<ion-slides #slider pager="false" class="revenue-item-list">
  <ion-slide class="revenue-item-list__slide" *ngFor="let level of ['group', 'subGroup', 'item']">
    <ion-list class="revenue-item-list__slide__list">
      <ng-container *ngIf="!!getParentElement(level)">
        <div class="revenue-item-list__slide__list__item"
          (click)="clickEvent.emit({ item: getParentElement(level), addToBasket: true })">
          <ion-icon [name]="level === 'subGroup' ? iconByLevel['group'] : iconByLevel['subGroup']"
            class="revenue-item-list__slide__list__item__img"></ion-icon>
          <p [innerHTML]="getParentElement(level)?.name" class="revenue-item-list__slide__list__item__text"></p>
          <div class="revenue-item-list__slide__list__item__button" [innerHTML]="t._('BOOK')"></div>
        </div>
        <div class="revenue-item-list__slide__list__separator"></div>
      </ng-container>
      <div *ngFor="let element of getItems(level); trackBy: trackId" class="revenue-item-list__slide__list__item"
        (click)="clickEvent.emit({ item: element, addToBasket: level === 'item' })">
        <ion-icon *ngIf="level !== 'item'" [name]="iconByLevel[level]"
          class="revenue-item-list__slide__list__item__img"></ion-icon>
        <span *ngIf="level === 'item'" class="revenue-item-list__slide__list__item__letter"
          [innerHTML]="element.name.charAt(0)"></span>
        <p [innerHTML]="element.name || ''" class="revenue-item-list__slide__list__item__text"></p>
        <i-chevron-right *ngIf="level !== 'item'" class="revenue-item-list__slide__list__item__icon"></i-chevron-right>
        <div *ngIf="level === 'item'" class="revenue-item-list__slide__list__item__button" [innerHTML]="t._('BOOK')">
        </div>
      </div>
      <div *ngIf="!getItems(level).length" class="revenue-item-list__slide__list__empty">
        <p *ngIf="level === 'group'" [innerHTML]="t._('EMPTY_GROUPS')"></p>
        <p *ngIf="level === 'subGroup'" [innerHTML]="t._('EMPTY_SUBGROUPS')"></p>
        <p *ngIf="level === 'item'" [innerHTML]="t._('EMPTY_ITEMS')"></p>
      </div>
    </ion-list>
  </ion-slide>
</ion-slides>