var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from "@angular/common/http";
import { VERSION } from "shared/lib/common/enums";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { P4mV1Service } from "shared/lib/common/services/p4m/p4m-v1/p4m-v1.service";
import { P4mV2Service } from "shared/lib/common/services/p4m/p4m-v2/p4m-v2.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EnvironmentService } from "../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../sentry/sentry.service";
import * as i3 from "../config/config.service";
import * as i4 from "../utils/utils.service";
import * as i5 from "../translate/translate.service";
import * as i6 from "../environment/environment.service";
import * as i7 from "./p4m-v1/p4m-v1.service";
import * as i8 from "./p4m-v2/p4m-v2.service";
export class P4mService {
    constructor(http, sentry, config, utils, translate, environment, v1, v2) {
        this.http = http;
        this.sentry = sentry;
        this.config = config;
        this.utils = utils;
        this.translate = translate;
        this.environment = environment;
        this.v1 = v1;
        this.v2 = v2;
        // tslint:disable: typedef
        this.assignCoupon = this.v1.assignCoupon;
        this.deviceManagementLogin = this.v1.deviceManagementLogin;
        this.getAllClients = this.v1.getAllClients;
        this.getStoresOfClient = this.v1.getStoresOfClient;
        this.downloadDisplayAds = this.v1.downloadDisplayAds;
        this.unMountDevice = this.v1.unMountDevice;
        this.loginToGetApiKey = this.v1.loginToGetApiKey;
        this.getPermissionUser = this.v1.getPermissionUser;
        this.extendedRevenuePointsManually = this.v1.extendedRevenuePointsManually;
        this.getActionDefinitions = this.v1.getActionDefinitions;
        this.register = this.v1.register;
        this.updateProfileExtendedRegistration = this.v1.updateProfileExtendedRegistration;
        this.readProgramPartners = this.v2.readProgramPartners;
        this.readPartnerStores = this.v2.readPartnerStores;
        this.parseResponseV2 = this.v2.parseResponseV2;
        this.mountDevice = this.v1.mountDevice;
        this.getPassword = this.v1.getPassword;
        this.setPassword = this.v1.setPassword;
        this.getUser = this.v1.getUser;
        this.setUser = this.v1.setUser;
        this.getToken = this.v1.getToken;
        this.setToken = this.v1.setToken;
        this.arts = this.v1.arts;
        this.getArtsXml = this.v1.getArtsXml;
        this.parseResponseV1 = this.v1.parseResponseV1;
        this.parseError = this.v1.parseError;
        this.parseBalance = this.v1.parseBalance;
        this.ensureValidTimestamp = this.v1.ensureValidTimestamp;
        this.parseCoupons = this.v2.parseCoupons;
        this.getCouponi18nField = this.v2.getCouponi18nField;
        this.parseFiles = this.v2.parseFiles;
        this.isUnlocked = this.v2.isUnlocked;
        this.getApiV1Url = this.v1.getApiV1Url;
        this.getCouponKeys = this.v1.getCouponKeys;
    }
    getUserDetails(params) {
        return this.config.getVersion("getUserDetails") === VERSION.v1 ? this.v1.getUserDetails(params) : this.v2.getUserDetails(params);
    }
    checkIn(params) {
        return this.config.getVersion("checkIn") === VERSION.v1 ? this.v1.checkIn(params) : this.v2.checkIn(params);
    }
    getUserHistory(params) {
        return this.config.getVersion("getUserHistory") === VERSION.v1 ? this.v1.getUserHistory(params) : this.v2.getUserHistory(params);
    }
    getCoupons(params) {
        return this.config.getVersion("getCoupons") === VERSION.v1 ? this.v1.getCoupons(params) : this.v2.getCoupons(params);
    }
    getRewards(params) {
        return this.config.getVersion("getRewards") === VERSION.v1 ? this.v1.getRewards(params) : this.v2.getRewards(params);
    }
    bookPoints(params) {
        return this.config.getVersion("bookPoints") === VERSION.v1 ? this.v1.bookPoints(params) : this.v2.bookPoints(params);
    }
    revenuePoints(params) {
        return this.config.getVersion("revenuePoints") === VERSION.v1 ? this.v1.revenuePoints(params) : this.v2.revenuePoints(params);
    }
    redeemCoupon(params) {
        return this.config.getVersion("redeemCoupon") === VERSION.v1 ? this.v1.redeemCoupon(params) : this.v2.redeemCoupon(params);
    }
    redeemReward(params) {
        return this.config.getVersion("redeemReward") === VERSION.v1 ? this.v1.redeemReward(params) : this.v2.redeemReward(params);
    }
    login(params) {
        return this.config.getVersion("login") === VERSION.v1 ? this.v1.login(params) : this.v2.login(params);
    }
    readAccountBalance(params) {
        return this.config.getVersion("readAccountBalance") === VERSION.v1
            ? this.v1.readAccountBalance(params)
            : this.v2.readAccountBalance(params);
    }
    readUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.config.getVersion("readUser") === VERSION.v1 ? this.v1.readUser(params) : this.v2.readUser(params);
        });
    }
    readAccountCouponsRedemptions(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.config.getVersion("readAccountCouponsRedemptions") === VERSION.v1
                ? this.v1.readAccountCouponsRedemptions(keyCode, idType)
                : this.v2.readAccountCouponsRedemptions(keyCode, idType);
        });
    }
}
P4mService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function P4mService_Factory() { return new P4mService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SentryService), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.UtilsService), i0.ɵɵinject(i5.TranslateService), i0.ɵɵinject(i6.EnvironmentService), i0.ɵɵinject(i7.P4mV1Service), i0.ɵɵinject(i8.P4mV2Service)); }, token: P4mService, providedIn: "root" });
