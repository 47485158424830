import { Component, NgZone } from "@angular/core";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ModalController, Platform } from "@ionic/angular";
import { IBurnPageParams, IInformationModalPageInputs, InformationModalPage } from "shared/lib/common/pages";
import { QrScannerPage as SharedQRScannerPage } from "shared/lib/common/pages";
import { ToastService, TranslateService, UserService, InactivityService, RoutingService, AlertService } from "shared/lib/common/services";
import { IIdentifierType } from "shared/lib/common/interfaces";
import { AdminService, PublicAccountService } from "shared/lib/v2/services";
import { SharedRoutes } from "shared/lib/common/enums";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-qr-scanner",
  templateUrl: "../../../common/pages/qr-scanner/qr-scanner.page.html",
  styleUrls: ["../../../common/pages/qr-scanner/qr-scanner.page.scss"],
})
export class QrScannerPage extends SharedQRScannerPage {
  constructor(
    protected _zone: NgZone,
    protected _translate: TranslateService,
    protected _userService: UserService,
    protected _toast: ToastService,
    protected _config: ConfigService,
    protected _modal: ModalController,
    protected _admin: AdminService,
    protected _inactivity: InactivityService,
    protected _platform: Platform,
    protected _qrScanner: QRScanner,
    protected _routing: RoutingService,
    private _publicAccount: PublicAccountService,
    private readonly _alert: AlertService,
  ) {
    super(_zone, _translate, _userService, _toast, _config, _modal, _admin, _inactivity, _platform, _qrScanner, _routing, _alert);
  }

  protected async user(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (keyCode) {
      const [checkInResponse, details] = await Promise.all([
        this._userService.checkIn(keyCode),
        this._userService.getDetails(keyCode, idType),
      ]);
      if (checkInResponse.ok === false) this._toast.show(this.translate._(checkInResponse.error.message), "error");
      if (details.ok === true) {
        const queryParams: IBurnPageParams = { idType, keyCode };
        const saveUserResponse = await this.saveUser(details.response, idType);
        if (saveUserResponse.ok === true) {
          if (this._config.getOrganization() === "dal") {
            if (details.response.isRegistered) {
              this._routing.goForward([SharedRoutes.burn], { queryParams });
            } else {
              await this.showInfoModal();
              return this._routing.goForward([SharedRoutes.screenSaver]);
            }
          } else {
            this._routing.goForward([SharedRoutes.burn], { queryParams });
          }
        } else this._toast.show(this.translate._(saveUserResponse.error.message), "error");
        return saveUserResponse.ok;
      } else this._toast.show(this.translate._(details.error.message), "error");
      return details.ok;
    }
    return false;
  }

  private async showInfoModal(): Promise<void> {
    const componentProps: IInformationModalPageInputs = {
      title: this.translate._("PLEASE_REGISTER"),
      description: "",
      buttonText: this.translate._("UNDERSTOOD"),
    };
    const informationModal = await this._modal.create({
      component: InformationModalPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await informationModal.present();
    await informationModal.onDidDismiss();
  }

  // Register v2 configurable with profile
  // protected async user(keyCode: string, idType: IIdentifierType): Promise<boolean> {
  //   if (keyCode) {
  //     const [checkInResponse, details, partnerResponse] = await Promise.all([
  //       this._userService.checkIn(keyCode),
  //       this._userService.getDetails(keyCode, idType),
  //       this._publicAccount.getIdentifierPartner(keyCode, this._config.getOrganization()),
  //     ]);
  //     if (checkInResponse.ok === false) this._toast.show(this.translate._(checkInResponse.error.message), "error");
  //     if (details.ok === true) {
  //       let partnerId: string;
  //       if (partnerResponse.ok === true) partnerId = partnerResponse.response;
  //       const queryParams: IBurnPageParams = { idType, keyCode };
  //       const saveUserResponse = await this.saveUser(details.response, idType);
  //       if (saveUserResponse.ok === true) {
  //         if (!details.response.isRegistered && this.registrationType !== "NO") {
  //           await this.showRegister(keyCode, details.response, partnerId);
  //           return false;
  //         } else {
  //           this._routing.goForward([SharedRoutes.burn], { queryParams });
  //         }
  //       } else this._toast.show(this.translate._(saveUserResponse.error.message), "error");
  //       return saveUserResponse.ok;
  //     } else this._toast.show(this.translate._(details.error.message), "error");
  //     return details.ok;
  //   }
  //   return false;
  // }

  // private async showRegister(keyCode: string, user: IUser, partnerId: string): Promise<boolean> {
  //   const componentProps: IRegisterModalPageInputs = {
  //     registrationType: this.registrationType,
  //     keyCode,
  //     userReferenceCode: user.userReferenceCode,
  //     partnerId,
  //   };
  //   const registerModal = await this._modal.create({
  //     component: RegisterPage,
  //     cssClass: "modal modal--full-screen",
  //     componentProps,
  //   });
  //   await registerModal.present();
  //   const { data } = (await registerModal.onDidDismiss()) as IRegisterModalPageOutputs;
  //   if (data === "SUCCESS") await this.showAttentionModal();
  //   return data === "SUCCESS";
  // }
}
