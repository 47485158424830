<ion-content scroll-y="false">
  <div class="page-wrapper admin">
    <shared-close (tap)="routing.goForward([routes.screenSaver])"></shared-close>
    <div class="admin__content">
      <form class="admin__content__printer" autocomplete="off" [formGroup]="form">
        <h1 class="admin__content__printer__title">
          {{ t._("PRINTER_CONFIG_TITLE") }}
        </h1>
        <h2 class="admin__content__printer__ip">
          {{ t._("PRINTER_CONFIG_SUBTITLE") }}
        </h2>
        <div class="admin__content__printer__ip-input-container">
          <input
            [value]="form.get('ip').value"
            formControlName="ip"
            autocomplete="off"
            placeholder="192.168.1.134"
            class="input admin__content__printer__ip-input"
          />
        </div>
        <div class="admin__content__printer__buttons-container">
          <shared-button
            class="admin__content__printer__buttons-container__remove"
            (clickEvent)="removeIp()"
            [disabled]="!print.getIp()"
            [color]="'danger'"
            [fill]="'solid'"
            [disabled]="form.get('ip').invalid"
            >{{ t._("REMOVE_PRINTER") }}</shared-button
          >
          <shared-button
            class="admin__content__printer__buttons-container__save"
            [color]="'primary'"
            [fill]="'solid'"
            [disabled]="form.get('ip').invalid"
            [loading]="loading"
            (clickEvent)="testConnection(form.get('ip').value)"
            >{{ t._("PRINTER_CONFIG_BUTTON") }}
          </shared-button>
        </div>
      </form>
      <div class="admin__content__admin">
        <h1 class="admin__content__admin__title">
          {{ t._("GENERAL_SETTINGS") }}
        </h1>
        <shared-button
          *ngIf="config.showSelfServiceButton"
          class="admin__content__admin__button --margin"
          [color]="'dark'"
          [fill]="'solid'"
          (tap)="openBackOffice()"
        >
          {{ t._("BACKOFFICE") }}
        </shared-button>
        <span
            class="admin__content__admin__link"
            (click)="reload()"
        >
          {{ t._("RELOAD") }}
        </span>
        <span
            class="admin__content__admin__link"
            (click)="logOut()"
        >
          {{ t._("UNMOUNT_DEVICE") }}
        </span>
      </div>
      <div class="admin__content__bottom">
        <p class="admin__content__bottom__text">
          {{ t._("SCAN_ZOOM") + zoom }}
        </p>
        <p class="admin__content__bottom__text">
          {{ releaseVersion }}
        </p>
      </div>
    </div>
  </div>
</ion-content>
