<div
  *ngIf="!connection || (!socketConnection && isDeviceMounted)"
  class="connection-banner"
  [class.--show]="!connection || (!socketConnection && isDeviceMounted)"
>
  <div *ngIf="!connection" class="connection-banner__text">
    {{ translate._("NO_CONNECTION") }}
  </div>

  <div *ngIf="connection && !socketConnection" class="connection-banner__text" (tap)="reconnect()">
    {{ loading ? translate._("RECONNECTING") : translate._("RECONNECT") }}
    <i-refresh-ccw class="connection-banner__text__icon" [class.--loading]="loading"></i-refresh-ccw>
  </div>
</div>
