import { Pipe, PipeTransform } from "@angular/core";
import { IAccountCouponRedemption, ITransaction } from "shared/lib/common/interfaces";
import { TranslateService } from "../../services";

export interface IHistory {
  text: string;
  date: string;
  points: number;
  positive: boolean;
}

@Pipe({
  name: "parseHistory",
})
export class ParseHistoryPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(transactions: ITransaction[], accountCouponsRedemptions: IAccountCouponRedemption[]): IHistory[] {
    return [
      ...(transactions || []),
      ...(accountCouponsRedemptions || []).filter(accountCouponsRedemption => accountCouponsRedemption.usageType === "DISCOUNT"),
    ]
      .map(element => {
        if (element["redemptionId"]) {
          return {
            text: element["bookingText"]
              ? `${this.translate._("COUPON_REDEMPTION")}: ${element["bookingText"]}`
              : this.translate._("COUPON_REDEMPTION"),
            date: new Date(element["redemptionTime"]).getTime(),
            points: 0,
            positive: false,
          };
        } else {
          return {
            text: element["description"],
            date: element["transactionDateTime"].unixTimeStamp,
            points: element["points"],
            positive: element["points"] >= 0,
          };
        }
      })
      .sort((a, b) => {
        return b.date - a.date;
      });
  }
}
