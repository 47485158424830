/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceData { 
    externalReference: string;
    partnerId?: string;
    property: ResourceData.PropertyEnum;
    /**
     * base64 encoded file content
     */
    resourceData: string;
    storeId?: string;
    type?: ResourceData.TypeEnum;
}
export namespace ResourceData {
    export type PropertyEnum = 'COUPON_IMAGE' | 'SYSTEM_DOWNLOAD' | 'TRANSACTION_RECEIPT';
    export const PropertyEnum = {
        COUPONIMAGE: 'COUPON_IMAGE' as PropertyEnum,
        SYSTEMDOWNLOAD: 'SYSTEM_DOWNLOAD' as PropertyEnum,
        TRANSACTIONRECEIPT: 'TRANSACTION_RECEIPT' as PropertyEnum
    };
    export type TypeEnum = 'GIF' | 'JPG' | 'PDF' | 'PNG' | 'UNKNOWN' | 'ZIP';
    export const TypeEnum = {
        GIF: 'GIF' as TypeEnum,
        JPG: 'JPG' as TypeEnum,
        PDF: 'PDF' as TypeEnum,
        PNG: 'PNG' as TypeEnum,
        UNKNOWN: 'UNKNOWN' as TypeEnum,
        ZIP: 'ZIP' as TypeEnum
    };
}


