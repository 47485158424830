import { P4mService, RoutingService } from "shared/lib/common/services";
import { SharedRoutes } from "shared/lib/common/enums";
import * as i0 from "@angular/core";
import * as i1 from "../../services/routing/routing.service";
import * as i2 from "../../services/p4m/p4m.service";
export class IsLoginDoneGuard {
    constructor(routing, p4m) {
        this.routing = routing;
        this.p4m = p4m;
    }
    canActivate() {
        if (this.p4m.getUser() && this.p4m.getPassword() && this.p4m.getToken()) {
            this.routing.goForward([SharedRoutes.mounting]);
            return false;
        }
        return true;
    }
}
IsLoginDoneGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsLoginDoneGuard_Factory() { return new IsLoginDoneGuard(i0.ɵɵinject(i1.RoutingService), i0.ɵɵinject(i2.P4mService)); }, token: IsLoginDoneGuard, providedIn: "root" });
