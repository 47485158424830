import { Injectable, Inject, Optional } from "@angular/core";
import { Configuration, BASE_PATH } from "shared/lib/v2/apis/programs";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { INTERACTION_ID } from "shared/lib/common/constants";
import { ProductCatalogService as ConvercusProductCatalogService } from "shared/lib/v2/apis/programs/api/product-catalog.service";
import { EnvironmentService } from "shared/lib/common/services";

@Injectable({ providedIn: "root" })
export class ProductCatalogService extends ConvercusProductCatalogService {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
  }

  public getGroupsOfPartner(
    programId: string,
    partnerId: string,
    interactionId: string = INTERACTION_ID,
    inherited: boolean = true,
  ): Observable<any> {
    return super.getGroupsOfPartnerUsingGET(interactionId, partnerId, programId, inherited);
  }

  public getSubGroupsOfPartnerAndGroup(
    programId: string,
    partnerId: string,
    groupId: string,
    interactionId: string = INTERACTION_ID,
    inherited: boolean = true,
  ): Observable<any> {
    return super.getSubGroupsOfPartnerAndGroupUsingGET(groupId, interactionId, partnerId, programId, inherited);
  }

  public getItemsOfPartner(
    programId: string,
    partnerId: string,
    interactionId: string = INTERACTION_ID,
    inherited: boolean = true,
  ): Observable<any> {
    return super.getItemsOfPartnerUsingGET(interactionId, partnerId, programId, inherited);
  }
}
