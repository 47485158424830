import { RatingConfigService as ConvercusRatingConfigService, Configuration } from "shared/lib/v2/apis/programs";
import { HttpClient } from "@angular/common/http";
import { INTERACTION_ID } from "shared/lib/common/constants";
import { EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../apis/programs/variables";
import * as i3 from "../../apis/programs/configuration";
import * as i4 from "../../../common/services/environment/environment.service";
export class RatingConfigService extends ConvercusRatingConfigService {
    constructor(httpClient, basePath, configuration, environmentService) {
        super(httpClient, environmentService.getVariable("API_V2"), configuration);
        this.httpClient = httpClient;
        this.environmentService = environmentService;
    }
    getRatingConfigForProgram(programId, interactionId = INTERACTION_ID) {
        return super.getRatingConfigForProgramUsingGET(interactionId, programId);
    }
}
RatingConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RatingConfigService_Factory() { return new RatingConfigService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8), i0.ɵɵinject(i4.EnvironmentService)); }, token: RatingConfigService, providedIn: "root" });
