import { ConfigService, P4mService, RoutingService } from "shared/lib/common/services";
import { SharedRoutes } from "shared/lib/common/enums";
import * as i0 from "@angular/core";
import * as i1 from "../../services/routing/routing.service";
import * as i2 from "../../services/config/config.service";
import * as i3 from "../../services/p4m/p4m.service";
export class IsMountingDoneGuard {
    constructor(routing, config, p4m) {
        this.routing = routing;
        this.config = config;
        this.p4m = p4m;
    }
    canActivate() {
        if (this.p4m.getUser() && this.p4m.getPassword() && this.p4m.getToken()) {
            const mountingConfig = this.config.getMountingConfig();
            if (mountingConfig.deviceKey) {
                this.routing.goForward([SharedRoutes.screenSaver]);
                return false;
            }
            return true;
        }
        else {
            this.routing.goForward([SharedRoutes.login]);
            return false;
        }
    }
}
IsMountingDoneGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsMountingDoneGuard_Factory() { return new IsMountingDoneGuard(i0.ɵɵinject(i1.RoutingService), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.P4mService)); }, token: IsMountingDoneGuard, providedIn: "root" });
