var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ModalController } from "@ionic/angular";
import { CheckPinModalPage } from "shared/lib/common/pages";
import { DeviceMountingService } from "../../services";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "../../services/device-mounting/device-mounting.service";
export class IsPinAddedGuard {
    constructor(modal, deviceMounting) {
        this.modal = modal;
        this.deviceMounting = deviceMounting;
    }
    canActivate(next, state) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.deviceMounting.profile.pinRequired) {
                return yield this.showCheckPinModal(this.deviceMounting.profile.pin ? this.deviceMounting.profile.pin : "");
            }
            else {
                return true;
            }
        });
    }
    showCheckPinModal(pin) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                pin,
            };
            const checkPinModal = yield this.modal.create({
                component: CheckPinModalPage,
                cssClass: "modal modal--full-screen",
                componentProps,
            });
            yield checkPinModal.present();
            const { data } = (yield checkPinModal.onDidDismiss());
            return data;
        });
    }
}
IsPinAddedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsPinAddedGuard_Factory() { return new IsPinAddedGuard(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.DeviceMountingService)); }, token: IsPinAddedGuard, providedIn: "root" });
