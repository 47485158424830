/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BatchAccountCouponAssignMessages } from './batch-account-coupon-assign-messages';


export interface BatchAccountCouponAssign { 
    completedCount?: number;
    couponId?: string;
    errorsCount?: number;
    finishDate?: string;
    id?: string;
    initDate?: string;
    messages?: Array<BatchAccountCouponAssignMessages>;
    rowsCount?: number;
    startedBy?: string;
    status?: BatchAccountCouponAssign.StatusEnum;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    validFrom?: string;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    validTo?: string;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    visibleFrom?: string;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    visibleTo?: string;
}
export namespace BatchAccountCouponAssign {
    export type StatusEnum = 'PROCESSING' | 'FINISHED' | 'ERROR' | 'ABORTED';
    export const StatusEnum = {
        PROCESSING: 'PROCESSING' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum,
        ABORTED: 'ABORTED' as StatusEnum
    };
}


