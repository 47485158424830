/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PartnerType { 
    name?: string;
    partnerTypeLabel: PartnerType.PartnerTypeLabelEnum;
}
export namespace PartnerType {
    export type PartnerTypeLabelEnum = 'AFFILIATE' | 'NETWORK' | 'STANDARD';
    export const PartnerTypeLabelEnum = {
        AFFILIATE: 'AFFILIATE' as PartnerTypeLabelEnum,
        NETWORK: 'NETWORK' as PartnerTypeLabelEnum,
        STANDARD: 'STANDARD' as PartnerTypeLabelEnum
    };
}


