import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import * as JSZip from "jszip";
import { CONFIGURATION } from "shared/lib/common/constants";
import { LOCAL_STORAGE, REQUEST, VERSION } from "shared/lib/common/enums";
import {
  CONFIGURATIONS_BY_ORGANIZATION,
  CONFIGURATIONS_BY_WORKSTATION_ID,
  Colors,
  IConfig,
  IDeviceConfig,
  IMountingConfig,
  IP4m,
  IScreenSaverConfig,
  ProgramConfig,
} from "shared/lib/common/interfaces";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { IResponse, CONFIGURATIONS_BY_DEVICE } from "shared/lib/common/interfaces";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private printerLogo: string;
  private organization: string;
  private version: IP4m;
  public registerFormUrl: string;
  public logo: string;
  public screenSaver: IScreenSaverConfig;
  public showSelfServiceButton: boolean;
  public inactivityTime: number;
  public manuallyEnableCamera: boolean;
  public displayRevenue: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private utils: UtilsService,
    private translate: TranslateService,
    private platform: Platform,
  ) {
    this.organization = "";
  }

  public setOrganization(organization: string): void {
    this.organization = organization;
  }

  public getOrganization(): string {
    return this.organization;
  }

  public async setConfig(programConfig?: ProgramConfig): Promise<void> {
    if (programConfig) {
      this.applyProgramConfig(programConfig);
    } else {
      let config: IResponse<IConfig>;
      const clientId = this.getMountingConfig().clientId;
      if (clientId && clientId !== "1234") {
        config = await this.getZipConfig(clientId);
        if (!config.ok) config = await this.getZipConfig(this.organization);
      } else {
        config = await this.getZipConfig(this.organization);
      }
      if (config.ok === true) this.applyConfig(config.response);
      else this.applyConfig(CONFIGURATION);
    }
  }

  public setMountingConfig(config: IMountingConfig): void {
    localStorage.setItem(LOCAL_STORAGE.MOUNTING_CONFIG, this.utils.encrypt(JSON.stringify(config)));
  }

  public getMountingConfig(): IMountingConfig {
    const mountingConfig: IMountingConfig = {
      workstationId: null,
      deviceId: null,
      cameraPosition: null,
      clientId: null,
      clientName: null,
      deviceKey: null,
      posMode: null,
      storeId: null,
      storeName: null,
      deviceInformation: null,
      partner: null,
      store: null,
      legalName: null,
      appInteractionId: null,
      cashInteractionId: null,
    };
    try {
      return (JSON.parse(this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG))) as IMountingConfig) || mountingConfig;
    } catch (error) {
      return mountingConfig;
    }
  }

  public havePrinter(): boolean {
    return this.getMountingConfig().posMode ? this.getMountingConfig().posMode === "SER" : false;
  }

  public haveBurnPage(): boolean {
    return this.getMountingConfig().posMode ? this.getMountingConfig().posMode !== "CAS" : false;
  }

  public setAllowedManualPoints(allowed: boolean): void {
    localStorage.setItem(LOCAL_STORAGE.MANUAL_ALLOWED, this.utils.encrypt(JSON.stringify(allowed)));
  }

  public getAllowedManualPoints(): boolean {
    try {
      return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MANUAL_ALLOWED)) === "true";
    } catch (error) {
      return false;
    }
  }

  public setPrinterLogo(src: string): void {
    this.printerLogo = src;
  }

  public getPrinterLogo(): string {
    return this.printerLogo;
  }

  public getVersion(request: REQUEST): VERSION {
    return this.version ? this.version[request] || this.version.default : VERSION.v1;
  }

  public getDeviceConfig(): IDeviceConfig {
    return (
      CONFIGURATIONS_BY_DEVICE.find(d => this.platform.testUserAgent(d.name)) ||
      CONFIGURATIONS_BY_WORKSTATION_ID.find(d => d.name === this.getMountingConfig().workstationId) ||
      CONFIGURATIONS_BY_ORGANIZATION.find(d => d.name === this.organization) || { name: "default", exposureCompensation: -1, zoom: 4 }
    );
  }

  private getZipConfig(configName: string): Promise<IResponse<IConfig>> {
    return new Promise(resolve => {
      if ((<any>window).JSZipUtils) {
        (<any>window).JSZipUtils.getBinaryContent(`/assets/${configName}.zip`, async (err, response) => {
          if (err) {
            resolve({ ok: false, error: { message: err } });
          } else {
            resolve(await this.parseZipData(response));
          }
        });
      } else {
        resolve({ ok: false, error: { message: "UNHANDLED_ERROR" } });
      }
    });
  }

  private async parseZipData(data: any): Promise<IResponse<IConfig>> {
    try {
      let config: IConfig = CONFIGURATION;
      const zip = await JSZip.loadAsync(data);
      for (const filename of Object.keys(zip.files)) {
        const [name, type] = filename.split(".");
        switch (name) {
          case "logo":
          case `${this.organization}/logo`:
            switch (type) {
              case "svg":
                config.logo_url = `data:image/svg+xml;base64,${await zip.files[filename].async("base64")}`;
                break;
              case "png":
                config.logo_url = `data:image/png;base64,${await zip.files[filename].async("base64")}`;
                break;
            }
            break;
          case "config":
          case `${this.organization}/config`:
            const configData = JSON.parse(await zip.files[filename].async("text")) as IConfig;
            config = { ...config, ...configData };
        }
      }
      return { ok: true, response: config };
    } catch (error) {
      return { ok: false, error: { message: error } };
    }
  }

  private applyConfig(config: IConfig): void {
    this.translate.setTranslations(config.translations);
    this.logo = config.logo_url;
    this.registerFormUrl = config.register_form;
    this.screenSaver = config.screen_saver;
    this.version = config.p4m_api;
    this.showSelfServiceButton = config.showSelfServiceButton;
    this.inactivityTime = config.inactivity_time;
    this.manuallyEnableCamera = !!config.manuallyEnableCamera;
    this.displayRevenue = config.display_revenue_amount_payable_with_points;
    for (const key in config.theme) {
      if (config.theme.hasOwnProperty(key)) {
        this.document.documentElement.style.setProperty(key, config.theme[key]);
      }
    }
  }

  private applyProgramConfig(programConfig: ProgramConfig): void {
    this.translate.setTranslations(programConfig.posapp.translations);
    this.logo = programConfig.posapp.logo || programConfig.logo;
    this.screenSaver = programConfig.posapp.screenSaver;
    this.version = programConfig.posapp.p4mApi;
    this.showSelfServiceButton = programConfig.posapp.showSelfServiceButton;
    this.inactivityTime = programConfig.posapp.inactivityTime;
    if (programConfig.theme) {
      if (programConfig.theme.colors) this.applyTheme(programConfig.theme.colors);
      Object.entries(programConfig.theme).forEach(([name, value]) => {
        if (value && typeof value === "string") {
          this.document.documentElement.style.setProperty(name, value);
        }
      });
    }
  }

  private applyTheme(colors: Colors): void {
    this.setVariables("dark", colors.dark);
    this.setVariables("light", colors.light);
    Object.entries(colors).forEach(([name, value]) => {
      if (!value) return;
      switch (name) {
        case "primary":
        case "secondary":
        case "tertiary":
        case "success":
        case "warning":
        case "danger":
        case "medium":
        case "background":
        case "banner":
        case "border":
        case "dark":
        case "light":
          this.setVariables(name, value);
          break;
        case "primary-text":
          this.setVariables("text", value);
          break;
      }
    });
  }

  private setVariables(name: string, color: string): void {
    if (!color) return;
    this.document.documentElement.style.setProperty(`--color-${name}`, color);
    const colorShade = this.getShadeColor(color, -20);
    this.document.documentElement.style.setProperty(`--color-${name}-shade`, colorShade);
    const colorTint = this.getShadeColor(color, 20);
    this.document.documentElement.style.setProperty(`--color-${name}-tint`, colorTint);
    const colorRgb = this.hexToRgb(color);
    this.document.documentElement.style.setProperty(`--color-${name}-rgb`, colorRgb);
    const colorShadeRgb = this.hexToRgb(colorShade);
    this.document.documentElement.style.setProperty(`--color-${name}-shade-rgb`, colorShadeRgb);
    const colorTintRgb = this.hexToRgb(colorTint);
    this.document.documentElement.style.setProperty(`--color-${name}-tint-rgb`, colorTintRgb);
    const colorDark = this.document.documentElement.style.getPropertyValue("--color-dark") || "#000000";
    const colorLight = this.document.documentElement.style.getPropertyValue("--color-light") || "#ffffff";
    let colorContrast: string;
    if (this.lightOrDark(color) === "dark") colorContrast = colorLight;
    else colorContrast = colorDark;
    this.document.documentElement.style.setProperty(`--color-${name}-contrast`, colorContrast);
    const colorContrastRgb = this.hexToRgb(colorContrast);
    this.document.documentElement.style.setProperty(`--color-${name}-contrast-rgb`, colorContrastRgb);
  }

  private getShadeColor(color: string, percent: number): string {
    let usePound = false;
    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }
    let R = parseInt(color.substring(0, 2), 16);
    let G = parseInt(color.substring(2, 4), 16);
    let B = parseInt(color.substring(4, 6), 16);
    R = R + percent;
    G = G + percent;
    B = B + percent;
    if (R > 255) R = 255;
    else if (R < 0) R = 0;
    if (G > 255) G = 255;
    else if (G < 0) G = 0;
    if (B > 255) B = 255;
    else if (B < 0) B = 0;
    const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);
    return (usePound ? "#" : "") + RR + GG + BB;
  }

  private hexToRgb(color: string): string {
    if (color[0] === "#") color = color.slice(1);
    const R = parseInt(color.substring(0, 2), 16);
    const G = parseInt(color.substring(2, 4), 16);
    const B = parseInt(color.substring(4, 6), 16);
    return `${R}, ${G}, ${B}`;
  }

  private lightOrDark(color: string): "light" | "dark" {
    if (color[0] === "#") color = color.slice(1);
    const R = parseInt(color.substring(0, 2), 16);
    const G = parseInt(color.substring(2, 4), 16);
    const B = parseInt(color.substring(4, 6), 16);
    // HSP (Highly Sensitive Poo)
    const hsp = Math.sqrt(0.299 * (R * R) + 0.587 * (G * G) + 0.114 * (B * B));
    if (hsp > 127.5) return "light";
    else return "dark";
  }
}
