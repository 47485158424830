var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConfigService, EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../common/services/environment/environment.service";
import * as i3 from "../../../common/services/config/config.service";
export class PublicAccountService {
    constructor(httpClient, environmentService, config) {
        this.httpClient = httpClient;
        this.environmentService = environmentService;
        this.config = config;
    }
    getIdentifierPartner(identifierCode, program) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (identifierCode === null || identifierCode === undefined) {
                    throw new Error("Required parameter identifierCode was null or undefined when calling getIdentifierPartner.");
                }
                if (program === null || program === undefined) {
                    throw new Error("Required parameter program was null or undefined when calling getIdentifierPartner.");
                }
                let headers = new HttpHeaders();
                headers = headers.set("program", String(program));
                headers = headers.set("Content-Type", "application/json");
                headers = headers.set("Accept", "*/*");
                headers = headers.set("interaction-id", this.config.getMountingConfig().workstationId);
                const response = yield this.httpClient
                    .get(`${this.environmentService.getVariable("API_V2")}/public/identifiers/${encodeURIComponent(String(identifierCode))}/partner`, {
                    headers,
                    responseType: "text",
                })
                    .toPromise();
                return { ok: true, response };
            }
            catch (error) {
                return { ok: false, error: { message: error.message } };
            }
        });
    }
}
PublicAccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicAccountService_Factory() { return new PublicAccountService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvironmentService), i0.ɵɵinject(i3.ConfigService)); }, token: PublicAccountService, providedIn: "root" });
