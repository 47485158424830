import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { P4mService, RoutingService } from "shared/lib/common/services";
import { SharedRoutes } from "shared/lib/common/enums";

@Injectable({
  providedIn: "root",
})
export class IsLoginDoneGuard implements CanActivate {
  constructor(private routing: RoutingService, public p4m: P4mService) {}

  public canActivate(): boolean {
    if (this.p4m.getUser() && this.p4m.getPassword() && this.p4m.getToken()) {
      this.routing.goForward([SharedRoutes.mounting]);
      return false;
    }
    return true;
  }
}
