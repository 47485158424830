<ion-content scroll-y="false">
  <div class="page-wrapper mounting">
    <div class="mounting--header">
      <h1 class="mounting--title">{{ translate._("MOUNTING_PROCESS") }}</h1>
      <shared-close class="mounting--close" (tap)="back()"></shared-close>
    </div>
    <div class="mounting--content-wrapper">
      <form [formGroup]="form" class="mounting--content">
        <div class="mounting--content-row">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">appInteractionId</h3>
            <div class="mounting--content-subtitle__autocomplete" [class.--has-error]="checkErrors('appInteractionId')">
              <ng-autocomplete
                formControlName="appInteractionId"
                [data]="interactionPoints || []"
                [searchKeyword]="'id'"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                (inputChanged)="onChangeSearch($event)"
              >
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.id"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="mounting--content-row">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">{{ translate._("DEVICE_NAME") }}</h3>
            <input
              autocomplete="off"
              class="mounting--content-subtitle__description-input"
              formControlName="deviceInformation"
              name="deviceInformation"
              [class.--has-error]="checkErrors('deviceInformation')"
              placeholder="Cash register 1"
            />
          </div>
        </div>
        <div class="mounting__content-footer">
          <shared-button
            class="mounting__content-footer__button"
            [color]="'primary'"
            [fill]="'solid'"
            [loading]="loading"
            (clickEvent)="mountDevice()"
            [disabled]="form.invalid"
          >
            {{ translate._("SAVE") }}
          </shared-button>
        </div>
      </form>
    </div>
  </div>
</ion-content>
