/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Condition {
  conditionType: Condition.ConditionTypeEnum;
  conditionUsage: Condition.ConditionUsageEnum;
  /**
   * operator
   */
  operator: Condition.OperatorEnum;
  /**
   * property
   */
  propertyPath: Condition.PropertyPathEnum;
  /**
   * value
   */
  value: string;
}
export namespace Condition {
  export type ConditionTypeEnum = "effectiveness" | "points" | "amount" | "statelevel" | "programUnit" | "maxRedeem" | "product";
  export const ConditionTypeEnum = {
    Effectiveness: "effectiveness" as ConditionTypeEnum,
    Points: "points" as ConditionTypeEnum,
    Amount: "amount" as ConditionTypeEnum,
    Statelevel: "statelevel" as ConditionTypeEnum,
    ProgramUnit: "programUnit" as ConditionTypeEnum,
    MaxRedeem: "maxRedeem" as ConditionTypeEnum,
    Product: "product" as ConditionTypeEnum,
  };
  export type ConditionUsageEnum = "VISIBILITY" | "REDEEM" | "REDEEM_VISIBILITY";
  export const ConditionUsageEnum = {
    VISIBILITY: "VISIBILITY" as ConditionUsageEnum,
    REDEEM: "REDEEM" as ConditionUsageEnum,
    REDEEMVISIBILITY: "REDEEM_VISIBILITY" as ConditionUsageEnum,
  };
  export type OperatorEnum = "==" | "<=" | "<" | ">" | ">=" | "in";
  export const OperatorEnum = {
    Equal: "==" as OperatorEnum,
    LessThanOrEqualTo: "<=" as OperatorEnum,
    LessThan: "<" as OperatorEnum,
    GreaterThan: ">" as OperatorEnum,
    GreaterThanOrEqualTo: ">=" as OperatorEnum,
    In: "in" as OperatorEnum,
  };
  export type PropertyPathEnum =
    | "account.points"
    | "account.stateLevel"
    | "account.maxRedeem"
    | "coupon.programUnit"
    | "coupon.maxRedeem"
    | "system.currentDateTime"
    | "product.groupCode"
    | "product.subGroupCode"
    | "product.itemId"
    | "transaction.revenue"
    | "transaction.loyaltyRevenue";
  export const PropertyPathEnum = {
    AccountPoints: "account.points" as PropertyPathEnum,
    AccountStateLevel: "account.stateLevel" as PropertyPathEnum,
    AccountMaxRedeem: "account.maxRedeem" as PropertyPathEnum,
    CouponProgramUnit: "coupon.programUnit" as PropertyPathEnum,
    CouponMaxRedeem: "coupon.maxRedeem" as PropertyPathEnum,
    SystemCurrentDateTime: "system.currentDateTime" as PropertyPathEnum,
    ProductGroupCode: "product.groupCode" as PropertyPathEnum,
    ProductSubGroupCode: "product.subGroupCode" as PropertyPathEnum,
    ProductItemId: "product.itemId" as PropertyPathEnum,
    TransactionRevenue: "transaction.revenue" as PropertyPathEnum,
    TransactionLoyaltyRevenue: "transaction.loyaltyRevenue" as PropertyPathEnum,
  };
}
