<div class="not-found">
  <svg class="not-found__icon" viewBox="0 0 1056 430" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="shadow"
      d="M527.644 429.231C818.974 429.231 1055.14 413.596 1055.14 394.309C1055.14 375.023 818.974 359.388 527.644 359.388C236.313 359.388 0.143555 375.023 0.143555 394.309C0.143555 413.596 236.313 429.231 527.644 429.231Z"
      fill="#F2F2F2" />
    <g id="numbers">
      <path id="four-shadow"
        d="M318.114 266.264H282.58V161.624C282.58 158.872 282.038 156.147 280.984 153.605C279.931 151.062 278.388 148.752 276.442 146.806C274.496 144.86 272.186 143.317 269.643 142.263C267.101 141.21 264.376 140.668 261.624 140.668H253.297C247.74 140.668 242.409 142.876 238.479 146.806C234.549 150.736 232.342 156.066 232.342 161.624V266.264H132.111C130.05 266.264 128.023 265.731 126.227 264.716C124.432 263.702 122.929 262.241 121.865 260.475C120.801 258.709 120.211 256.698 120.153 254.637C120.095 252.576 120.571 250.535 121.534 248.712L227.616 47.9312C228.939 45.428 229.74 42.6827 229.973 39.8611C230.205 37.0395 229.863 34.2001 228.968 31.5143C228.073 28.8285 226.642 26.3519 224.763 24.2341C222.884 22.1163 220.596 20.4012 218.036 19.1923L211.774 16.2351C206.911 13.9389 201.352 13.5998 196.246 15.2881C191.141 16.9763 186.879 20.5627 184.344 25.3051L54.9628 267.338C52.7596 271.459 51.6069 276.06 51.6069 280.734V280.734C51.6069 288.27 54.6007 295.498 59.9297 300.827C65.2587 306.156 72.4864 309.15 80.0228 309.15H232.342V375.317C232.342 378.616 232.991 381.882 234.254 384.93C235.516 387.977 237.366 390.746 239.699 393.079C242.031 395.411 244.8 397.262 247.848 398.524C250.895 399.786 254.162 400.436 257.461 400.436H257.461C264.123 400.436 270.512 397.789 275.222 393.079C279.933 388.368 282.58 381.979 282.58 375.317V309.15H318.114C320.93 309.15 323.718 308.595 326.32 307.517C328.921 306.44 331.285 304.86 333.276 302.869C335.268 300.878 336.847 298.514 337.925 295.913C339.002 293.311 339.557 290.523 339.557 287.707V287.707C339.557 284.891 339.002 282.102 337.925 279.501C336.847 276.899 335.268 274.535 333.276 272.544C331.285 270.553 328.921 268.973 326.32 267.896C323.718 266.818 320.93 266.264 318.114 266.264V266.264Z"
        stroke="#3F3D56" stroke-miterlimit="10" />
      <path id="four-shadow"
        d="M984.688 266.264H949.153V161.624C949.153 156.066 946.946 150.736 943.016 146.806C939.086 142.876 933.755 140.668 928.198 140.668H919.871C917.119 140.668 914.394 141.21 911.852 142.263C909.309 143.317 906.999 144.86 905.053 146.806C903.107 148.752 901.564 151.062 900.51 153.605C899.457 156.147 898.915 158.872 898.915 161.624V266.264H798.685C796.623 266.264 794.596 265.731 792.801 264.716C791.006 263.702 789.503 262.241 788.439 260.475C787.374 258.709 786.785 256.698 786.727 254.637C786.669 252.576 787.144 250.535 788.108 248.712L894.19 47.9312C895.513 45.428 896.314 42.6827 896.547 39.8611C896.779 37.0395 896.437 34.2001 895.542 31.5143C894.646 28.8285 893.216 26.3519 891.337 24.2341C889.458 22.1163 887.17 20.4012 884.61 19.1923L878.347 16.2351C873.485 13.9389 867.925 13.5998 862.82 15.2881C857.714 16.9763 853.453 20.5627 850.918 25.3051L721.536 267.338C719.333 271.459 718.181 276.06 718.181 280.734C718.181 288.27 721.174 295.498 726.503 300.827C731.832 306.156 739.06 309.15 746.596 309.15H898.915V375.317C898.915 381.979 901.562 388.368 906.272 393.079C910.983 397.789 917.372 400.436 924.034 400.436V400.436C930.696 400.436 937.085 397.789 941.796 393.079C946.507 388.368 949.153 381.979 949.153 375.317V309.15H984.688C987.504 309.15 990.292 308.595 992.893 307.517C995.495 306.44 997.859 304.86 999.85 302.869C1001.84 300.878 1003.42 298.514 1004.5 295.913C1005.58 293.311 1006.13 290.523 1006.13 287.707V287.707C1006.13 282.02 1003.87 276.565 999.85 272.544C995.829 268.523 990.375 266.264 984.688 266.264V266.264Z"
        stroke="#3F3D56" stroke-miterlimit="10" />
      <path id="cero-shadow"
        d="M527.031 0.981812C441.087 0.981812 379.38 56.1212 379.38 184.78C379.38 330.593 441.087 369.19 527.031 369.19C612.975 369.19 678.358 326.917 678.358 184.78C678.358 32.8401 612.975 0.981812 527.031 0.981812ZM527.596 320.79C468.069 320.79 436.97 285.867 436.97 184.874C436.97 95.7621 469.342 48.7693 528.869 48.7693C588.396 48.7693 620.768 79.637 620.768 184.874C620.768 283.321 587.123 320.79 527.596 320.79V320.79Z"
        stroke="#3F3D56" stroke-miterlimit="10" />
      <path id="cero"
        d="M507.426 12.0098C421.482 12.0098 359.775 67.1492 359.775 195.808C359.775 341.621 421.482 380.218 507.426 380.218C593.37 380.218 658.753 337.945 658.753 195.808C658.753 43.8681 593.37 12.0098 507.426 12.0098ZM507.991 331.818C448.464 331.818 417.365 296.895 417.365 195.902C417.365 106.79 449.737 59.7972 509.264 59.7972C568.791 59.7972 601.163 90.665 601.163 195.902C601.163 294.349 567.517 331.818 507.991 331.818Z"
        fill="#2F2E41" />
      <path id="four"
        d="M308.311 278.517H272.777V173.877C272.777 168.319 270.569 162.989 266.639 159.059C262.709 155.129 257.379 152.921 251.821 152.921H243.495C240.743 152.921 238.018 153.463 235.475 154.517C232.933 155.57 230.623 157.113 228.677 159.059C226.731 161.005 225.187 163.315 224.134 165.858C223.081 168.4 222.539 171.125 222.539 173.877V278.517H122.309C120.247 278.517 118.22 277.984 116.425 276.97C114.629 275.955 113.127 274.494 112.062 272.728C110.998 270.962 110.408 268.951 110.35 266.89C110.292 264.829 110.768 262.788 111.731 260.965L217.813 60.1843C219.136 57.6811 219.938 54.9359 220.17 52.1143C220.402 49.2927 220.06 46.4533 219.165 43.7675C218.27 41.0816 216.839 38.6051 214.961 36.4873C213.082 34.3695 210.793 32.6544 208.233 31.4454L201.971 28.4883C197.108 26.192 191.549 25.853 186.443 27.5412C181.338 29.2295 177.077 32.8159 174.542 37.5583L45.16 279.591C42.9568 283.712 41.8042 288.314 41.8042 292.987V292.987C41.8042 296.719 42.5392 300.414 43.9672 303.861C45.3953 307.309 47.4884 310.441 50.127 313.08C52.7657 315.719 55.8982 317.812 59.3458 319.24C62.7933 320.668 66.4884 321.403 70.22 321.403H222.539V387.57C222.539 390.869 223.189 394.135 224.451 397.183C225.713 400.23 227.563 402.999 229.896 405.332C232.228 407.664 234.998 409.515 238.045 410.777C241.093 412.039 244.359 412.689 247.658 412.689H247.658C250.957 412.689 254.223 412.039 257.27 410.777C260.318 409.515 263.087 407.664 265.42 405.332C267.752 402.999 269.602 400.23 270.865 397.183C272.127 394.135 272.777 390.869 272.777 387.57V321.403H308.311C313.998 321.403 319.452 319.144 323.474 315.122C327.495 311.101 329.754 305.647 329.754 299.96V299.96C329.754 294.273 327.495 288.819 323.474 284.797C319.452 280.776 313.998 278.517 308.311 278.517V278.517Z"
        fill="#6C63FF" />
      <path id="four"
        d="M966.308 278.517H930.773V173.877C930.773 168.319 928.566 162.989 924.636 159.059C920.706 155.129 915.375 152.921 909.818 152.921H901.491C898.739 152.921 896.014 153.463 893.472 154.517C890.929 155.57 888.619 157.113 886.673 159.059C884.727 161.005 883.184 163.315 882.131 165.858C881.077 168.4 880.535 171.125 880.535 173.877V278.517H780.305C778.243 278.517 776.216 277.984 774.421 276.97C772.626 275.955 771.123 274.494 770.059 272.728C768.995 270.962 768.405 268.951 768.347 266.89C768.289 264.829 768.764 262.788 769.728 260.965L875.81 60.1844C877.133 57.6811 877.934 54.9359 878.167 52.1143C878.399 49.2927 878.057 46.4533 877.162 43.7675C876.266 41.0816 874.836 38.6051 872.957 36.4873C871.078 34.3695 868.79 32.6544 866.23 31.4454L859.967 28.4883C855.105 26.192 849.546 25.853 844.44 27.5412C839.334 29.2295 835.073 32.8159 832.538 37.5583L703.157 279.591C700.953 283.712 699.801 288.314 699.801 292.987V292.987C699.801 300.523 702.795 307.751 708.124 313.08C713.453 318.409 720.68 321.403 728.217 321.403H880.535V387.57C880.535 394.232 883.182 400.621 887.893 405.332C892.603 410.043 898.992 412.689 905.654 412.689V412.689C912.316 412.689 918.705 410.043 923.416 405.332C928.127 400.621 930.773 394.232 930.773 387.57V321.403H966.308C971.995 321.403 977.449 319.144 981.47 315.122C985.492 311.101 987.751 305.647 987.751 299.96V299.96C987.751 294.273 985.492 288.819 981.47 284.797C977.449 280.776 971.995 278.517 966.308 278.517Z"
        fill="#6C63FF" />
    </g>
  </svg>
  <h1 class="not-found__title" [innerHTML]="t._('NOT_FOUND_TITLE')"></h1>
  <p class="not-found__text" [innerHTML]="t._('NOT_FOUND_TEXT')"></p>
</div>