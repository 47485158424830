var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppComponent as SharedAppComponent } from "shared/lib/v2/components";
import { InactivityService, PosConnectionService, RoutingService, SentryService, TranslateService, UserService, UtilsService, } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import * as manifestJSON from "../../manifest.json";
export class AppComponent extends SharedAppComponent {
    constructor(_inactivity, _modalCtrl, _user, _routing, _config, _posConnectionService, _utils, _sentry, _translate, _deviceMountingService) {
        super(_inactivity, _modalCtrl, _user, _routing, _config, _posConnectionService, _utils, _sentry, _translate, _deviceMountingService);
        this._inactivity = _inactivity;
        this._modalCtrl = _modalCtrl;
        this._user = _user;
        this._routing = _routing;
        this._config = _config;
        this._posConnectionService = _posConnectionService;
        this._utils = _utils;
        this._sentry = _sentry;
        this._translate = _translate;
        this._deviceMountingService = _deviceMountingService;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return __awaiter(this, void 0, void 0, function* () {
            this.updateManifest(manifestJSON["default"]);
            _super.ngOnInit.call(this);
        });
    }
    updateManifest(manifest) {
        manifest.short_name = this._config.getOrganization().toUpperCase();
        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);
        const manifestLink = document.querySelector("#my-manifest-placeholder");
        const iconLink = document.querySelector("#my-icon-placeholder");
        if (manifestLink)
            manifestLink.setAttribute("href", manifestURL);
        if (iconLink)
            iconLink.setAttribute("href", this._config.logo);
    }
}
