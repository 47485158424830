import { Component, OnInit } from "@angular/core";
import { LOGS, SharedRoutes } from "shared/lib/common/enums";
import {
  TranslateService,
  PrintService,
  ToastService,
  PosConnectionService,
  RoutingService,
  EnvironmentService,
  SentryService,
} from "shared/lib/common/services";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { OverlayEventDetail } from "@ionic/core";
import { ModalController } from "@ionic/angular";
import { AdminService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import { EnsureUnmountLoginModal, EnsureUnmountLoginModalOutputs } from "../ensure-unmount-login-modal/ensure-unmount-login-modal.page";
import { Severity } from "@sentry/browser";

@Component({
  selector: "shared-v2-admin",
  templateUrl: "./admin.page.html",
  styleUrls: ["./admin.page.scss"],
})
export class AdminPage implements OnInit {
  public releaseVersion: string;
  public form: FormGroup;
  public loading: boolean;
  public ip: string;
  public unmmountLoading: boolean;
  public routes: typeof SharedRoutes = SharedRoutes;
  public zoom: number;

  constructor(
    private sentryService: SentryService,
    private environment: EnvironmentService,
    private posConnectionService: PosConnectionService,
    private toast: ToastService,
    private admin: AdminService,
    private fb: FormBuilder,
    private modal: ModalController,
    public config: ConfigService,
    public t: TranslateService,
    public print: PrintService,
    public routing: RoutingService,
  ) {}

  public ngOnInit(): void {
    this.releaseVersion = this.environment.getVariable("RELEASE");
    this.zoom = this.config.getDeviceConfig().zoom;
    this.loading = false;
    this.form = this.fb.group({
      ip: [
        this.print.getIp(),
        [
          Validators.required,
          Validators.pattern(/\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/),
        ],
      ],
    });
  }

  public async testConnection(ip: string): Promise<void> {
    this.loading = true;
    const response = await this.print.testConnection(ip);
    if (response.ok === true) {
      this.print.setIp(ip);
      this.toast.show(this.t._("PRINTER_CONNECTED"), "info");
    } else {
      this.toast.show(this.t._(response.error.message as any), "error");
    }
    this.loading = false;
  }

  public removeIp(): void {
    this.form.reset();
    this.print.removeIp();
  }

  public async logOut(): Promise<void> {
    this.unmmountLoading = true;
    const { data } = await this.presentLogin();

    if (data) {
      let config = {};
      const mountingConfig = this.config.getMountingConfig();
      if (mountingConfig) config = mountingConfig;
      this.sentryService.captureException(LOGS.LOGOUT, {
        extra: {
          ...this.config.getMountingConfig(),
          unmountDatetime: new Date().toISOString(),
        },
        tags: {
          function: "logOut",
          file: "shared/lib/v2/pages/admin/admin.page.ts",
          interactionId: config ? config["appInteractionId"] : null,
        },
        level: Severity.Log,
      });
      const resp = await this.admin.unMountDevice();
      if (resp.ok === false) this.toast.show(this.t._(resp.error.message as any), "error");

      this.admin.logout();
      this.posConnectionService.closeSocketConnection();
      this.routing.resetConfig();

      window.location.href = `${this.config.getOrganization()}/${SharedRoutes.login}`;
    }

    this.unmmountLoading = false;
  }

  public async openBackOffice(): Promise<void> {
    const { Browser } = Plugins;
    await Browser.open({ url: this.environment.getVariable("SELFSERVICE") });
  }

  public reload(): void {
    window.location.reload();
    window.location.href = `${this.config.getOrganization()}/${SharedRoutes.screenSaver}`;
  }

  private async presentLogin(): Promise<OverlayEventDetail<EnsureUnmountLoginModalOutputs>> {
    const modal = await this.modal.create({ component: EnsureUnmountLoginModal });
    await modal.present();
    return await modal.onDidDismiss();
  }
}
