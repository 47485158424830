import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ConfigService, P4mService, RoutingService } from "shared/lib/common/services";
import { SharedRoutes } from "shared/lib/common/enums";

@Injectable({
  providedIn: "root",
})
export class IsMountingDoneGuard implements CanActivate {
  constructor(private routing: RoutingService, public config: ConfigService, public p4m: P4mService) {}

  public canActivate(): boolean {
    if (this.p4m.getUser() && this.p4m.getPassword() && this.p4m.getToken()) {
      const mountingConfig = this.config.getMountingConfig();
      if (mountingConfig.deviceKey) {
        this.routing.goForward([SharedRoutes.screenSaver]);
        return false;
      }
      return true;
    } else {
      this.routing.goForward([SharedRoutes.login]);
      return false;
    }
  }
}
