var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SharedRoutes } from "shared/lib/common/enums";
import { ProductCatalogService, DeviceMountingService, AccountService, RatingConfigService } from "shared/lib/v2/services";
import { ConfigService, UserService, TranslateService, ToastService, InactivityService, RoutingService } from "shared/lib/common/services";
import { ModalController, AlertController } from "@ionic/angular";
import { CalculatorPage } from "shared/lib/v2/pages/calculator/calculator.page";
import { SummaryPage } from "../summary/summary.page";
import { ConfirmationPage, } from "shared/lib/common/pages/confirmation/confirmation.page";
import { CheckPinModalPage } from "shared/lib/common/pages";
export class RevenuePage {
    constructor(routing, t, account, deviceMounting, product, config, modal, userService, toast, inactivity, alert, ratingConfig) {
        this.routing = routing;
        this.t = t;
        this.account = account;
        this.deviceMounting = deviceMounting;
        this.product = product;
        this.config = config;
        this.modal = modal;
        this.userService = userService;
        this.toast = toast;
        this.inactivity = inactivity;
        this.alert = alert;
        this.ratingConfig = ratingConfig;
        this.routes = SharedRoutes;
    }
    ionViewWillEnter() {
        this.loading = true;
        this.level = "group";
        this.earnGroups = [];
        this.earnSubGroups = [];
        this.earnItems = [];
        this.burnGroups = [
            {
                id: "1",
                program: this.config.getOrganization(),
                partnerId: this.config.getMountingConfig().partner.id,
                name: this.t._("REVENUE_STEP1_BURN_1"),
                groupCode: "groupCode",
            },
        ];
        this.burnSubGroups = [];
        this.burnItems = [];
        this.revenues = [];
        this.amount = 0;
        this.points = 0;
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.user = this.userService.getUser();
            yield this.fetchItems("group");
            yield this.fetchProgramRatingConfig();
            this.loading = false;
        });
    }
    ionViewDidLeave() {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeout(this.timeout);
        });
    }
    resetMode() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.mode) {
                const alert = yield this.alert.create({
                    header: this.t._("RESET_REVENUE_CART_TITLE"),
                    message: this.t._("RESET_REVENUE_CART_MESSAGE"),
                    buttons: [
                        {
                            role: "cancel",
                            text: this.t._("CANCEL"),
                        },
                        {
                            text: this.t._("CONFIRM"),
                        },
                    ],
                    backdropDismiss: false,
                });
                yield alert.present();
                const { role } = yield alert.onWillDismiss();
                if (!role || (role && role.toLowerCase() !== "cancel")) {
                    this.mode = null;
                    this.revenues = [];
                    this.groupId = null;
                    this.subGroupId = null;
                    this.level = "group";
                }
            }
        });
    }
    openCalculator(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = { item };
            const calculatorModal = yield this.modal.create({
                component: CalculatorPage,
                componentProps,
                backdropDismiss: false,
            });
            yield calculatorModal.present();
            const { data } = yield calculatorModal.onWillDismiss();
            if (data)
                this.setTransactionPreview(item, data);
        });
    }
    openSummary() {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = { revenues: this.revenues, amount: this.amount, points: this.points, mode: this.mode };
            const summaryModal = yield this.modal.create({
                component: SummaryPage,
                componentProps,
                backdropDismiss: false,
            });
            yield summaryModal.present();
            const { data } = yield summaryModal.onWillDismiss();
            this.revenues = data;
            yield this.updatePreview();
        });
    }
    openConfirmation() {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                title: this.mode === "earn" ? this.t._("CONFIRM_EARN_TRANSACTION_TITLE") : this.t._("CONFIRM_BURN_TRANSACTION_TITLE"),
                subtitle: this.points.toString(),
                confirmText: this.t._("CONFIRM"),
                dismissText: this.t._("CANCEL"),
            };
            const confirmationModal = yield this.modal.create({
                component: ConfirmationPage,
                cssClass: "modal",
                componentProps,
            });
            yield confirmationModal.present();
            const { data } = (yield confirmationModal.onDidDismiss());
            if (data) {
                if (this.deviceMounting.profile.pinRequired) {
                    this.showCheckPinModal(this.deviceMounting.profile.pin ? this.deviceMounting.profile.pin : "");
                }
                else {
                    this.makeTransaction();
                }
            }
        });
    }
    selectItem({ item, addToBasket }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (addToBasket)
                return this.openCalculator(item);
            switch (this.getItemLevel(item)) {
                case "group":
                    this.level = "subGroup";
                    this.groupId = item.id;
                    this.fetchItems("subGroup", this.groupId);
                    break;
                case "subGroup":
                    this.level = "item";
                    this.subGroupId = item.id;
                    this.fetchItems("item", this.groupId, this.subGroupId);
                    break;
            }
        });
    }
    goBack() {
        return __awaiter(this, void 0, void 0, function* () {
            switch (this.level) {
                case "subGroup":
                    this.level = "group";
                    this.groupId = null;
                    this.fetchItems("group");
                    break;
                case "item":
                    this.level = "subGroup";
                    this.subGroupId = null;
                    this.fetchItems("subGroup", this.groupId);
                    break;
            }
        });
    }
    getListTitle() {
        switch (this.level) {
            case "group":
                return this.t._("GROUPS");
            case "subGroup":
                return this.t._("SUBGROUPS");
            case "item":
                return this.t._("ITEMS");
        }
    }
    getName(id, type) {
        switch (type) {
            case "group":
                let group;
                if (this.mode === "earn")
                    group = this.earnGroups.find(g => g.id === id);
                else
                    group = this.burnGroups.find(g => g.id === id);
                return group ? group.name : "";
            case "subgroup":
                let subgroup;
                if (this.mode === "earn")
                    subgroup = this.earnSubGroups.find(g => g.id === id);
                else
                    subgroup = this.burnSubGroups.find(g => g.id === id);
                return subgroup ? subgroup.name : "";
        }
    }
    updatePreview() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const user = this.userService.getUser();
            try {
                const resp = yield this.account
                    .createTransactionPrev(user.keyCode, this.config.getMountingConfig().workstationId, user.idType, this.account.getTransactionData(this.revenues, this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION"))
                    .toPromise();
                this.revenues = resp.lineItems.map(l => {
                    return {
                        name: l.description,
                        points: l.points,
                        money: l.extendedAmount,
                        id: l.itemID,
                        partnerId: this.config.getMountingConfig().partner.id,
                        program: this.config.getOrganization(),
                        groupCode: l.merchandiseGroupCode,
                        subGroupCode: l.merchandiseSubGroupCode,
                    };
                });
                this.amount = resp.amount;
                this.points = resp.points;
            }
            catch (error) {
                const e = error.error && error.error.message ? error.error.message : error.message || error;
                this.toast.show(this.t._(e), "error");
            }
            finally {
                this.loading = false;
            }
        });
    }
    makeTransaction() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const transactions = this.mode === "earn"
                ? this.account.getTransactionData(this.revenues, "EARNTRANSACTION")
                : this.account.getTransactionData(this.revenues, "PAYWITHPOINTSTRANSACTION");
            const user = this.userService.getUser();
            try {
                yield this.account
                    .createTransaction(user.keyCode, this.config.getMountingConfig().workstationId, undefined, user.idType, transactions)
                    .toPromise();
                this.revenues = [];
                this.timeout = setTimeout(() => {
                    this.toast.show(this.t._("ARTS_ERROR"), "error");
                    this.inactivity.resetTimer();
                    clearTimeout(this.timeout);
                    this.loading = false;
                }, 30000);
            }
            catch (error) {
                const e = error.error && error.error.message ? error.error.message : error.message || error;
                this.toast.show(this.t._(e), "error");
                this.loading = false;
            }
        });
    }
    showCheckPinModal(pin) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = { pin };
            const checkPinModal = yield this.modal.create({
                component: CheckPinModalPage,
                cssClass: "modal modal--full-screen",
                componentProps,
            });
            yield checkPinModal.present();
            const { data } = (yield checkPinModal.onDidDismiss());
            if (data)
                this.makeTransaction();
        });
    }
    setTransactionPreview(item, data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const revenue = Object.assign({}, item, { blacklisted: item.blacklisted, money: data, points: data });
            const user = this.userService.getUser();
            try {
                const resp = yield this.account
                    .createTransactionPrev(user.keyCode, this.config.getMountingConfig().workstationId, user.idType, this.account.getTransactionData([...this.revenues, revenue], this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION"))
                    .toPromise();
                if (this.user.totalPoints >= resp.points || this.mode === "earn") {
                    this.revenues = resp.lineItems.map(l => {
                        return {
                            name: l.description,
                            points: l.points,
                            money: l.extendedAmount,
                            id: l.itemID,
                            partnerId: this.config.getMountingConfig().partner.id,
                            program: this.config.getOrganization(),
                            groupCode: l.merchandiseGroupCode,
                            subGroupCode: l.merchandiseSubGroupCode,
                        };
                    });
                    this.amount = resp.amount;
                    this.points = resp.points;
                }
                else
                    this.toast.show(this.t._("NOT_ENOUGH_POINT"), "error");
            }
            catch (error) {
                const e = error.error && error.error.message ? error.error.message : error.message || error;
                this.toast.show(this.t._(e), "error");
            }
            finally {
                this.loading = false;
            }
        });
    }
    fetchItems(level, groupId, subGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                switch (level) {
                    case "group":
                        this.loadingItems = true;
                        this.earnGroups = yield this.product
                            .getGroupsOfPartner(this.config.getOrganization(), this.config.getMountingConfig().partner.id)
                            .toPromise();
                        break;
                    case "subGroup":
                        this.loadingItems = true;
                        this.earnSubGroups = yield this.product
                            .getSubGroupsOfPartnerAndGroup(this.config.getOrganization(), this.config.getMountingConfig().partner.id, groupId)
                            .toPromise();
                        break;
                    case "item":
                        this.loadingItems = true;
                        const patnerItems = yield this.product
                            .getItemsOfPartner(this.config.getOrganization(), this.config.getMountingConfig().partner.id)
                            .toPromise();
                        this.earnItems = patnerItems.filter(i => i.group.groupId === groupId && i.subGroup.subGroupId === subGroupId);
                        break;
                }
                this.loadingItems = false;
            }
            catch (error) {
                this.toast.show(this.t._(error), "error");
            }
        });
    }
    getItemLevel(item) {
        if (item["groupCode"])
            return "group";
        if (item["subGroupCode"])
            return "subGroup";
        return "item";
    }
    fetchProgramRatingConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.ratingConfig.getRatingConfigForProgram(this.config.getOrganization()).toPromise();
            this.hasPayWithPoints = this.allowedPayWithPoints(resp.pwpAllowed);
            if (!this.hasPayWithPoints)
                this.mode = "earn";
        });
    }
    allowedPayWithPoints(pwpAllowed) {
        switch (pwpAllowed) {
            case "NO":
                return false;
            case "REGISTEREDUSERS":
                return this.user.isRegistered;
            case "ALLUSERS":
                return true;
        }
    }
}
