import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PrintService, RoutingService, ToastService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { IRegisterModalPageOutputs, IRegisterModalPageInputs } from "shared/lib/common/pages";
import { BurnPage as SharedBurnPage } from "shared/lib/common/pages";
import { AdminService, AccountCouponService, RatingConfigService } from "shared/lib/v2/services";
import { IRegistrationCode, IVoucher, VoucherType } from "shared/lib/common/interfaces";
import { SharedRoutes } from "shared/lib/common/enums";
import { RegisterPage } from "../register/register.page";
import { ConfigService } from "shared/lib/common/services";
import { DecimalPipe } from "@angular/common";
import { ICouponCardInput, CouponCardPage, ICouponCardOutput } from "shared/lib/v2/pages/coupon-card/coupon-card.page";
import { RatingConfig } from "../../apis/programs";

@Component({
  selector: "shared-v2-burn",
  templateUrl: "./burn.page.html",
  styleUrls: ["./burn.page.scss"],
})
export class BurnPage extends SharedBurnPage implements OnInit {
  public routes: typeof SharedRoutes = SharedRoutes;
  public currency: string;
  public partnerId: string;

  constructor(
    public _translate: TranslateService,
    public _user: UserService,
    public _utils: UtilsService,
    public _routing: RoutingService,
    private _modal: ModalController,
    private _toast: ToastService,
    private _config: ConfigService,
    private _print: PrintService,
    private _admin: AdminService,
    private _accountCouponService: AccountCouponService,
    private _decimal: DecimalPipe,
    private _ratingConfig: RatingConfigService,
  ) {
    super(_translate, _user, _utils, _modal, _toast, _config, _print, _admin, _routing);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.partnerId = this._config.getMountingConfig().partner.id;
  }

  public async ionViewDidEnter(): Promise<void> {
    super.ionViewDidEnter();
    const ratingConfig: RatingConfig = await this._ratingConfig.getRatingConfigForProgram(this._config.getOrganization()).toPromise();
    this.currency = ratingConfig.currency;
  }

  public async changeActivationStatus(coupon: IVoucher): Promise<void> {
    await this._accountCouponService
      .changeActivationStatus(coupon.accountVoucherId, { activated: !coupon.activation.activated })
      .toPromise();
    const c = await this._accountCouponService.getAccountCoupon(coupon.accountVoucherId).toPromise();
    this._user.updateUserCoupon(c);
    this.tickets = this.sortTickets(this.user.getVouchers());
    this.ticketSelected = this.tickets.find(t => t.voucherId === coupon.voucherId);
  }

  public getPoints(coupon: IVoucher): string {
    let points = "";
    if (coupon) {
      points = this._decimal.transform(coupon.points, "", this._translate.getSessionLanguage());
      if (coupon.voucherType === VoucherType.coupon) {
        if (coupon.points) {
          switch (coupon.couponTypeCode) {
            case "EXTRAPOINT":
              return `+${points}`;
            case "MULTIPLIER":
              return `${points}x`;
            case "DISCOUNT":
              if (coupon.redeemActions[0].actionType === "NOMINALDISCOUNTACTION") return `${points}${this.currency}`;
              else return `${points}%`;
          }
        } else return this._translate._("FREE");
      }
    }
    return points;
  }

  public getText(coupon: IVoucher): string {
    if (coupon) {
      switch (coupon.voucherType) {
        case VoucherType.coupon:
          if (coupon.points) {
            switch (coupon.couponTypeCode) {
              case "EXTRAPOINT":
                return this._translate._("EXTRAPOINT");
              case "MULTIPLIER":
                return this._translate._("MULTIPLIER");
              case "DISCOUNT":
                return this._translate._("DISCOUNT");
              default:
                return this._translate._("EARN");
            }
          } else return "";
        case VoucherType.reward:
          return this._translate._("POINTS");
      }
    }
  }

  public async selectTicket(ticket: IVoucher): Promise<void> {
    this.ticketSelected = ticket;
    if (this.mobileVersion) {
      const componentProps: ICouponCardInput = {
        ticketSelected: this.ticketSelected,
        showRegisterButton: this.showRegisterButton,
        registerMandatory: this.needToShowRegisterModal(this.ticketSelected),
      };
      const couponModal = await this._modal.create({
        component: CouponCardPage,
        cssClass: "modal",
        componentProps,
      });
      await couponModal.present();
      const { data } = (await couponModal.onDidDismiss()) as ICouponCardOutput;
      if (data) {
        if (data.event === "activation") this.changeActivationStatus(data.voucher);
        if (data.event === "redeem") this.askToRedeem(data.voucher);
      }
    }
  }

  protected async showRegisterModal(
    keyCode: string,
    userReferenceCode: string,
    registrationType: IRegistrationCode,
  ): Promise<IRegisterModalPageOutputs> {
    const componentProps: IRegisterModalPageInputs = {
      registrationType,
      keyCode,
      userReferenceCode,
    };
    const registerModal = await this._modal.create({
      component: RegisterPage,
      cssClass: "modal modal--full-screen",
      componentProps,
    });
    await registerModal.present();

    return (await registerModal.onDidDismiss()) as IRegisterModalPageOutputs;
  }
}
