import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { Deploy } from "cordova-plugin-ionic/dist/ngx";
import { SharedModule } from "shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DeviceMountingService, InterceptorService, ProgramService } from "shared/lib/v2/services";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ThirdPartyScriptsService, ConfigService } from "shared/lib/common/services";
import { BLACK_LIST } from "shared/lib/common/constants";
import { ProgramConfig } from "shared/lib/common/interfaces";
import { Injector } from "@angular/core";
import { environment } from "shared/lib/common/environments/environment";

async function getSubdomainConfig(program: ProgramService): Promise<{ subdomainOrg: string; subdomainProgramConfig: ProgramConfig }> {
  const subdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0];
  const isBlackListDomain = BLACK_LIST.some(host => subdomain.includes(host));
  if (!isBlackListDomain) {
    const subdomainProgramConfig = await program.getProgramConfig(subdomain).toPromise();
    return { subdomainOrg: subdomain, subdomainProgramConfig };
  }

  return { subdomainOrg: null, subdomainProgramConfig: null };
}

async function getPathConfig(program: ProgramService): Promise<{ pathOrg: string; pathProgramConfig: ProgramConfig }> {
  const pathOrg = window.location.pathname.split("/")[1];
  const pathProgramConfig = await program.getProgramConfig(pathOrg).toPromise();
  return { pathOrg, pathProgramConfig };
}

async function setConfig(org: string, programConfig: ProgramConfig, config: ConfigService): Promise<void> {
  config.setOrganization(org);
  await config.setConfig(programConfig);
}

export function initializeApp(injector: Injector): () => void {
  return async () => {
    const thirdParty = injector.get<ThirdPartyScriptsService>(ThirdPartyScriptsService);
    const program = injector.get<ProgramService>(ProgramService);
    const deviceMounting = injector.get<DeviceMountingService>(DeviceMountingService);
    const config = injector.get<ConfigService>(ConfigService);
    const router = injector.get<Router>(Router);
    await thirdParty.addThirdPartyScripts();
    const { subdomainOrg, subdomainProgramConfig } = await getSubdomainConfig(program);
    const { pathOrg, pathProgramConfig } = await getPathConfig(program);
    if (subdomainProgramConfig && pathProgramConfig && subdomainOrg === pathOrg) {
      await setConfig(pathOrg, pathProgramConfig, config);
    } else if (subdomainProgramConfig && pathProgramConfig) {
      router.navigateByUrl("");
    } else if (subdomainProgramConfig) {
      await setConfig(subdomainOrg, subdomainProgramConfig, config);
      window.location.href = `${subdomainOrg}`;
    } else if (pathProgramConfig) {
      await setConfig(pathOrg, pathProgramConfig, config);
    } else {
      router.navigateByUrl("");
    }
    const res = await deviceMounting.getMounting();
    if (res.ok === true) config.setMountingConfig(await deviceMounting.parseMountingConfigV1ToV2(res.response));
  };
}

const pages = [];

const modals = [];

@NgModule({
  entryComponents: [modals],
  declarations: [AppComponent, pages, modals],
  imports: [
    ZXingScannerModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    IonicModule.forRoot({
      mode: "md",
      keyboardHeight: 0,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    Deploy,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
