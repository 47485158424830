import { Injectable } from "@angular/core";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { ENVIRONMENT_VARIABLES } from "shared/lib/common/enums";
import { environment } from "shared/lib/common/environments/environment";
import { environmentVariables, org } from "shared/lib/common/environments/environment.constant";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  constructor(private config: ConfigService) {}

  public getVariable(variable: ENVIRONMENT_VARIABLES): string {
    const organization = this.config.getOrganization();
    const variables = {
      ...environment,
      ...environmentVariables[`${environment.BUILD_ENV}_${organization}`],
    };

    if (variables[variable].includes("point4more")) {
      return organization === "pfm" ? variables[variable].replace(org, "") : variables[variable].replace(org, `${organization}.`);
    } else {
      return variables[variable].replace(org, organization);
    }
  }
}
