import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Configuration } from '../configuration';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
export class AccountCouponService {
    constructor(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://staging.convercus.io';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }
    addToHttpParams(httpParams, value, key) {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        }
        else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }
    addToHttpParamsRecursive(httpParams, value, key) {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            }
            else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, value.toISOString().substr(0, 10));
                }
                else {
                    throw Error("key may not be null if value is Date");
                }
            }
            else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        }
        else if (key != null) {
            httpParams = httpParams.append(key, value);
        }
        else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }
    getAccountCoupon(accountcouponid, interactionId, observe = 'body', reportProgress = false, options) {
        if (accountcouponid === null || accountcouponid === undefined) {
            throw new Error('Required parameter accountcouponid was null or undefined when calling getAccountCoupon.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountCoupon.');
        }
        let headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        let httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(`${this.configuration.basePath}/accountcoupons/${encodeURIComponent(String(accountcouponid))}`, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    getCouponCode(accountcouponid, interactionId, observe = 'body', reportProgress = false, options) {
        if (accountcouponid === null || accountcouponid === undefined) {
            throw new Error('Required parameter accountcouponid was null or undefined when calling getCouponCode.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getCouponCode.');
        }
        let headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        let httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(`${this.configuration.basePath}/accountcoupons/${encodeURIComponent(String(accountcouponid))}/code`, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    redeemAccountCouponPatch(accountcouponid, interactionId, observe = 'body', reportProgress = false, options) {
        if (accountcouponid === null || accountcouponid === undefined) {
            throw new Error('Required parameter accountcouponid was null or undefined when calling redeemAccountCouponPatch.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling redeemAccountCouponPatch.');
        }
        let headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        let httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.patch(`${this.configuration.basePath}/accountcoupons/${encodeURIComponent(String(accountcouponid))}`, null, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    redeemAccountCouponPut(accountcouponid, interactionId, observe = 'body', reportProgress = false, options) {
        if (accountcouponid === null || accountcouponid === undefined) {
            throw new Error('Required parameter accountcouponid was null or undefined when calling redeemAccountCouponPut.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling redeemAccountCouponPut.');
        }
        let headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        let httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        let responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(`${this.configuration.basePath}/accountcoupons/${encodeURIComponent(String(accountcouponid))}`, null, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
AccountCouponService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountCouponService_Factory() { return new AccountCouponService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8)); }, token: AccountCouponService, providedIn: "root" });
