/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Condition } from './condition';
import { Action } from './action';
import { CustomProperty } from './custom-property';
import { Image } from './image';
import { CouponDataI18nFields } from './coupon-data-i18n-fields';
import { ProgramUnit } from './program-unit';
import { ExternalCode } from './external-code';


export interface CouponData { 
    /**
     * Whether the coupon is activatable or not
     */
    activatable?: boolean;
    /**
     * Describes if this coupon can be assigned or its conditions get calculated dynamically
     */
    assignationType?: CouponData.AssignationTypeEnum;
    /**
     * Array of conditions that must be fullfilled for user assignment
     */
    conditions?: Array<Condition>;
    /**
     * Array of custom properties
     */
    customProperties?: Array<CustomProperty>;
    externalCode?: ExternalCode;
    /**
     * external identifier
     */
    externalReference?: string;
    i18nFields: { [key: string]: CouponDataI18nFields; };
    /**
     * Array of images including tags and urls
     */
    images?: Array<Image>;
    /**
     * Array of actions that will be executed at redemption
     */
    redeemActions?: Array<Action>;
    sponsor?: ProgramUnit;
    /**
     * Trigger for the coupon to be applied
     */
    trigger: CouponData.TriggerEnum;
    usageType: CouponData.UsageTypeEnum;
}
export namespace CouponData {
    export type AssignationTypeEnum = 'DYNAMIC' | 'CAMPAIGN';
    export const AssignationTypeEnum = {
        DYNAMIC: 'DYNAMIC' as AssignationTypeEnum,
        CAMPAIGN: 'CAMPAIGN' as AssignationTypeEnum
    };
    export type TriggerEnum = 'TRANSACTION' | 'MANUAL';
    export const TriggerEnum = {
        TRANSACTION: 'TRANSACTION' as TriggerEnum,
        MANUAL: 'MANUAL' as TriggerEnum
    };
    export type UsageTypeEnum = 'INCENTIVE' | 'REWARD' | 'DISCOUNT' | 'MULTIPLIER' | 'CAMPAIGN' | 'EXTRAPOINT';
    export const UsageTypeEnum = {
        INCENTIVE: 'INCENTIVE' as UsageTypeEnum,
        REWARD: 'REWARD' as UsageTypeEnum,
        DISCOUNT: 'DISCOUNT' as UsageTypeEnum,
        MULTIPLIER: 'MULTIPLIER' as UsageTypeEnum,
        CAMPAIGN: 'CAMPAIGN' as UsageTypeEnum,
        EXTRAPOINT: 'EXTRAPOINT' as UsageTypeEnum
    };
}


