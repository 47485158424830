<ion-content scroll-y="false">
  <div ion-button class="page-wrapper burn-page">
    <div class="burn-page__status">
      <div class="burn-page__status__balance">
        <h1 class="burn-page__status__balance__title">
          {{ translate._("YOUR_BALANCE") }}
        </h1>
        <p class="burn-page__status__balance__number">
          {{ totalPoints | number: "":translate.getSessionLanguage() }}
        </p>
        <div class="burn-page__status__balance__text">
          {{ translate._("POINTS") }}
        </div>
      </div>
    </div>

    <div class="burn-page__buttons-wrapper">
      <ion-button
        *ngIf="partnerId"
        (tap)="_routing.goForward([routes.revenue])"
        class="button button--secondary burn-page__buttons-wrapper__button"
        color="secondary"
        fill="solid"
      >
        <ion-icon class="burn-page__buttons-wrapper__button__icon" name="cart"></ion-icon>
        <span class="burn-page__buttons-wrapper__button__text"> {{ translate._("REVENUE") }}</span>
      </ion-button>
      <ion-button
        (tap)="_routing.goForward([routes.history])"
        class="button button--secondary burn-page__buttons-wrapper__button"
        color="secondary"
        fill="solid"
      >
        <i-clock class="burn-page__buttons-wrapper__button__icon"></i-clock>
        <span class="burn-page__buttons-wrapper__button__text"> {{ translate._("HISTORY") }}</span>
      </ion-button>
      <ion-button (tap)="logOutUser()" class="button button--danger burn-page__buttons-wrapper__button" color="danger" fill="solid">
        <i-log-out class="burn-page__buttons-wrapper__button__icon"></i-log-out>
        <span class="burn-page__buttons-wrapper__button__text"> {{ translate._("LOG_OUT") }}</span>
      </ion-button>
    </div>

    <div class="burn-page__wrapper">
      <div *ngIf="!loading && !mobileVersion" class="burn-page__wrapper__column">
        <shared-v2-coupon-card
          [ticketSelected]="ticketSelected"
          [isCouponLocked]="!ticketSelected?.unlocked"
          [showRegisterButton]="showRegisterButton"
          [isCouponRegisterMandatory]="needToShowRegisterModal(ticketSelected)"
          [loading]="registerLoading"
          (registerEvent)="goToRegister()"
          (redeemEvent)="askToRedeem($event)"
          (activateEvent)="changeActivationStatus($event)"
        ></shared-v2-coupon-card>
      </div>
      <ion-spinner *ngIf="loading" class="burn-page__wrapper__spinner"></ion-spinner>
      <div *ngIf="!loading && tickets?.length > 0" class="burn-page__wrapper__column">
        <ion-list lines="none" class="burn-page__wrapper__column__ticket-list">
          <ion-item
            no-padding
            class="burn-page__wrapper__column__ticket-list__item"
            [disabled]="!ticket.unlocked"
            *ngFor="let ticket of tickets"
            (click)="selectTicket(ticket)"
          >
            <div
              class="burn-page__wrapper__column__ticket-list__item__ticket"
              [class.--active]="ticketSelected?.voucherId === ticket.voucherId"
            >
              <div class="burn-page__wrapper__column__ticket-list__item__ticket__points">
                <p
                  class="burn-page__wrapper__column__ticket-list__item__ticket__points__points"
                  [class.--free]="ticket.voucherType === 'coupon'"
                >
                  {{getPoints(ticket)}}<span
                    class="burn-page__wrapper__column__ticket-list__item__ticket__points__points__text"
                    [class.--free]="ticket.voucherType === 'coupon'"
                    >{{getText(ticket)}}</span
                  >
                </p>
                <p
                  class="burn-page__wrapper__column__ticket-list__item__ticket__points__active"
                  [class.--active]="ticket?.activation?.activated"
                  *ngIf="ticket?.activation?.activatable && (ticket?.couponTypeCode === 'MULTIPLIER' || ticket?.couponTypeCode === 'EXTRAPOINT')"
                >
                  {{ ticket?.activation?.activated ? translate._('ACTIVATED') : translate._('DISABLED') }}
                </p>
              </div>
              <div class=" burn-page__wrapper__column__ticket-list__item__ticket__title">
                <p>{{ ticket?.title }}</p>
                <div
                  class="burn-page__wrapper__column__ticket-list__item__ticket__title__date"
                  *ngIf="!ticket.unlocked && utils.lockedDueToDates(ticket)"
                >
                  <span *ngIf="ticket?.validFromDate && ticket?.validToDate">
                    {{ ticket?.voucherType === "coupon" ? translate._( "VALID_BETWEEN", ticket?.validFromDate.unixTimeStamp | date:
                    "dd.MM.yy", ticket?.validToDate.unixTimeStamp | date: "dd.MM.yy" ) : "" }}
                  </span>
                  <span *ngIf="ticket?.validFromDate && !ticket.validToDate">
                    {{ ticket?.voucherType === "coupon" ? translate._("VALID_IN_DAYS", utils.getNumberOfDays(ticket)) : "" }}
                  </span>
                </div>
              </div>
              <div
                class="burn-page__wrapper__column__ticket-list__item__ticket__image"
                [style.backgroundImage]="ticket?.teaserPictureFilePath?.file ? 'url(' + ticket?.teaserPictureFilePath?.file + ')' : ''"
              ></div>
            </div>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>
<shared-loading *ngIf="redeemLoading"></shared-loading>
