var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LOCAL_STORAGE, LOGS } from "shared/lib/common/enums";
import { ActionType, IActionDefinition, IArtsResponse, IClientComplet, IDeviceManagementAuthenticationResponse, IDisplayAd, IDownloadDisplayAdsResponse, IGetAllClientsResponse, IGetPermissionUserResponse, IGetStoresOfClientResponse, IMount, IMountDeviceResponse, IPartner, IPromotion, IPromotions, IRegister, IStore, IStoreAPI2, IUnMountDeviceResponse, IRegistrationCode, IExtendedRevenuePointsManuallyResponse, IIdentifierType, } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "../translate/translate.service";
import { Severity } from "@sentry/browser";
import * as i0 from "@angular/core";
import * as i1 from "../translate/translate.service";
import * as i2 from "../p4m/p4m.service";
import * as i3 from "../utils/utils.service";
import * as i4 from "../config/config.service";
import * as i5 from "../sentry/sentry.service";
export class AdminService {
    constructor(translate, p4m, utils, configService, sentry) {
        this.translate = translate;
        this.p4m = p4m;
        this.utils = utils;
        this.configService = configService;
        this.sentry = sentry;
    }
    getRegistrationType() {
        return __awaiter(this, void 0, void 0, function* () {
            let registrationType = "NO";
            const resp = yield this.getAllClients();
            if (resp.ok === true) {
                const client = resp.response.find(c => String(c.clientId) === this.configService.getMountingConfig().clientId);
                if (client && client.alwaysShowRegistration) {
                    switch (this.configService.getMountingConfig().posMode) {
                        case "STD":
                            registrationType = client.showRegistrationCode;
                            break;
                        case "SER":
                            registrationType = client.registrationModePosRegistrationTypeCode;
                            break;
                    }
                }
                return { ok: true, response: registrationType };
            }
            return resp;
        });
    }
    extendedRevenuePointsManually(keyCode, amount, extendedRevenueFactsId, idType, externalId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.p4m.extendedRevenuePointsManually({
                organization: this.configService.getOrganization(),
                language: this.translate.getSessionLanguage(),
                deviceKey: this.configService.getMountingConfig().deviceKey,
                keyCode,
                extendedRevenueFactsId,
            });
        });
    }
    arts(date, amount, keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            const { workstationId, store } = this.configService.getMountingConfig();
            const resp = yield this.p4m.arts({ date, amount, apiKey: this.getApiKey(), workstationId, vatId: store && store.vatId, keyCode });
            const response = this.parseResponse(resp);
            if (response.ok === true && response.response.successInfo && response.response.successInfo.successCode === "BON_ID_ALREADY_EXISTS") {
                return { ok: false, error: { message: "BON_ID_ALREADY_EXISTS" } };
            }
            return response;
        });
    }
    readProgramPartners() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.p4m.readProgramPartners();
        });
    }
    readPartnerStores(partnerId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.p4m.readPartnerStores(partnerId);
        });
    }
    getAllClients() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const refresh = yield this.p4m.deviceManagementLogin({
                    userName: this.p4m.getUser(),
                    organization: this.configService.getOrganization(),
                    password: this.p4m.getPassword(),
                });
                if (refresh.ok === true) {
                    const response = this.parseDeviceManagementLogin(refresh.response);
                    if (response.ok === true) {
                        const resp = this.parseResponse(yield this.p4m.getAllClients({
                            apiKey: this.getMountingApiKey(),
                            organization: this.configService.getOrganization(),
                        }));
                        if (resp.ok === true) {
                            return { ok: true, response: resp.response.clients };
                        }
                        else {
                            return resp;
                        }
                    }
                    else {
                        return response;
                    }
                }
                else {
                    return refresh;
                }
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.getAllClients" });
                return { ok: false, error };
            }
        });
    }
    getClientStore() {
        try {
            return { ok: true, response: JSON.parse(this.getStore()) };
        }
        catch (error) {
            this.sentry.handleError({
                err: error,
                method: "admin.getClientStore",
            });
            return { ok: false, error };
        }
    }
    fetchStoresOfClient(clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const refresh = yield this.p4m.loginToGetApiKey({
                    organization: this.configService.getOrganization(),
                    userName: this.p4m.getUser(),
                    password: this.p4m.getPassword(),
                });
                if (refresh.ok === true) {
                    const refreshParsed = this.parseResponse(refresh);
                    if (refreshParsed.ok === true) {
                        const resp = this.parseResponse(yield this.p4m.getStoresOfClient({
                            organization: this.configService.getOrganization(),
                            apiKey: this.getMountingApiKey(),
                            clientId,
                        }));
                        if (resp.ok === true) {
                            if (this.configService.getMountingConfig().storeName) {
                                this.setStore(resp.response.stores.find(s => s.storeName === this.configService.getMountingConfig().storeName));
                            }
                            return { ok: true, response: resp.response.stores };
                        }
                        else {
                            return resp;
                        }
                    }
                    else {
                        return refreshParsed;
                    }
                }
                else {
                    return refresh;
                }
            }
            catch (error) {
                this.sentry.handleError({
                    err: error,
                    method: "admin.fetchStoresOfClient",
                });
                return { ok: false, error };
            }
        });
    }
    getDisplayAds(deviceKey) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield this.p4m.downloadDisplayAds({
                    organization: this.configService.getOrganization(),
                    apiKey: this.getMountingApiKey(),
                    deviceKey,
                });
                if (resp.ok === true) {
                    return { ok: true, response: this.parseDisplayAds(resp.response) };
                }
                else {
                    return resp;
                }
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.getDisplayAds" });
                return { ok: false, error };
            }
        });
    }
    mountDevice(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const apiKey = yield this.getApiKeyProcess(this.configService.getOrganization(), params.clientId);
                if (apiKey.ok === true) {
                    this.setApiKey(apiKey.response);
                }
                else {
                    return apiKey;
                }
                return this.parseResponse(yield this.p4m.mountDevice({
                    organization: this.configService.getOrganization(),
                    apiKey: this.getMountingApiKey(),
                    clientId: params.clientId,
                    authenticationKey: params.authenticationKey,
                    cameraPositionCode: params.cameraPositionCode,
                    deviceInformation: params.deviceInformation,
                    deviceModel: params.deviceModel,
                    language: params.language,
                    osCode: params.osCode,
                    posModeCode: params.posModeCode,
                    storeId: params.storeId,
                    workstationId: params.workstationId,
                }));
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.mountDevice" });
                return { ok: false, error };
            }
        });
    }
    unMountDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            let config = {};
            const mountingConfig = this.configService.getMountingConfig();
            if (mountingConfig)
                config = mountingConfig;
            this.sentry.captureException(LOGS.UNMOUNT, {
                extra: Object.assign({}, config, { unmountDatetime: new Date().toISOString() }),
                tags: {
                    function: "unMountDevice",
                    file: "shared/lib/common/services/admin/admin.service.ts",
                    interactionId: config ? config["appInteractionId"] : null,
                },
                level: Severity.Log,
            });
            try {
                const refresh = yield this.p4m.loginToGetApiKey({
                    userName: this.p4m.getUser(),
                    password: this.p4m.getPassword(),
                    organization: this.configService.getOrganization(),
                });
                if (refresh.ok === true) {
                    const response = this.parseResponse(refresh);
                    if (response.ok === true) {
                        return this.parseResponse(yield this.p4m.unMountDevice({
                            organization: this.configService.getOrganization(),
                            apiKey: this.getMountingApiKey(),
                            deviceId: this.configService.getMountingConfig().deviceId.toFixed(),
                            fromDevice: true,
                        }));
                    }
                    else {
                        return response;
                    }
                }
                else {
                    return refresh;
                }
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.unMountDevice" });
                return { ok: false, error };
            }
        });
    }
    isValidPin(pin) {
        const pinCode = this.getPinCode();
        return pinCode && pinCode === pin;
    }
    adminLogin(email, organization, password) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const loginResponse = yield this.AdminMultiLogin(email, organization, password);
                if (loginResponse.ok === true) {
                    this.p4m.setUser(email);
                    this.p4m.setPassword(password);
                    this.p4m.setToken(loginResponse.response.token);
                    this.setMountingApiKey(loginResponse.response.mountingApiKey);
                    return {
                        ok: true,
                        response: {
                            mountingApiKey: loginResponse.response.mountingApiKey,
                            token: loginResponse.response.token,
                        },
                    };
                }
                else {
                    return loginResponse;
                }
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.adminLogin" });
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
    reLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.p4m.login({
                    email: this.p4m.getUser(),
                    organization: this.configService.getOrganization(),
                    password: this.p4m.getPassword(),
                });
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.reLogin" });
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
    getPromotions() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.p4m.getActionDefinitions({
                organization: this.configService.getOrganization(),
                deviceKey: this.configService.getMountingConfig().deviceKey,
            });
            let actionDefinitions = [];
            if (res.ok) {
                actionDefinitions = res.response.actionDefinitions;
            }
            const keyValue = {};
            (actionDefinitions || []).forEach(a => {
                keyValue[a.actionType] = {
                    factor: a.factor,
                    name: a.campaignName,
                    points: 0,
                    revenue: 0,
                    type: a.actionType,
                };
            });
            return {
                rmc1: keyValue[ActionType.RMC1],
                rmc2: keyValue[ActionType.RMC2],
                rmc3: keyValue[ActionType.RMC3],
                rmt: keyValue[ActionType.RMT],
                rmb: keyValue[ActionType.RMB],
            };
        });
    }
    logout() {
        localStorage.clear();
        document.cookie.split(";").forEach(c => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    }
    isAuthenticated() {
        if (this.p4m.getUser() && this.p4m.getPassword()) {
            return true;
        }
        else {
            return false;
        }
    }
    getPinCode() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.PIN_CODE));
    }
    getMountingApiKey() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_APIKEY));
    }
    getApiKey() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.APIKEY));
    }
    validateSaveNewPinParams(pin) {
        if (!pin)
            return { ok: false, error: { message: "EMPTY_PIN" } };
        if (pin.length <= 3) {
            return { ok: false, error: { message: "SHORT_PIN" } };
        }
        return { ok: true, response: { pin } };
    }
    parseGetPermissionUser(response) {
        if (!response.errorInfo) {
            return { ok: true, response: response.user.apiKey };
        }
        else {
            return { ok: false, error: { message: response.errorInfo.errorCode } };
        }
    }
    parseResponse(response) {
        if (response.ok === true) {
            if (!response.response.errorInfo) {
                return response;
            }
            else {
                if (response.response.errorInfo.exceptionMessage &&
                    response.response.errorInfo.exceptionMessage.includes("workstation_id_unique")) {
                    return {
                        ok: false,
                        error: {
                            message: "Please verify your workstation ID (it has to be unique) and get in contact with convercus service",
                        },
                    };
                }
                return { ok: false, error: { message: response.response.errorInfo.errorCode } };
            }
        }
        else {
            return response;
        }
    }
    register(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const simpleResponse = yield this.p4m.register({
                organization: this.configService.getOrganization(),
                channelCode: params.simple.channelCode,
                emailAddress: params.simple.emailAddress,
                keyCode: params.simple.keyCode,
                language: params.simple.language,
                clientOptins: params.simple.clientOptins,
                clientId: params.simple.clientId,
            });
            if (simpleResponse.ok === true) {
                if (params.type !== "SIM") {
                    return this.parseResponse(yield this.p4m.updateProfileExtendedRegistration({
                        organization: this.configService.getOrganization(),
                        birthdate: params.extended.birthdate,
                        city: params.extended.city,
                        countryCode: params.extended.countryCode,
                        deviceKey: params.extended.deviceKey,
                        emailAddress: params.extended.emailAddress,
                        familyName: params.extended.familyName,
                        genderCode: params.extended.genderCode || "NA",
                        givenName: params.extended.givenName,
                        phoneNumber: params.extended.phoneNumber,
                        streetHouseNo: params.extended.streetHouseNo,
                        userReferenceCode: params.extended.userReferenceCode,
                        zipCode: params.extended.zipCode,
                        clientId: params.simple.clientOptins ? params.simple.clientOptins[0].clientId : undefined,
                        dataOptin: params.simple.clientOptins ? params.simple.clientOptins[0].dataOptin : undefined,
                        emailOptin: params.simple.clientOptins ? params.simple.clientOptins[0].emailOptin : undefined,
                    }));
                }
                return this.parseResponse(simpleResponse);
            }
            return simpleResponse;
        });
    }
    setApiKey(apiKey) {
        localStorage.setItem(LOCAL_STORAGE.APIKEY, this.utils.encrypt(apiKey));
    }
    setMountingApiKey(mountingApiKey) {
        localStorage.setItem(LOCAL_STORAGE.MOUNTING_APIKEY, this.utils.encrypt(mountingApiKey));
    }
    getStore() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.STORE));
    }
    setStore(store) {
        localStorage.setItem(LOCAL_STORAGE.STORE, this.utils.encrypt(JSON.stringify(store)));
    }
    parseDisplayAds(displayAds) {
        const resp = [];
        if (displayAds && displayAds.displayAds) {
            displayAds.displayAds.forEach(d => {
                resp.push(d);
            });
        }
        return resp;
    }
    AdminMultiLogin(email, organization, password) {
        return __awaiter(this, void 0, void 0, function* () {
            let token, mountingApiKey;
            const loginResp = yield this.p4m.loginToGetApiKey({ userName: email, organization, password });
            if (loginResp.ok === true) {
                const resp = this.parseResponse(loginResp);
                if (resp.ok === false) {
                    return resp;
                }
            }
            else {
                return loginResp;
            }
            const response = yield Promise.all([
                this.p4m.login({ email, organization, password }),
                this.p4m.deviceManagementLogin({ userName: email, organization, password }),
            ]);
            const isValidLogin = response.filter(({ ok }) => !ok);
            if (isValidLogin.length === 0) {
                if (response[0].ok === true) {
                    token = response[0].response;
                }
                else {
                    return response[0];
                }
                if (response[1].ok === true) {
                    const resp = this.parseDeviceManagementLogin(response[1].response);
                    if (resp.ok === true) {
                        mountingApiKey = resp.response;
                    }
                    else {
                        return resp;
                    }
                }
                else {
                    return response[1];
                }
                return {
                    ok: true,
                    response: {
                        mountingApiKey,
                        token,
                    },
                };
            }
            else {
                return isValidLogin[0];
            }
        });
    }
    parseDeviceManagementLogin(response) {
        if (!response.errorInfo) {
            return { ok: true, response: response.authenticationKey };
        }
        else {
            return { ok: false, error: { message: response.errorInfo.errorCode } };
        }
    }
    getApiKeyProcess(organization, clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userPermissionResponse = yield this.p4m.getPermissionUser({ clientId, organization });
            if (userPermissionResponse.ok === true) {
                return this.parseGetPermissionUser(userPermissionResponse.response);
            }
            else {
                return userPermissionResponse;
            }
        });
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.P4mService), i0.ɵɵinject(i3.UtilsService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.SentryService)); }, token: AdminService, providedIn: "root" });
