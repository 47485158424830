/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qrscanner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./qrscanner.component";
import * as i3 from "../../../common/services/utils/utils.service";
import * as i4 from "@ionic/angular";
var styles_QrscannerComponent = [i0.styles];
var RenderType_QrscannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrscannerComponent, data: {} });
export { RenderType_QrscannerComponent as RenderType_QrscannerComponent };
export function View_QrscannerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { canvasElement: 0 }), i1.ɵqud(402653184, 2, { video: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "qrscanner"]], [[2, "--show", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["video", 1]], null, 0, "video", [["autoplay", ""]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [["class", "qrscanner__canvas"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scanerVisible; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "qrscanner__video --", _co.cameraPosition, ""); _ck(_v, 3, 0, currVal_1); }); }
export function View_QrscannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-qrscanner", [], null, null, null, View_QrscannerComponent_0, RenderType_QrscannerComponent)), i1.ɵdid(1, 770048, null, 0, i2.QrscannerComponent, [i3.UtilsService, i4.Platform], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrscannerComponentNgFactory = i1.ɵccf("shared-qrscanner", i2.QrscannerComponent, View_QrscannerComponent_Host_0, { scannerEnabled: "scannerEnabled", device: "device", deviceConfig: "deviceConfig", cameraPosition: "cameraPosition" }, { scanSuccess: "scanSuccess", scanFailure: "scanFailure", camerasFound: "camerasFound", hasDevices: "hasDevices", permissionResponse: "permissionResponse" }, []);
export { QrscannerComponentNgFactory as QrscannerComponentNgFactory };
