/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./connection-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "angular-feather";
import * as i4 from "@angular/common";
import * as i5 from "./connection-banner.component";
import * as i6 from "../../../common/services/translate/translate.service";
var styles_ConnectionBannerComponent = [i0.styles];
var RenderType_ConnectionBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConnectionBannerComponent, data: {} });
export { RenderType_ConnectionBannerComponent as RenderType_ConnectionBannerComponent };
function View_ConnectionBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "connection-banner__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("NO_CONNECTION"); _ck(_v, 1, 0, currVal_0); }); }
function View_ConnectionBannerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "connection-banner__text"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.reconnect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i-refresh-ccw", [["class", "connection-banner__text__icon"]], [[2, "--loading", null]], null, null, i2.View_ɵhj_0, i2.RenderType_ɵhj)), i1.ɵdid(3, 49152, null, 0, i3.ɵhj, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading ? _co.translate._("RECONNECTING") : _co.translate._("RECONNECT")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); }); }
function View_ConnectionBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "connection-banner"]], [[2, "--show", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionBannerComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionBannerComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.connection; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.connection && !_co.socketConnection); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.connection || (!_co.socketConnection && _co.isDeviceMounted)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ConnectionBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.connection || (!_co.socketConnection && _co.isDeviceMounted)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConnectionBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-connection-banner", [], null, null, null, View_ConnectionBannerComponent_0, RenderType_ConnectionBannerComponent)), i1.ɵdid(1, 49152, null, 0, i5.ConnectionBannerComponent, [i6.TranslateService], null, null)], null, null); }
var ConnectionBannerComponentNgFactory = i1.ɵccf("shared-connection-banner", i5.ConnectionBannerComponent, View_ConnectionBannerComponent_Host_0, { connection: "connection", socketConnection: "socketConnection", isDeviceMounted: "isDeviceMounted", loading: "loading" }, { reconnectSocket: "reconnectSocket" }, []);
export { ConnectionBannerComponentNgFactory as ConnectionBannerComponentNgFactory };
