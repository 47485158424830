/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Action } from './action';
import { AccountCouponV2I18nFields } from './account-coupon-v2-i18n-fields';
import { CustomProperty } from './custom-property';
import { Activation } from './activation';
import { Image } from './image';
import { ProgramUnit } from './program-unit';
import { ExternalCode } from './external-code';


export interface AccountCouponV2 { 
    /**
     * unique identifier
     */
    accountCouponId?: string;
    /**
     * account identifier
     */
    accountId?: string;
    activation?: Activation;
    /**
     * coupon identifier
     */
    couponId?: string;
    /**
     * neccessary point amount for redemption
     */
    couponValue?: number;
    /**
     * Array of custom properties
     */
    customProperties?: Array<CustomProperty>;
    externalCode?: ExternalCode;
    externalReference?: string;
    i18nFields?: { [key: string]: AccountCouponV2I18nFields; };
    /**
     * Array of images including tags and urls
     */
    images?: Array<Image>;
    /**
     * maximal redeem count per account
     */
    maxRedeemCount?: number;
    /**
     * maximal redeem count per coupon
     */
    maxRedeemCountGlobal?: number;
    /**
     * Array of programUnits where the coupon is redeemable
     */
    pointsOfRedemption?: Array<ProgramUnit>;
    /**
     * Array of actions that will be executed at redemption
     */
    redeemActions?: Array<Action>;
    /**
     * current redeem count per accountcoupon
     */
    redeemCount?: number;
    /**
     * current redeem count per coupon
     */
    redeemCountGlobal?: number;
    /**
     * name of the necessary statelevel
     */
    stateLevel?: string;
    usageType?: AccountCouponV2.UsageTypeEnum;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    validFrom?: string;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    validTo?: string;
}
export namespace AccountCouponV2 {
    export type UsageTypeEnum = 'REWARD' | 'DISCOUNT' | 'MULTIPLIER' | 'EXTRAPOINT' | 'CAMPAIGN' | 'INCENTIVE';
    export const UsageTypeEnum = {
        REWARD: 'REWARD' as UsageTypeEnum,
        DISCOUNT: 'DISCOUNT' as UsageTypeEnum,
        MULTIPLIER: 'MULTIPLIER' as UsageTypeEnum,
        EXTRAPOINT: 'EXTRAPOINT' as UsageTypeEnum,
        CAMPAIGN: 'CAMPAIGN' as UsageTypeEnum,
        INCENTIVE: 'INCENTIVE' as UsageTypeEnum
    };
}


