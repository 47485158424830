/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "../../../../shared/lib/v1/components/connection-banner/connection-banner.component.ngfactory";
import * as i4 from "../../../../shared/lib/v1/components/connection-banner/connection-banner.component";
import * as i5 from "../../../../shared/lib/common/services/translate/translate.service";
import * as i6 from "../../../../shared/lib/v1/components/header/header.component.ngfactory";
import * as i7 from "../../../../shared/lib/v1/components/header/header.component";
import * as i8 from "../../../../shared/lib/common/services/routing/routing.service";
import * as i9 from "../../../../shared/lib/common/services/config/config.service";
import * as i10 from "@angular/router";
import * as i11 from "@angular/common";
import * as i12 from "./app.component";
import * as i13 from "../../../../shared/lib/common/services/inactivity/inactivity.service";
import * as i14 from "../../../../shared/lib/common/services/user/user.service";
import * as i15 from "../../../../shared/lib/common/services/pos-connection/pos-connection.service";
import * as i16 from "../../../../shared/lib/common/services/utils/utils.service";
import * as i17 from "../../../../shared/lib/common/services/sentry/sentry.service";
import * as i18 from "../../../../shared/lib/v2/services/device-mounting/device-mounting.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "ion-app", [], null, null, null, i1.View_IonApp_0, i1.RenderType_IonApp)), i0.ɵdid(1, 49152, null, 0, i2.IonApp, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "shared-connection-banner", [], null, [[null, "reconnectSocket"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reconnectSocket" === en)) {
        var pd_0 = (_co.connectToSocket(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ConnectionBannerComponent_0, i3.RenderType_ConnectionBannerComponent)), i0.ɵdid(3, 49152, null, 0, i4.ConnectionBannerComponent, [i5.TranslateService], { connection: [0, "connection"], socketConnection: [1, "socketConnection"], isDeviceMounted: [2, "isDeviceMounted"], loading: [3, "loading"] }, { reconnectSocket: "reconnectSocket" }), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "shared-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i0.ɵdid(5, 245760, null, 0, i7.HeaderComponent, [i8.RoutingService, i9.ConfigService], null, null), (_l()(), i0.ɵeld(6, 16777216, null, 0, 1, "ion-router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i2.IonRouterOutlet, [i10.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], [8, null], i2.Config, i2.NavController, i11.Location, i0.ElementRef, i10.Router, i0.NgZone, i10.ActivatedRoute, [3, i2.IonRouterOutlet]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.connection; var currVal_1 = _co.socketConnection; var currVal_2 = _co.isDeviceMounted; var currVal_3 = _co.loading; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i12.AppComponent, [i13.InactivityService, i2.ModalController, i14.UserService, i8.RoutingService, i9.ConfigService, i15.PosConnectionService, i16.UtilsService, i17.SentryService, i5.TranslateService, i18.DeviceMountingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
