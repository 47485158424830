/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InteractionPointData { 
    id?: string;
    name: string;
    type: InteractionPointData.TypeEnum;
}
export namespace InteractionPointData {
    export type TypeEnum = 'APP' | 'CASHIER' | 'ONLINE';
    export const TypeEnum = {
        APP: 'APP' as TypeEnum,
        CASHIER: 'CASHIER' as TypeEnum,
        ONLINE: 'ONLINE' as TypeEnum
    };
}


