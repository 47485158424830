import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { RegisterPage as SharedRegisterPage } from "shared/lib/common/pages";
import { AdminService, ProgramService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import { ProgramConfig, UserProperty } from "../../apis/programs";
import { PasswordMatch } from "shared/lib/common/validators";
import { ACCEPTED_COUNTRIES } from "shared/lib/common/constants";
import { RegistrationData, UserData } from "../../apis/users";
import { Optin } from "../../apis/accounts";
import { IRegistrationCode } from "shared/lib/common/interfaces";

export interface IRegisterModalPageInputs {
  registrationType: IRegistrationCode;
  keyCode: string;
  userReferenceCode: string;
  partnerId: string;
}

@Component({
  selector: "shared-v2-register",
  templateUrl: "./register.page.html",
  styleUrls: ["./register.page.scss"],
})
export class RegisterPage extends SharedRegisterPage implements OnInit {
  public membershipOptins: string[];
  public userProperties: UserProperty[];

  @Input() public partnerId: string;

  constructor(
    public _t: TranslateService,
    private _modal: ModalController,
    private _fb: FormBuilder,
    private _admin: AdminService,
    private _toast: ToastService,
    private _config: ConfigService,
    private _user: UserService,
    private _program: ProgramService,
  ) {
    super(_t, _modal, _fb, _admin, _toast, _config, _user);
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.countries = ACCEPTED_COUNTRIES;
    this.client = this._config.getMountingConfig().legalName;
  }

  public async ionViewDidEnter(): Promise<void> {
    this.isLoading = true;
    const config: ProgramConfig = await this._program.getProgramConfig(this._config.getOrganization()).toPromise();

    this.updateForm(config);
    this.isLoading = false;
  }

  public async register(): Promise<void> {
    this.isLoading = true;
    if (this.form.valid) {
      const params = this.userProperties.reduce(
        (acum, userProperty) => {
          if (userProperty.name === "birthDate") {
            const birthdate = this.form.get(userProperty.name).value;
            const date = !!birthdate ? new Date(birthdate) : null;
            acum[userProperty.name] = date && date.toISOString();
          } else {
            acum[userProperty.name] = this.form.get(userProperty.name).value || undefined;
          }
          return acum;
        },
        {} as UserData,
      );
      const resp = await this._admin.register(this.addRegistrationData(params));

      if (resp.ok === true) {
        this.form.reset();
        this.dismiss("SUCCESS");
      } else {
        this._toast.show(resp.error.message, "error");
      }
    }
    this.isLoading = false;
  }

  private addRegistrationData(userData: UserData): UserData {
    const registrationData: RegistrationData[] = [{ key: "email", value: this.form.get("emailAddress").value }];
    if (this.partnerId) registrationData.push({ key: "partnerId", value: this.partnerId });
    if (this.keyCode) registrationData.push({ key: "accountId", value: this.keyCode });

    const optins: Optin[] = (this.membershipOptins || []).map(optin => {
      return { type: optin, flag: this.form.get(optin).value || false };
    });

    registrationData.push({
      key: "optins",
      value: JSON.stringify(optins),
    });

    return { ...userData, registrationData };
  }

  private updateForm(programConfig: ProgramConfig): void {
    if (programConfig) {
      this.membershipOptins = programConfig.membershipOptins;
      this.userProperties = programConfig.userProperties;

      (this.membershipOptins || []).forEach(optin => {
        this.form.addControl(optin, new FormControl(false));
      });

      (this.userProperties || []).forEach(userProperty => {
        switch (userProperty.name) {
          case "emailAddress":
            this.form.addControl(
              userProperty.name,
              new FormControl("", userProperty.mandatory ? [Validators.required, Validators.email] : [Validators.email]),
            );
            break;
          case "password":
          case "passwordRepeat":
            this.form.addControl(
              userProperty.name,
              new FormControl("", userProperty.mandatory ? [Validators.required, Validators.minLength(8)] : [Validators.minLength(8)]),
            );
            break;
          default:
            this.form.addControl(userProperty.name, new FormControl("", userProperty.mandatory ? [Validators.required] : []));
            break;
        }
      });
      if (this.form.get("password") && this.form.get("passwordRepeat")) {
        this.form.setValidators(PasswordMatch("password", "passwordRepeat"));
      }
      this.form.updateValueAndValidity();
    }
  }
}
