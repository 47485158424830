import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedRoutes } from "shared/lib/common/enums";
import { IsLoginDoneGuard, IsRegisteredAdminGuard, IsUserScannedGuard, IsMountingDoneGuard } from "shared/lib/common/guards";
import { IsPinAddedGuard } from "shared/lib/v2/guards";
import * as CPages from "shared/lib/common/pages";
import * as V2pages from "shared/lib/v2/pages";

const routes: Routes = [
  {
    path: SharedRoutes.login,
    component: V2pages.LoginPage,
    canActivate: [IsLoginDoneGuard],
  },
  {
    path: SharedRoutes.history,
    component: CPages.HistoryPage,
    canActivate: [IsRegisteredAdminGuard, IsUserScannedGuard],
  },
  {
    path: SharedRoutes.admin,
    component: V2pages.AdminPage,
    canActivate: [IsRegisteredAdminGuard, IsPinAddedGuard],
  },
  {
    path: SharedRoutes.screenSaver,
    component: V2pages.ScreenSaverPage,
    canActivate: [IsRegisteredAdminGuard],
  },
  {
    path: SharedRoutes.qrScanner,
    component: V2pages.QrScannerPage,
    canActivate: [IsRegisteredAdminGuard],
  },
  {
    path: SharedRoutes.burn,
    component: V2pages.BurnPage,
    canActivate: [IsRegisteredAdminGuard, IsUserScannedGuard],
  },
  {
    path: SharedRoutes.mounting,
    component: V2pages.MountingSettingsPage,
    canActivate: [IsMountingDoneGuard],
  },
  {
    path: SharedRoutes.deviceTest,
    component: CPages.DeviceTestPage,
    canActivate: [],
  },
  {
    path: SharedRoutes.revenue,
    component: V2pages.RevenuePage,
    canActivate: [IsRegisteredAdminGuard, IsUserScannedGuard],
  },
  { path: "**", redirectTo: SharedRoutes.screenSaver },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: ":program",
          children: routes,
        },
        {
          path: "**",
          component: CPages.NotFoundPage,
        },
      ],
      { onSameUrlNavigation: "reload" },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
