import { org } from "./environment.constant";
import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  production: true,
  BUILD_ENV: "qa",
  RELEASE: "0.17.4#51e65cd",
  API_V0: `https://${org}test.point4more.com`,
  API_V1: "https://dev.service.point4more.com",
  API_V2: "https://staging.convercus.io",
  API_MOUNTING: "https://staging.convercus.io",
  API_SOCKET: "https://socket.staging.convercus.io",
  SELFSERVICE: `https://staging.admin.convercus.io/${org}`,
};
