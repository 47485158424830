var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PrintService, RoutingService, ToastService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { BurnPage as SharedBurnPage } from "shared/lib/common/pages";
import { AdminService, AccountCouponService, RatingConfigService } from "shared/lib/v2/services";
import { VoucherType } from "shared/lib/common/interfaces";
import { SharedRoutes } from "shared/lib/common/enums";
import { RegisterPage } from "../register/register.page";
import { ConfigService } from "shared/lib/common/services";
import { DecimalPipe } from "@angular/common";
import { CouponCardPage } from "shared/lib/v2/pages/coupon-card/coupon-card.page";
export class BurnPage extends SharedBurnPage {
    constructor(_translate, _user, _utils, _routing, _modal, _toast, _config, _print, _admin, _accountCouponService, _decimal, _ratingConfig) {
        super(_translate, _user, _utils, _modal, _toast, _config, _print, _admin, _routing);
        this._translate = _translate;
        this._user = _user;
        this._utils = _utils;
        this._routing = _routing;
        this._modal = _modal;
        this._toast = _toast;
        this._config = _config;
        this._print = _print;
        this._admin = _admin;
        this._accountCouponService = _accountCouponService;
        this._decimal = _decimal;
        this._ratingConfig = _ratingConfig;
        this.routes = SharedRoutes;
    }
    ngOnInit() {
        super.ngOnInit();
        this.partnerId = this._config.getMountingConfig().partner.id;
    }
    ionViewDidEnter() {
        const _super = Object.create(null, {
            ionViewDidEnter: { get: () => super.ionViewDidEnter }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.ionViewDidEnter.call(this);
            const ratingConfig = yield this._ratingConfig.getRatingConfigForProgram(this._config.getOrganization()).toPromise();
            this.currency = ratingConfig.currency;
        });
    }
    changeActivationStatus(coupon) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._accountCouponService
                .changeActivationStatus(coupon.accountVoucherId, { activated: !coupon.activation.activated })
                .toPromise();
            const c = yield this._accountCouponService.getAccountCoupon(coupon.accountVoucherId).toPromise();
            this._user.updateUserCoupon(c);
            this.tickets = this.sortTickets(this.user.getVouchers());
            this.ticketSelected = this.tickets.find(t => t.voucherId === coupon.voucherId);
        });
    }
    getPoints(coupon) {
        let points = "";
        if (coupon) {
            points = this._decimal.transform(coupon.points, "", this._translate.getSessionLanguage());
            if (coupon.voucherType === VoucherType.coupon) {
                if (coupon.points) {
                    switch (coupon.couponTypeCode) {
                        case "EXTRAPOINT":
                            return `+${points}`;
                        case "MULTIPLIER":
                            return `${points}x`;
                        case "DISCOUNT":
                            if (coupon.redeemActions[0].actionType === "NOMINALDISCOUNTACTION")
                                return `${points}${this.currency}`;
                            else
                                return `${points}%`;
                    }
                }
                else
                    return this._translate._("FREE");
            }
        }
        return points;
    }
    getText(coupon) {
        if (coupon) {
            switch (coupon.voucherType) {
                case VoucherType.coupon:
                    if (coupon.points) {
                        switch (coupon.couponTypeCode) {
                            case "EXTRAPOINT":
                                return this._translate._("EXTRAPOINT");
                            case "MULTIPLIER":
                                return this._translate._("MULTIPLIER");
                            case "DISCOUNT":
                                return this._translate._("DISCOUNT");
                            default:
                                return this._translate._("EARN");
                        }
                    }
                    else
                        return "";
                case VoucherType.reward:
                    return this._translate._("POINTS");
            }
        }
    }
    selectTicket(ticket) {
        return __awaiter(this, void 0, void 0, function* () {
            this.ticketSelected = ticket;
            if (this.mobileVersion) {
                const componentProps = {
                    ticketSelected: this.ticketSelected,
                    showRegisterButton: this.showRegisterButton,
                    registerMandatory: this.needToShowRegisterModal(this.ticketSelected),
                };
                const couponModal = yield this._modal.create({
                    component: CouponCardPage,
                    cssClass: "modal",
                    componentProps,
                });
                yield couponModal.present();
                const { data } = (yield couponModal.onDidDismiss());
                if (data) {
                    if (data.event === "activation")
                        this.changeActivationStatus(data.voucher);
                    if (data.event === "redeem")
                        this.askToRedeem(data.voucher);
                }
            }
        });
    }
    showRegisterModal(keyCode, userReferenceCode, registrationType) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                registrationType,
                keyCode,
                userReferenceCode,
            };
            const registerModal = yield this._modal.create({
                component: RegisterPage,
                cssClass: "modal modal--full-screen",
                componentProps,
            });
            yield registerModal.present();
            return (yield registerModal.onDidDismiss());
        });
    }
}
