import { Injectable, Inject, Optional } from "@angular/core";
import { RatingConfigService as ConvercusRatingConfigService, Configuration, BASE_PATH } from "shared/lib/v2/apis/programs";
import { HttpClient } from "@angular/common/http";
import { INTERACTION_ID } from "shared/lib/common/constants";
import { Observable } from "rxjs";
import { EnvironmentService } from "shared/lib/common/services";

@Injectable({ providedIn: "root" })
export class RatingConfigService extends ConvercusRatingConfigService {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
  }

  public getRatingConfigForProgram(programId: string, interactionId: string = INTERACTION_ID): Observable<any> {
    return super.getRatingConfigForProgramUsingGET(interactionId, programId);
  }
}
