import { Configuration } from "shared/lib/v2/apis/programs";
import { HttpClient } from "@angular/common/http";
import { INTERACTION_ID } from "shared/lib/common/constants";
import { ProductCatalogService as ConvercusProductCatalogService } from "shared/lib/v2/apis/programs/api/product-catalog.service";
import { EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../apis/programs/variables";
import * as i3 from "../../apis/programs/configuration";
import * as i4 from "../../../common/services/environment/environment.service";
export class ProductCatalogService extends ConvercusProductCatalogService {
    constructor(httpClient, basePath, configuration, environmentService) {
        super(httpClient, environmentService.getVariable("API_V2"), configuration);
        this.httpClient = httpClient;
        this.environmentService = environmentService;
    }
    getGroupsOfPartner(programId, partnerId, interactionId = INTERACTION_ID, inherited = true) {
        return super.getGroupsOfPartnerUsingGET(interactionId, partnerId, programId, inherited);
    }
    getSubGroupsOfPartnerAndGroup(programId, partnerId, groupId, interactionId = INTERACTION_ID, inherited = true) {
        return super.getSubGroupsOfPartnerAndGroupUsingGET(groupId, interactionId, partnerId, programId, inherited);
    }
    getItemsOfPartner(programId, partnerId, interactionId = INTERACTION_ID, inherited = true) {
        return super.getItemsOfPartnerUsingGET(interactionId, partnerId, programId, inherited);
    }
}
ProductCatalogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductCatalogService_Factory() { return new ProductCatalogService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8), i0.ɵɵinject(i4.EnvironmentService)); }, token: ProductCatalogService, providedIn: "root" });
