/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revenue-item-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i5 from "angular-feather";
import * as i6 from "@angular/common";
import * as i7 from "./revenue-item-list.component";
import * as i8 from "../../../common/services/translate/translate.service";
var styles_RevenueItemListComponent = [i0.styles];
var RenderType_RevenueItemListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RevenueItemListComponent, data: {} });
export { RenderType_RevenueItemListComponent as RenderType_RevenueItemListComponent };
function View_RevenueItemListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "revenue-item-list__slide__list__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent.emit({ item: _co.getParentElement(_v.parent.context.$implicit), addToBasket: true }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ion-icon", [["class", "revenue-item-list__slide__list__item__img"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "p", [["class", "revenue-item-list__slide__list__item__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "revenue-item-list__slide__list__item__button"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "revenue-item-list__slide__list__separator"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.$implicit === "subGroup") ? _co.iconByLevel["group"] : _co.iconByLevel["subGroup"]); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = _co.getParentElement(_v.parent.context.$implicit)) == null) ? null : tmp_1_0.name); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.t._("BOOK"); _ck(_v, 5, 0, currVal_2); }); }
function View_RevenueItemListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-icon", [["class", "revenue-item-list__slide__list__item__img"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(1, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconByLevel[_v.parent.parent.context.$implicit]; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RevenueItemListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "revenue-item-list__slide__list__item__letter"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name.charAt(0); _ck(_v, 0, 0, currVal_0); }); }
function View_RevenueItemListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i-chevron-right", [["class", "revenue-item-list__slide__list__item__icon"]], null, null, null, i4.View_ɵbw_0, i4.RenderType_ɵbw)), i1.ɵdid(1, 49152, null, 0, i5.ɵbw, [], null, null)], null, null); }
function View_RevenueItemListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "revenue-item-list__slide__list__item__button"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("BOOK"); _ck(_v, 0, 0, currVal_0); }); }
function View_RevenueItemListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "revenue-item-list__slide__list__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent.emit({ item: _v.context.$implicit, addToBasket: (_v.parent.context.$implicit === "item") }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_4)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [["class", "revenue-item-list__slide__list__item__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_6)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_7)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit !== "item"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit === "item"); _ck(_v, 4, 0, currVal_1); var currVal_3 = (_v.parent.context.$implicit !== "item"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_v.parent.context.$implicit === "item"); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (_v.context.$implicit.name || ""); _ck(_v, 5, 0, currVal_2); }); }
function View_RevenueItemListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("EMPTY_GROUPS"); _ck(_v, 0, 0, currVal_0); }); }
function View_RevenueItemListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("EMPTY_SUBGROUPS"); _ck(_v, 0, 0, currVal_0); }); }
function View_RevenueItemListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("EMPTY_ITEMS"); _ck(_v, 0, 0, currVal_0); }); }
function View_RevenueItemListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "revenue-item-list__slide__list__empty"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_9)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_10)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueItemListComponent_11)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit === "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit === "subGroup"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit === "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_RevenueItemListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-slide", [["class", "revenue-item-list__slide"]], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(1, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "ion-list", [["class", "revenue-item-list__slide__list"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(3, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RevenueItemListComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RevenueItemListComponent_3)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RevenueItemListComponent_8)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.getParentElement(_v.context.$implicit); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.getItems(_v.context.$implicit); var currVal_2 = _co.trackId; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = !_co.getItems(_v.context.$implicit).length; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_RevenueItemListComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { slider: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ion-slides", [["class", "revenue-item-list"], ["pager", "false"]], null, null, null, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(2, 49152, [[1, 4], ["slider", 4]], 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { pager: [0, "pager"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_RevenueItemListComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(5, 3)], function (_ck, _v) { var currVal_0 = "false"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, "group", "subGroup", "item"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RevenueItemListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-revenue-item-list", [], null, null, null, View_RevenueItemListComponent_0, RenderType_RevenueItemListComponent)), i1.ɵdid(1, 638976, null, 0, i7.RevenueItemListComponent, [i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevenueItemListComponentNgFactory = i1.ɵccf("shared-v2-revenue-item-list", i7.RevenueItemListComponent, View_RevenueItemListComponent_Host_0, { groupId: "groupId", subGroupId: "subGroupId", subGroups: "subGroups", groups: "groups", items: "items", level: "level" }, { clickEvent: "clickEvent" }, []);
export { RevenueItemListComponentNgFactory as RevenueItemListComponentNgFactory };
