import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, of, throwError } from "rxjs";
import { catchError, switchMap, retry } from "rxjs/operators";
import { UtilsService, P4mService, ConfigService, EnvironmentService, SentryService } from "shared/lib/common/services";
import { LOCAL_STORAGE, LOGS } from "shared/lib/common/enums";
import { Severity } from "@sentry/browser";

export interface IError {
  error?: string;
  errorType?: string;
  message: string;
}

@Injectable({ providedIn: "root" })
export class InterceptorService implements HttpInterceptor {
  constructor(
    private utils: UtilsService,
    private p4m: P4mService,
    private config: ConfigService,
    private environmentService: EnvironmentService,
    private sentry: SentryService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(`${this.environmentService.getVariable("API_MOUNTING")}/api/device-management/pmm`)) {
      req = req.clone({ withCredentials: true });
    }
    const authorization = this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
    if (authorization) req = req.clone({ setHeaders: { authorization } });

    return next.handle(req).pipe(
      retry(2),
      catchError(error => {
        if (error && error.error) {
          try {
            const err = this.parseError(error.error, error.status);
            return this.handleError(req, next, err);
          } catch (error) {}
        }
        return throwError(error);
      }),
    );
  }

  private reLogin(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    const email = this.p4m.getUser();
    const password = this.p4m.getPassword();
    const login = this.p4m.login({ password, email, organization: this.config.getOrganization() });
    if (login && email && password) {
      return from(login).pipe(
        switchMap(response => {
          if (response.ok === true) return next.handle(req.clone({ setHeaders: { authorization: response.response } }));
          else return of(response.error);
        }),
        catchError(err => {
          let config = {};
          const mountingConfig = this.config.getMountingConfig();
          if (mountingConfig) config = mountingConfig;
          this.sentry.captureException(LOGS.LOGOUT, {
            extra: {
              ...config,
              unmountDatetime: new Date().toISOString(),
            },
            tags: {
              function: "reLogin 1",
              file: "shared/lib/v2/services/interceptor/interceptor.service.ts",
              interactionId: config ? config["appInteractionId"] : null,
            },
            level: Severity.Log,
          });
          localStorage.clear();
          return of(err);
        }),
      );
    } else {
      let config = {};
      const mountingConfig = this.config.getMountingConfig();
      if (mountingConfig) config = mountingConfig;
      this.sentry.captureException(LOGS.LOGOUT, {
        extra: {
          ...config,
          unmountDatetime: new Date().toISOString(),
        },
        tags: {
          function: "reLogin 2",
          file: "shared/lib/v2/services/interceptor/interceptor.service.ts",
          interactionId: config ? config["appInteractionId"] : null,
        },
        level: Severity.Log,
      });
      localStorage.clear();
      return of(error);
    }
  }

  private handleError(
    req: HttpRequest<any>,
    next: HttpHandler,
    error: { status: number; error?: string; errorType?: string; message: string },
  ): Observable<HttpEvent<any>> {
    if (error.errorType === "Unauthorized" || error.status === 401 || error.status === 0) return this.reLogin(req, next, error);
    return throwError(error);
  }

  private parseError(error: any, status: number): { status: number; error?: string; errorType?: string; message: string } {
    try {
      const err: IError = JSON.parse(error);
      return { ...err, status };
    } catch (err) {
      return { ...error, status };
    }
  }
}
