var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { RoutingService, TranslateService, UserService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
export class HistoryPage {
    constructor(translate, config, user, routing) {
        this.translate = translate;
        this.config = config;
        this.user = user;
        this.routing = routing;
    }
    ngOnInit() {
        this.loading = true;
        this.totalEarn = 0;
        this.totalRedeem = 0;
        this.transactions = [];
        this.firstAction = null;
    }
    ionViewDidEnter() {
        if (this.user.getUser())
            this.firstAction = this.user.getUser().firstAction;
        if (this.user.getHistory())
            this.transactions = this.user.getHistory().transactions;
        if (this.user.getAccountCouponsRedemptions())
            this.accountCouponsRedemptions = this.user.getAccountCouponsRedemptions();
        this.getTotals(this.transactions);
        this.loading = false;
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            this.routing.goForward([SharedRoutes.burn]);
        });
    }
    getTotals(transactions = []) {
        transactions.forEach(t => {
            if (t.points) {
                if (t.points >= 0) {
                    this.totalEarn += t.points;
                }
                else {
                    this.totalRedeem += t.points;
                }
            }
        });
    }
}
