import { from, of, throwError } from "rxjs";
import { catchError, switchMap, retry } from "rxjs/operators";
import { UtilsService, P4mService, ConfigService, EnvironmentService, SentryService } from "shared/lib/common/services";
import { LOCAL_STORAGE, LOGS } from "shared/lib/common/enums";
import { Severity } from "@sentry/browser";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/utils/utils.service";
import * as i2 from "../../../common/services/p4m/p4m.service";
import * as i3 from "../../../common/services/config/config.service";
import * as i4 from "../../../common/services/environment/environment.service";
import * as i5 from "../../../common/services/sentry/sentry.service";
export class InterceptorService {
    constructor(utils, p4m, config, environmentService, sentry) {
        this.utils = utils;
        this.p4m = p4m;
        this.config = config;
        this.environmentService = environmentService;
        this.sentry = sentry;
    }
    intercept(req, next) {
        if (req.url.includes(`${this.environmentService.getVariable("API_MOUNTING")}/api/device-management/pmm`)) {
            req = req.clone({ withCredentials: true });
        }
        const authorization = this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
        if (authorization)
            req = req.clone({ setHeaders: { authorization } });
        return next.handle(req).pipe(retry(2), catchError(error => {
            if (error && error.error) {
                try {
                    const err = this.parseError(error.error, error.status);
                    return this.handleError(req, next, err);
                }
                catch (error) { }
            }
            return throwError(error);
        }));
    }
    reLogin(req, next, error) {
        const email = this.p4m.getUser();
        const password = this.p4m.getPassword();
        const login = this.p4m.login({ password, email, organization: this.config.getOrganization() });
        if (login && email && password) {
            return from(login).pipe(switchMap(response => {
                if (response.ok === true)
                    return next.handle(req.clone({ setHeaders: { authorization: response.response } }));
                else
                    return of(response.error);
            }), catchError(err => {
                let config = {};
                const mountingConfig = this.config.getMountingConfig();
                if (mountingConfig)
                    config = mountingConfig;
                this.sentry.captureException(LOGS.LOGOUT, {
                    extra: Object.assign({}, config, { unmountDatetime: new Date().toISOString() }),
                    tags: {
                        function: "reLogin 1",
                        file: "shared/lib/v2/services/interceptor/interceptor.service.ts",
                        interactionId: config ? config["appInteractionId"] : null,
                    },
                    level: Severity.Log,
                });
                localStorage.clear();
                return of(err);
            }));
        }
        else {
            let config = {};
            const mountingConfig = this.config.getMountingConfig();
            if (mountingConfig)
                config = mountingConfig;
            this.sentry.captureException(LOGS.LOGOUT, {
                extra: Object.assign({}, config, { unmountDatetime: new Date().toISOString() }),
                tags: {
                    function: "reLogin 2",
                    file: "shared/lib/v2/services/interceptor/interceptor.service.ts",
                    interactionId: config ? config["appInteractionId"] : null,
                },
                level: Severity.Log,
            });
            localStorage.clear();
            return of(error);
        }
    }
    handleError(req, next, error) {
        if (error.errorType === "Unauthorized" || error.status === 401 || error.status === 0)
            return this.reLogin(req, next, error);
        return throwError(error);
    }
    parseError(error, status) {
        try {
            const err = JSON.parse(error);
            return Object.assign({}, err, { status });
        }
        catch (err) {
            return Object.assign({}, error, { status });
        }
    }
}
InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.ɵɵinject(i1.UtilsService), i0.ɵɵinject(i2.P4mService), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.EnvironmentService), i0.ɵɵinject(i5.SentryService)); }, token: InterceptorService, providedIn: "root" });
