import { Component, OnInit } from "@angular/core";
import { RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { ScreenSaverPage as SharedScreenSaverPage } from "shared/lib/common/pages";
import { AdminService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import { SharedRoutes } from "shared/lib/common/enums";

@Component({
  selector: "shared-v2-screen-saver",
  templateUrl: "./screen-saver.page.html",
  styleUrls: ["./screen-saver.page.scss"],
})
export class ScreenSaverPage extends SharedScreenSaverPage implements OnInit {
  public noPicture: boolean;
  public routes: typeof SharedRoutes = SharedRoutes;

  constructor(
    public _user: UserService,
    public _translate: TranslateService,
    public _config: ConfigService,
    public _routing: RoutingService,
    private _admin: AdminService,
    private _utils: UtilsService,
  ) {
    super(_user, _translate, _config, _admin, _utils, _routing);
  }

  public ngOnInit(): void {
    this.images = [];
    this.initSlide();
  }

  public ionViewWillEnter(): void {
    this.noPicture = false;
  }

  public async ionViewDidEnter(): Promise<void> {
    await super.ionViewDidEnter();
    if (this.images.length === 0) this.noPicture = true;
  }

  protected async backgroundSlide(): Promise<void> {
    if (this.images.length > 0) {
      for (let i = 0; i < this.images.length; i++) {
        this.actualImage = this.images[i].url;
        await this._utils.waiting(this.images[i].duration);
      }
    } else await this._utils.waiting(500);
    this.backgroundSlide();
  }
}
