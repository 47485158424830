import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppComponent as SharedAppComponent } from "shared/lib/v2/components";
import {
  InactivityService,
  PosConnectionService,
  RoutingService,
  SentryService,
  TranslateService,
  UserService,
  UtilsService,
} from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import * as manifestJSON from "../../manifest.json";
import { Manifest } from "shared/lib/common/interfaces";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent extends SharedAppComponent implements OnInit {
  constructor(
    protected readonly _inactivity: InactivityService,
    protected readonly _modalCtrl: ModalController,
    protected readonly _user: UserService,
    protected readonly _routing: RoutingService,
    protected readonly _config: ConfigService,
    protected readonly _posConnectionService: PosConnectionService,
    protected readonly _utils: UtilsService,
    protected readonly _sentry: SentryService,
    protected readonly _translate: TranslateService,
    protected readonly _deviceMountingService: DeviceMountingService,
  ) {
    super(_inactivity, _modalCtrl, _user, _routing, _config, _posConnectionService, _utils, _sentry, _translate, _deviceMountingService);
  }

  public async ngOnInit(): Promise<void> {
    this.updateManifest(manifestJSON["default"]);
    super.ngOnInit();
  }

  private updateManifest(manifest: Manifest): void {
    manifest.short_name = this._config.getOrganization().toUpperCase();
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    const manifestLink = document.querySelector("#my-manifest-placeholder");
    const iconLink = document.querySelector("#my-icon-placeholder");
    if (manifestLink) manifestLink.setAttribute("href", manifestURL);
    if (iconLink) iconLink.setAttribute("href", this._config.logo);
  }
}
