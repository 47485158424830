import { Component } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "shared-not-found",
  templateUrl: "./not-found.page.html",
  styleUrls: ["./not-found.page.scss"],
})
export class NotFoundPage {
  constructor(public t: TranslateService) {}
}
