import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConfigService, EnvironmentService } from "shared/lib/common/services";
import { IResponse } from "shared/lib/common/interfaces";

export interface PublicData {
  partnerId?: string;
}

@Injectable({ providedIn: "root" })
export class PublicAccountService {
  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService, private config: ConfigService) {}

  public async getIdentifierPartner(identifierCode: string, program: string): Promise<IResponse<string>> {
    try {
      if (identifierCode === null || identifierCode === undefined) {
        throw new Error("Required parameter identifierCode was null or undefined when calling getIdentifierPartner.");
      }
      if (program === null || program === undefined) {
        throw new Error("Required parameter program was null or undefined when calling getIdentifierPartner.");
      }

      let headers = new HttpHeaders();
      headers = headers.set("program", String(program));
      headers = headers.set("Content-Type", "application/json");
      headers = headers.set("Accept", "*/*");
      headers = headers.set("interaction-id", this.config.getMountingConfig().workstationId);

      const response = await this.httpClient
        .get(`${this.environmentService.getVariable("API_V2")}/public/identifiers/${encodeURIComponent(String(identifierCode))}/partner`, {
          headers,
          responseType: "text",
        })
        .toPromise();

      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: { message: error.message } };
    }
  }
}
