/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RatingConfig { 
    billNoUniqueness?: boolean;
    burnCurrencyToPointsFactor?: number;
    currency?: string;
    earnCurrencyToPointsFactor?: number;
    id?: string;
    partnerId?: string;
    program?: string;
    pwpAllowed?: RatingConfig.PwpAllowedEnum;
    rounding?: RatingConfig.RoundingEnum;
}
export namespace RatingConfig {
    export type PwpAllowedEnum = 'ALLUSERS' | 'NO' | 'REGISTEREDUSERS';
    export const PwpAllowedEnum = {
        ALLUSERS: 'ALLUSERS' as PwpAllowedEnum,
        NO: 'NO' as PwpAllowedEnum,
        REGISTEREDUSERS: 'REGISTEREDUSERS' as PwpAllowedEnum
    };
    export type RoundingEnum = 'CEILING' | 'DOWN' | 'FLOOR' | 'HALF_DOWN' | 'HALF_EVEN' | 'HALF_UP' | 'UP';
    export const RoundingEnum = {
        CEILING: 'CEILING' as RoundingEnum,
        DOWN: 'DOWN' as RoundingEnum,
        FLOOR: 'FLOOR' as RoundingEnum,
        HALFDOWN: 'HALF_DOWN' as RoundingEnum,
        HALFEVEN: 'HALF_EVEN' as RoundingEnum,
        HALFUP: 'HALF_UP' as RoundingEnum,
        UP: 'UP' as RoundingEnum
    };
}


