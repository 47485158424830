var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { ScreenSaverPage as SharedScreenSaverPage } from "shared/lib/common/pages";
import { AdminService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import { SharedRoutes } from "shared/lib/common/enums";
export class ScreenSaverPage extends SharedScreenSaverPage {
    constructor(_user, _translate, _config, _routing, _admin, _utils) {
        super(_user, _translate, _config, _admin, _utils, _routing);
        this._user = _user;
        this._translate = _translate;
        this._config = _config;
        this._routing = _routing;
        this._admin = _admin;
        this._utils = _utils;
        this.routes = SharedRoutes;
    }
    ngOnInit() {
        this.images = [];
        this.initSlide();
    }
    ionViewWillEnter() {
        this.noPicture = false;
    }
    ionViewDidEnter() {
        const _super = Object.create(null, {
            ionViewDidEnter: { get: () => super.ionViewDidEnter }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.ionViewDidEnter.call(this);
            if (this.images.length === 0)
                this.noPicture = true;
        });
    }
    backgroundSlide() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.images.length > 0) {
                for (let i = 0; i < this.images.length; i++) {
                    this.actualImage = this.images[i].url;
                    yield this._utils.waiting(this.images[i].duration);
                }
            }
            else
                yield this._utils.waiting(500);
            this.backgroundSlide();
        });
    }
}
