import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { LOCAL_STORAGE, LOGS, SharedRoutes } from "shared/lib/common/enums";
import { IIdentifierType } from "shared/lib/common/interfaces";
import { IQrScannerPageParams } from "shared/lib/common/pages";
import {
  InactivityService,
  IPOSObservableOutput,
  IPosRevenue,
  PosConnectionService,
  RoutingService,
  SentryService,
  TranslateService,
  UserService,
  UtilsService,
} from "shared/lib/common/services";
import { ISuccessComponentParams, SuccessPage } from "shared/lib/common/pages";
import { ConfigService } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services";
import { Severity } from "@sentry/browser";

@Component({
  selector: "shared-v2-app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public connection: boolean;
  public socketConnection: boolean;
  public isDeviceMounted: boolean;
  public loading: boolean;

  constructor(
    private inactivity: InactivityService,
    private readonly modalCtrl: ModalController,
    private user: UserService,
    private routing: RoutingService,
    private config: ConfigService,
    private posConnectionService: PosConnectionService,
    private utils: UtilsService,
    private sentry: SentryService,
    private translate: TranslateService,
    private deviceMountingService: DeviceMountingService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isDeviceMounted = !!this.config.getMountingConfig().clientId;
    this.setLanguage();
    this.checkConnection();
    this.connectToSocket();
    this.inactivityWatch();
    this.addSentryTags();
  }

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public inactivityWatch(): void {
    this.inactivity.startWatching();
    this.inactivity.onTimeout().subscribe(async () => {
      try {
        await this.modalCtrl.dismiss();
      } catch (error) {}
      this.user.removeUser();
      await this.routing.goForward([SharedRoutes.screenSaver]);
    });
  }

  public checkConnection(): void {
    this.connection = navigator.onLine;
    window.addEventListener("offline", () => (this.connection = false));
    window.addEventListener("online", () => (this.connection = true));
  }

  public async connectToSocket(force: boolean = false): Promise<void> {
    this.loading = true;
    this.connection = true;
    this.socketConnection = true;
    if (force) this.posConnectionService.closeSocketConnection();
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.posConnectionService.getObservable().subscribe(this.manageSocket.bind(this));
    await this.posConnectionService.connectToSocket(this.config.getMountingConfig().deviceKey);
    setTimeout(() => (this.connection = navigator.onLine), 4000);
    this.loading = false;
  }

  protected async openSuccessModal(keyCode: string, points: number, idType: IIdentifierType): Promise<void> {
    const componentProps: ISuccessComponentParams = {
      idType,
      keyCode,
      points,
      title: points > 0 ? this.translate._("POINTS_ACUMULATED") : this.translate._("POINTS_SPENT"),
    };
    const successModal = await this.modalCtrl.create({
      component: SuccessPage,
      componentProps,
    });
    await successModal.present();
    await successModal.onDidDismiss();
  }

  private setLanguage(): void {
    if (this.deviceMountingService.profile && this.deviceMountingService.profile.language) {
      this.translate.setLanguage(this.deviceMountingService.profile.language);
      this.translate.setSessionLanguage();
    }
  }

  private async onSocketRevenue(data: IPosRevenue): Promise<void> {
    this.inactivity.resetTimer();
    const modal = await this.modalCtrl.getTop();
    if (modal) modal.dismiss();
    const queryParams: IQrScannerPageParams = {
      type: "earn",
      totalRevenue: data.totalRevenue,
      totalPoints: data.totalPoints,
      externalId: data.payload.billNo,
      transactionId: data.payload.transactionId,
    };
    if (data.keyCode) await this.openSuccessModal(data.keyCode, data.totalPoints, data.idType || "APPCODE");
    else {
      await this.routing.goForward([SharedRoutes.screenSaver]);
      await this.routing.goForward([SharedRoutes.qrScanner], { queryParams });
    }
  }

  private addSentryTags(): void {
    const mountingConfig = JSON.parse(this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG)));
    if (mountingConfig) this.sentry.addTag("device-id", String(mountingConfig.deviceId));
  }

  private manageSocket(message: IPOSObservableOutput): void {
    this.isDeviceMounted = !!this.config.getMountingConfig().clientId;
    switch (message.type) {
      case "CONNECT":
        this.socketConnection = true;
        break;
      case "DISCONNECT":
        this.socketConnection = false;
        break;
      case "REVENUE":
        this.onSocketRevenue(message.data);
        break;
      case "UNMOUNT":
        let config = {};
        const mountingConfig = this.config.getMountingConfig();
        if (mountingConfig) config = mountingConfig;
        this.sentry.captureException(LOGS.UNMOUNT, {
          extra: {
            ...config,
            messageType: message.type,
            messageData: message.data,
            unmountDatetime: new Date().toISOString(),
          },
          tags: {
            function: "manageSocket",
            file: "shared/lib/v2/components/app-component/app.component.ts",
            interactionId: config ? config["appInteractionId"] : null,
          },
          level: Severity.Log,
        });
        localStorage.clear();
        window.location.href = `${this.config.getOrganization()}/${SharedRoutes.login}`;
        break;
    }
  }
}
