import { Component, OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { IAccountCouponRedemption, IDate, ITransaction } from "shared/lib/common/interfaces";
import { RoutingService, TranslateService, UserService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-history",
  templateUrl: "./history.page.html",
  styleUrls: ["./history.page.scss"],
})
export class HistoryPage implements OnInit {
  public transactions: ITransaction[];
  public accountCouponsRedemptions: IAccountCouponRedemption[];
  public firstAction: IDate;
  public totalEarn: number;
  public totalRedeem: number;
  public loading: boolean;

  constructor(
    public translate: TranslateService,
    public config: ConfigService,
    private user: UserService,
    private routing: RoutingService,
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.totalEarn = 0;
    this.totalRedeem = 0;
    this.transactions = [];
    this.firstAction = null;
  }

  public ionViewDidEnter(): void {
    if (this.user.getUser()) this.firstAction = this.user.getUser().firstAction;
    if (this.user.getHistory()) this.transactions = this.user.getHistory().transactions;
    if (this.user.getAccountCouponsRedemptions()) this.accountCouponsRedemptions = this.user.getAccountCouponsRedemptions();
    this.getTotals(this.transactions);
    this.loading = false;
  }

  public async close(): Promise<void> {
    this.routing.goForward([SharedRoutes.burn]);
  }

  private getTotals(transactions: ITransaction[] = []): void {
    transactions.forEach(t => {
      if (t.points) {
        if (t.points >= 0) {
          this.totalEarn += t.points;
        } else {
          this.totalRedeem += t.points;
        }
      }
    });
  }
}
