var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from "@angular/common";
import { OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { PosConnectionService, RoutingService, ToastService, TranslateService } from "shared/lib/common/services";
import { DeviceMountingService, ProgramService } from "shared/lib/v2/services";
import { FormBuilder, Validators } from "@angular/forms";
import { ConfigService } from "shared/lib/common/services";
export class MountingSettingsPage {
    constructor(fb, program, routing, toast, location, config, posConnectionService, deviceMountingService, translate) {
        this.fb = fb;
        this.program = program;
        this.routing = routing;
        this.toast = toast;
        this.location = location;
        this.config = config;
        this.posConnectionService = posConnectionService;
        this.deviceMountingService = deviceMountingService;
        this.translate = translate;
    }
    ngOnInit() {
        this.form = this.fb.group({
            appInteractionId: ["", [Validators.required]],
            deviceInformation: [""],
        });
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield this.program.getInteractionPointsOfProgram(this.config.getOrganization(), undefined, true).toPromise();
                this.interactionPoints = resp.filter(i => i.type !== "CASHIER");
            }
            catch (error) {
                const e = error.error && error.error.message ? error.error.message : error.message || error;
                this.toast.show(this.translate._(e), "error");
            }
        });
    }
    back() {
        this.location.back();
    }
    mountDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const res = yield this.deviceMountingService.mount(this.form.get("appInteractionId").value.id || this.form.get("appInteractionId").value, this.form.get("deviceInformation").value);
            if (res.ok === true) {
                this.config.setMountingConfig(yield this.deviceMountingService.parseMountingConfigV1ToV2(res.response));
                this.posConnectionService.connectToSocket(res.response.deviceKey);
                this.translate.setLanguage(res.response.profile.language === "de" ? "de" : "en");
                try {
                    yield navigator.mediaDevices.getUserMedia({ video: true });
                }
                catch (error) {
                    this.toast.show("Camera access denied", "error");
                }
                this.routing.goForward([SharedRoutes.screenSaver]);
            }
            else {
                const e = res.error.error && res.error.error.message ? res.error.error.message : res.error.message || res.error;
                this.toast.show(this.translate._(e), "error");
            }
            this.loading = false;
        });
    }
    checkErrors(formControlName) {
        const control = this.form.get(formControlName);
        return control.invalid && control.touched;
    }
    onChangeSearch(value) {
        this.form.get("appInteractionId").patchValue(value);
    }
}
