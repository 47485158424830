import { Component, NgZone } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { IDeviceConfig, IResponse } from "shared/lib/common/interfaces";
import { SharedRoutes } from "shared/lib/common/enums";
import { IIdentifierType, IRegistrationCode, IUser } from "shared/lib/common/interfaces";
import { IRegisterModalPageInputs, IRegisterModalPageOutputs, RegisterPage } from "shared/lib/common/pages/register/register.page";
import { ISuccessComponentParams, SuccessPage } from "shared/lib/common/pages/success/success.page";
import {
  AdminService,
  AlertService,
  InactivityService,
  RoutingService,
  ToastService,
  TranslateService,
  UserService,
} from "shared/lib/common/services";
import { IBurnPageParams } from "shared/lib/common/pages/burn/burn.page";
import { IInformationModalPageInputs, InformationModalPage } from "shared/lib/common/pages/information-modal/information-modal.page";
import { Subscription } from "rxjs";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ConfigService } from "shared/lib/common/services";

interface IQRv2 {
  accountId: string;
  idType?: "CARDCODE" | "APPCODE";
  accountCouponId?: string;
  couponId?: string;
}

export type IValidateQr =
  | { type: "earn"; keyCode: string; idType?: IIdentifierType }
  | { type: "user"; keyCode: string; idType?: IIdentifierType }
  | { type: "coupon"; qrObject: IValidCoupon }
  | { type: "reward"; qrObject: IValidReward }
  | { type: "ck"; qrObject: IValidCoupon };

export interface IValidCoupon {
  keyCode: string;
  couponId?: string;
  couponKey?: string;
  idType?: IIdentifierType;
}

export interface IValidReward {
  keyCode: string;
  rewardId: string;
}

export const positionMap: { [key: string]: "top" | "left" | "right" } = {
  BC: "top",
  TR: "left",
  RC: "right",
};

export enum IAudioFile {
  "scanned",
  "socket",
}

interface IParamsBurn {
  type: "burn";
  keycode?: string;
}

interface IParamsEarn {
  type: "earn";
  manualPoints?: number;
  revenueRmb?: number;
  revenueRmt?: number;
  revenueRmc1?: number;
  revenueRmc2?: number;
  revenueRmc3?: number;
  totalRevenue?: number;
  totalPoints?: number;
  extendedRevenueFactsId?: number;
  externalId?: string;
  transactionId?: string;
  date?: string;
  acumulatePoints?: number;
}

type IQR =
  | { type: "uk" | "cc" | "pk"; keyCode: string; idType?: IIdentifierType }
  | { type: "ruk"; keyCode: string; hashedReward: string }
  | { type: "ck"; couponId: string }
  | { type: "cuk"; keyCode: string; hashedCoupon: string }
  | { type: "ckuk"; keyCode: string; couponId: string; idType?: IIdentifierType };

export type IQrScannerPageParams = IParamsEarn | IParamsBurn;

const COUPON_MASK = "AEHJPQSXBV";
const REWARD_MASK = "CDGKLPSVXZ";

@Component({
  selector: "shared-qr-scanner",
  templateUrl: "./qr-scanner.page.html",
  styleUrls: ["./qr-scanner.page.scss"],
})
export class QrScannerPage {
  private qrScanner$: Subscription;
  public cameraPosition: "top" | "left" | "right";
  public scannerEnabled: boolean;
  public loading: boolean;
  public showCamera: boolean;
  public cameraSelected: MediaDeviceInfo;
  public scannerResult: any;
  public backButton: boolean;
  public registrationRequired: boolean;
  public params: IQrScannerPageParams;
  public registrationType: IRegistrationCode;
  public audios: { [index in IAudioFile]: HTMLAudioElement };
  public timeout: NodeJS.Timeout;
  public isOnCapacitor: boolean;
  public alertTimeout: NodeJS.Timeout;
  public deviceConfig: IDeviceConfig;

  constructor(
    public zone: NgZone,
    public translate: TranslateService,
    private userService: UserService,
    private toast: ToastService,
    private config: ConfigService,
    private modal: ModalController,
    private admin: AdminService,
    private inactivity: InactivityService,
    private platform: Platform,
    private qrScanner: QRScanner,
    private routing: RoutingService,
    private readonly alert: AlertService,
  ) {}

  public async ionViewWillEnter(): Promise<void> {
    this.isOnCapacitor = this.platform.is("capacitor");
    this.params = this.routing.getUrlParams<IQrScannerPageParams>();
    if (!this.validParams()) {
      this.toast.show(this.translate._("INVALID_PARAMS"), "error");
      this.back();
      return;
    }
    this.loadAudioFiles();
    this.deviceConfig = this.config.getDeviceConfig();
    this.cameraPosition = positionMap[this.config.getMountingConfig().cameraPosition];
    if (this.params.type === "earn" && this.params.totalRevenue) {
      this.playSound(IAudioFile.socket);
    }

    if (
      (this.config && !this.config.manuallyEnableCamera) ||
      this.params.type === "burn" ||
      (this.params.type === "earn" && this.params.manualPoints)
    ) {
      this.enableAppQRScanner();
      this.showCamera = true;
    }
    this.loading = false;

    if (this.config && this.config.inactivityTime) {
      this.timeout = setTimeout(() => {
        this.back();
      }, this.config.inactivityTime * 1000);
    }
  }

  public async ionViewDidEnter(): Promise<void> {
    const resp = await this.admin.getRegistrationType();
    if (resp.ok === true) this.registrationType = resp.response;
    else this.toast.show(this.translate._(resp.error.message), "error");

    if (this.config && this.config.manuallyEnableCamera && this.params.type === "earn" && !this.params.manualPoints) {
      if (await this.manuallyEnableCamera()) {
        this.enableAppQRScanner();
        this.showCamera = true;
      } else {
        this.back();
      }
    }
  }

  public ionViewDidLeave(): void {
    clearTimeout(this.timeout);
    clearTimeout(this.alertTimeout);
    this.showCamera = false;
    this.disableAppQRScanner();
    this.alert.dismissAlert();
    if (this.isOnCapacitor) this.qrScanner.destroy();
  }

  public logs(name: string, event: any): void {}

  public back(): void {
    this.routing.goForward([SharedRoutes.screenSaver]);
  }

  public cameraFounds(devices: Array<MediaDeviceInfo>): void {
    const cameras = (devices || []).filter(({ kind }) => kind === "videoinput");
    cameras.forEach(camera => {
      if (camera.label.includes("front")) {
        this.cameraSelected = camera;
      }
    });
    if (!this.cameraSelected && cameras.length > 1 && this.getMobileOperatingSystem() === "iOS") {
      this.cameraSelected = cameras[1];
    } else if (!this.cameraSelected && cameras.length > 0) {
      this.cameraSelected = cameras[0];
    }
  }

  public async scannerCode(qrResult: string, error: boolean): Promise<void> {
    this.playSound(IAudioFile.scanned);
    this.loading = true;
    await this.disableAppQRScanner();
    let actionSuccess = false;
    this.inactivity.resetTimer();
    if (!error) {
      const resp = this.validateQr(qrResult);
      if (resp.ok === true) {
        switch (resp.response.type) {
          case "user":
            actionSuccess = await this.user(resp.response.keyCode, resp.response.idType);
            break;
          case "earn":
            actionSuccess = await this.earn(resp.response.keyCode, resp.response.idType);
            break;
          case "reward":
            actionSuccess = await this.burnReward(resp.response.qrObject);
            break;
          case "coupon":
            actionSuccess = await this.burnCoupon(resp.response.qrObject);
            break;
          case "ck":
            actionSuccess = this.openCoupon(resp.response.qrObject);
            break;
        }
      } else {
        await this.toast.show(this.translate._(resp.error.message), "error");
      }
    } else {
      await this.toast.show(this.translate._(qrResult), "error");
    }
    this.loading = false;
    if (!actionSuccess) await this.enableAppQRScanner();
  }

  public async openSuccessModal(keyCode: string, points: number, idType: IIdentifierType): Promise<void> {
    const componentProps: ISuccessComponentParams = {
      idType,
      keyCode,
      points,
      title: points > 0 ? this.translate._("POINTS_ACUMULATED") : this.translate._("POINTS_SPENT"),
    };
    const successModal = await this.modal.create({
      component: SuccessPage,
      componentProps,
    });
    await successModal.present();
    await successModal.onDidDismiss();
  }

  public async saveUser(user: IUser, idType?: IIdentifierType): Promise<IResponse<any>> {
    const [userHistoryResponse, accountCouponRedemptionsResponse] = await Promise.all([
      this.userService.getUserHistory(user.keyCode, idType),
      this.userService.readAccountCouponsRedemptions(user.keyCode, idType),
    ]);

    if (userHistoryResponse.ok === true && accountCouponRedemptionsResponse.ok === true) {
      this.userService.setUser(user);
      this.userService.setHistory(userHistoryResponse.response);
      this.userService.setAccountCouponsRedemptions(accountCouponRedemptionsResponse.response);
    }

    if (!userHistoryResponse.ok) return userHistoryResponse;

    if (!accountCouponRedemptionsResponse.ok) return accountCouponRedemptionsResponse;

    return userHistoryResponse;
  }

  public async extendedRevenuePointsManually(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (this.params.type === "earn") {
      const response = await this.admin.extendedRevenuePointsManually(
        keyCode,
        this.params.totalRevenue,
        this.params.extendedRevenueFactsId,
        idType,
        this.params.externalId,
      );
      if (response.ok === true) {
        this.openSuccessModal(keyCode, this.params.totalPoints, idType);
      } else {
        this.toast.show(this.translate._(response.error.message as any), "error");
      }
      return response.ok;
    }
  }

  protected async user(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (keyCode) {
      const [checkInResponse, details] = await Promise.all([
        this.userService.checkIn(keyCode),
        this.userService.getDetails(keyCode, idType),
      ]);
      if (checkInResponse.ok === false) this.toast.show(this.translate._(checkInResponse.error.message), "error");
      if (details.ok === true) {
        const queryParams: IBurnPageParams = { idType, keyCode };
        const saveUserResponse = await this.saveUser(details.response, idType);
        if (saveUserResponse.ok === true) {
          if (!details.response.isRegistered && this.registrationType !== "NO") {
            await this.showRegisterModal(keyCode, details.response);
          }
          this.routing.goForward([SharedRoutes.burn], { queryParams });
        } else this.toast.show(this.translate._(saveUserResponse.error.message), "error");
        return saveUserResponse.ok;
      } else this.toast.show(this.translate._(details.error.message), "error");
      return details.ok;
    }
    return false;
  }

  protected async earn(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (keyCode && this.params.type === "earn") {
      if (this.params.manualPoints) {
        return await this.revenuePoints(keyCode, idType);
      } else {
        return await this.extendedRevenuePointsManually(keyCode, idType);
      }
    }
    return false;
  }

  protected async showRegisterModal(keyCode: string, user: IUser): Promise<boolean> {
    const componentProps: IRegisterModalPageInputs = {
      registrationType: this.registrationType as any,
      keyCode,
      userReferenceCode: user.userReferenceCode,
    };
    const registerModal = await this.modal.create({
      component: RegisterPage,
      cssClass: "modal modal--full-screen",
      componentProps,
    });
    await registerModal.present();
    const { data } = (await registerModal.onDidDismiss()) as IRegisterModalPageOutputs;
    if (data === "SUCCESS") await this.showAttentionModal();
    return data === "SUCCESS";
  }

  protected async playSound(file: IAudioFile): Promise<void> {
    try {
      if (this.audios && this.audios[file]) await this.audios[file].play();
    } catch (error) {
      console.error(error);
    }
  }

  protected async enableAppQRScanner(): Promise<void> {
    this.scannerEnabled = true;
    try {
      if (this.isOnCapacitor) {
        const { authorized } = await this.qrScanner.prepare();
        if (authorized) {
          await this.qrScanner.useFrontCamera();
          await this.qrScanner.show();
          this.qrScanner$ = this.qrScanner.scan().subscribe(async (text: string) => {
            this.qrScanner$.unsubscribe();
            this.scannerCode(text, false);
            return await this.qrScanner.hide();
          });
        } else {
          const componentProps: IInformationModalPageInputs = {
            title: this.translate._("CAMERA_ACCESS_DENIED"),
            description: this.translate._("GRANT_CAMERA_INSTRUCTIONS"),
            buttonText: this.translate._("OK"),
          };
          const informationModal = await this.modal.create({
            component: InformationModalPage,
            cssClass: "modal",
            componentProps,
            backdropDismiss: false,
          });
          await informationModal.present();
          await informationModal.onDidDismiss();
        }
      }
    } catch (error) {}
  }

  protected async disableAppQRScanner(): Promise<void> {
    this.scannerEnabled = false;
    if (this.isOnCapacitor) await this.qrScanner.hide();
  }

  protected validateQr(qrResult: string): IResponse<IValidateQr> {
    const resp = this.getQrType(qrResult);
    if (resp.ok === true) {
      switch (resp.response.type) {
        case "uk":
        case "cc":
        case "pk":
          return this.params.type === "burn"
            ? { ok: true, response: { type: "user", keyCode: resp.response.keyCode, idType: resp.response.idType } }
            : { ok: true, response: { type: "earn", keyCode: resp.response.keyCode, idType: resp.response.idType } };
        case "ruk":
          return this.params.type === "burn"
            ? {
                ok: true,
                response: {
                  type: "reward",
                  qrObject: { keyCode: resp.response.keyCode, rewardId: this.unmask(resp.response.hashedReward, REWARD_MASK) },
                },
              }
            : { ok: false, error: { message: this.translate._("INVALID_QR") } };
        case "ck":
          return this.params.type === "burn"
            ? {
                ok: true,
                response: {
                  type: "ck",
                  qrObject: { keyCode: this.params.keycode, couponKey: resp.response.couponId },
                },
              }
            : { ok: false, error: { message: this.translate._("INVALID_QR") } };
        case "cuk":
          return this.params.type === "burn"
            ? {
                ok: true,
                response: {
                  type: "coupon",
                  qrObject: { keyCode: resp.response.keyCode, couponId: this.unmask(resp.response.hashedCoupon, COUPON_MASK) },
                },
              }
            : { ok: false, error: { message: this.translate._("INVALID_QR") } };
        case "ckuk":
          return this.params.type === "burn"
            ? {
                ok: true,
                response: {
                  type: "coupon",
                  qrObject: { keyCode: resp.response.keyCode, couponId: resp.response.couponId, idType: resp.response.idType },
                },
              }
            : { ok: false, error: { message: this.translate._("INVALID_QR") } };
      }
    } else {
      return resp;
    }
  }

  protected async burnReward({ keyCode, rewardId }: IValidReward): Promise<boolean> {
    const details = await this.userService.getDetails(keyCode);
    if (details.ok === true) {
      const saveUserResponse = await this.saveUser(details.response);
      if (saveUserResponse.ok === true) {
        const queryParams: IBurnPageParams = { keyCode, rewardId };
        this.routing.goForward([SharedRoutes.burn], { queryParams });
      } else {
        this.toast.show(this.translate._(saveUserResponse.error.message as any), "error");
      }
      return saveUserResponse.ok;
    } else {
      this.toast.show(this.translate._(details.error.message as any), "error");
      return details.ok;
    }
  }

  protected async burnCoupon({ idType, keyCode, couponId, couponKey }: IValidCoupon): Promise<boolean> {
    const details = await this.userService.getDetails(keyCode, idType);
    if (details.ok === true) {
      const saveUserResponse = await this.saveUser(details.response, idType);
      if (saveUserResponse.ok === true) {
        const queryParams: IBurnPageParams = { idType, keyCode, couponId, couponKey };
        this.routing.goForward([SharedRoutes.burn], { queryParams });
      } else {
        this.toast.show(this.translate._(saveUserResponse.error.message as any), "error");
      }
      return saveUserResponse.ok;
    } else {
      this.toast.show(this.translate._(details.error.message as any), "error");
      return details.ok;
    }
  }

  protected openCoupon({ idType, keyCode, couponId, couponKey }: IValidCoupon): boolean {
    const queryParams: IBurnPageParams = { idType, keyCode, couponId, couponKey };
    this.routing.goForward([SharedRoutes.burn], { queryParams });
    return true;
  }

  protected async showAttentionModal(): Promise<void> {
    const componentProps: IInformationModalPageInputs = {
      title: this.translate._("CONFIRM"),
      description: this.translate._("REGISTRATION_NOT_COMPLETE"),
      buttonText: this.translate._("UNDERSTOOD"),
    };
    const informationModal = await this.modal.create({
      component: InformationModalPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await informationModal.present();
    await informationModal.onDidDismiss();
  }

  private getQrType(qrResult: string): IResponse<IQR> {
    try {
      const qrObject = JSON.parse(qrResult) as IQRv2;
      if (qrObject.accountId) {
        if (qrObject.accountCouponId || qrObject.couponId) {
          return {
            ok: true,
            response: {
              type: "ckuk",
              keyCode: qrObject.accountId,
              couponId: qrObject.accountCouponId || qrObject.couponId,
              idType: qrObject.idType ? qrObject.idType : "ID",
            },
          };
        } else {
          return { ok: true, response: { type: "uk", keyCode: qrObject.accountId, idType: qrObject.idType ? qrObject.idType : "ID" } };
        }
      } else {
        return { ok: true, response: { type: "uk", keyCode: qrResult } };
      }
    } catch (error) {
      const array = qrResult.split("/");
      if (array.length > 4) {
        const type = array[array.length - 2];
        const value = array[array.length - 1];
        switch (type) {
          case "uk":
          case "cc":
          case "pk":
            return { ok: true, response: { type, keyCode: value } };
          case "ruk":
            const hashedReward = value.split("-");
            return { ok: true, response: { type, keyCode: hashedReward[0], hashedReward: hashedReward[1] } };
          case "ck":
            return { ok: true, response: { type, couponId: value } };
          case "cuk":
            const hashedCoupon = value.split("-");
            return { ok: true, response: { type, keyCode: hashedCoupon[0], hashedCoupon: hashedCoupon[1] } };
          case "ckuk":
            const coupon = value.split("-");
            return { ok: true, response: { type, keyCode: coupon[0], couponId: coupon[1] } };
          default:
            return { ok: false, error: { message: this.translate._("INVALID_QR") } };
        }
      } else if (array.length === 4 || array.length === 1) {
        return { ok: true, response: { type: "uk", keyCode: array[array.length - 1], idType: "ID" } };
      } else {
        return { ok: false, error: { message: this.translate._("INVALID_QR") } };
      }
    }
  }

  private unmask(ticketId: string, mask: string): string {
    let ticket = "";
    for (let i = 0; i < ticketId.length; i++) {
      const c = ticketId.substring(i, i + 1);
      const x = mask.indexOf(c);
      if (x < 0) {
        return null;
      }
      ticket += x;
    }
    return ticket;
  }

  private async revenuePoints(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (this.params.type === "earn") {
      const response = await this.userService.revenuePoints({
        deviceKey: this.config.getMountingConfig().deviceKey,
        keyCode,
        idType,
        revenueRmb: this.params.revenueRmb,
        revenueRmc1: this.params.revenueRmc1,
        revenueRmc2: this.params.revenueRmc2,
        revenueRmc3: this.params.revenueRmc3,
        revenueRmt: this.params.revenueRmt,
        totalPoints: this.params.manualPoints,
      });
      if (response.ok === true) {
        this.openSuccessModal(keyCode, this.params.manualPoints, idType);
      } else {
        this.toast.show(this.translate._(response.error.message as any), "error");
      }
      return response.ok;
    }
    return false;
  }

  private validParams(): boolean {
    switch (this.params.type) {
      case "burn":
        return true;
      case "earn":
        return (
          !!this.params.manualPoints ||
          (!!this.params.totalRevenue && !!this.params.date) ||
          (!!this.params.totalRevenue && !!this.params.totalPoints && (!!this.params.extendedRevenueFactsId || !!this.params.externalId))
        );
      default:
        return false;
    }
  }

  private getMobileOperatingSystem(): "Windows Phone" | "Android" | "iOS" | "unknown" {
    if (/windows phone/i.test(navigator.userAgent || navigator.vendor)) {
      return "Windows Phone";
    }
    if (/android/i.test(navigator.userAgent || navigator.vendor)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor)) {
      return "iOS";
    }
    return "unknown";
  }

  private loadAudioFiles(): void {
    try {
      this.audios = {
        [IAudioFile.scanned]: new Audio("assets/scanned.mp3"),
        [IAudioFile.socket]: new Audio("assets/socket.mp3"),
      };
      this.audios[IAudioFile.scanned].load();
      this.audios[IAudioFile.socket].load();
    } catch (error) {
      console.error(error);
    }
  }

  private manuallyEnableCamera(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.alertTimeout = setTimeout(() => {
        this.alert
          .dismissAlert()
          .then(() => {
            resolve(false);
          })
          .catch(error => {
            reject(error);
          });
      }, this.config && this.config.inactivityTime * 1000);

      this.alert.showAlert({
        cssClass: "alert-enable-camera",
        message: this.translate._("enableCamera"),
        buttons: [
          {
            text: this.translate._("CONFIRM"),
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
    });
  }
}
