/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./confirmation.page";
import * as i6 from "../../services/translate/translate.service";
var styles_ConfirmationPage = [i0.styles];
var RenderType_ConfirmationPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationPage, data: {} });
export { RenderType_ConfirmationPage as RenderType_ConfirmationPage };
function View_ConfirmationPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "confirm--points__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.voucher.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("POINTS_TO_EXCHANGE"); _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationPage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params.voucher.redeemActions[0].actionType === "NOMINALDISCOUNTACTION") ? _co.translate._("EURO") : _co.translate._("PERCENT")); _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "confirm--points__number"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationPage_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationPage_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.params.voucher.couponTypeCode !== "DISCOUNT"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.params.voucher.couponTypeCode === "DISCOUNT"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.params.voucher["points"], "", _co.translate.getSessionLanguage())); _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmationPage_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "confirm--points__free"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params == null) ? null : _co.params.voucher.title); _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmationPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "confirm--points"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationPage_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationPage_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationPage_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params == null) ? null : _co.params.voucher["points"]); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.params == null) ? null : _co.params.voucher["points"]); _ck(_v, 4, 0, currVal_1); var currVal_2 = !((_co.params == null) ? null : _co.params.voucher["points"]); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ConfirmationPage_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "confirm--subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p", [["class", "confirm--subtitle__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 3), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), ((_co.params == null) ? null : _co.params.subtitle), "", _co.translate.getSessionLanguage())); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.translate._("POINTS_TO_EXCHANGE"); _ck(_v, 5, 0, currVal_1); }); }
export function View_ConfirmationPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 15, "ion-content", [["class", "modal--inner confirm page-wrapper"]], null, null, null, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "confirm--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "modal--title confirm--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmationPage_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfirmationPage_7)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 6, "div", [["class", "modal--footer confirm--footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ion-button", [["class", "button confirm--button-transparent"], ["color", "dark"], ["expand", "block"], ["fill", "clear"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.cancelation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"], fill: [2, "fill"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "ion-button", [["class", "button confirm--button-primary"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.confirmation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(16, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.params == null) ? null : _co.params.voucher); _ck(_v, 7, 0, currVal_1); var currVal_2 = (!((_co.params == null) ? null : _co.params.voucher) && ((_co.params == null) ? null : _co.params.subtitle)); _ck(_v, 9, 0, currVal_2); var currVal_3 = "dark"; var currVal_4 = "block"; var currVal_5 = "clear"; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params == null) ? null : _co.params.title); _ck(_v, 5, 0, currVal_0); var currVal_6 = ((_co.params == null) ? null : _co.params.dismissText); _ck(_v, 13, 0, currVal_6); var currVal_7 = ((_co.params == null) ? null : _co.params.confirmText); _ck(_v, 16, 0, currVal_7); }); }
export function View_ConfirmationPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-confirmation", [], null, null, null, View_ConfirmationPage_0, RenderType_ConfirmationPage)), i1.ɵdid(1, 114688, null, 0, i5.ConfirmationPage, [i4.NavParams, i4.ModalController, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationPageNgFactory = i1.ɵccf("shared-confirmation", i5.ConfirmationPage, View_ConfirmationPage_Host_0, {}, {}, []);
export { ConfirmationPageNgFactory as ConfirmationPageNgFactory };
