import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit,
} from "@angular/core";
import { TranslateService } from "shared/lib/common/services";
import { IonSlides } from "@ionic/angular";
import { Group, SubGroup, ItemDetail } from "shared/lib/v2/apis/programs";
import { Level } from "../../pages/revenue/revenue.page";

const levels = {
  group: 0,
  subGroup: 1,
  item: 2,
};

const icons = {
  group: "apps",
  subGroup: "list",
  item: null,
};

@Component({
  selector: "shared-v2-revenue-item-list",
  templateUrl: "./revenue-item-list.component.html",
  styleUrls: ["./revenue-item-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevenueItemListComponent implements OnChanges, OnInit {
  @Input() public groupId: string;
  @Input() public subGroupId: string;
  @Input() public subGroups: SubGroup[];
  @Input() public groups: Group[];
  @Input() public items: ItemDetail[];
  @Input() public level: Level;
  @Output() public clickEvent: EventEmitter<{ item: SubGroup | Group | ItemDetail; addToBasket?: boolean }> = new EventEmitter();
  @ViewChild("slider", { static: false }) public slider: IonSlides;
  public iconByLevel: { [key: string]: string } = icons;

  constructor(public t: TranslateService) {}

  public ngOnInit(): void {
    if (this.slider) this.slider.lockSwipes(true);
  }

  public ngOnChanges({ level }: SimpleChanges): void {
    if (this.slider) {
      this.slider.lockSwipes(false);
      if (!!level && level.currentValue !== level.previousValue) this.slider.slideTo(levels[level.currentValue]);
      this.slider.lockSwipes(true);
    }
  }

  public getParentElement(actualLevel: Level): SubGroup | Group {
    if (actualLevel === "subGroup") {
      return this.groups.find(({ id }) => id === this.groupId);
    } else if (actualLevel === "item") {
      return this.subGroups.find(({ id }) => id === this.subGroupId);
    } else {
      return null;
    }
  }

  public getItems(level: Level): SubGroup[] | Group[] | ItemDetail[] {
    switch (level) {
      case "group":
        return this.groups || [];
      case "subGroup":
        return this.subGroups || [];
      case "item":
        return this.items || [];
      default:
        return [];
    }
  }

  public trackId(index: number, item: SubGroup | Group | ItemDetail): string {
    return `${index}-${item.id}`;
  }
}
