import { Component } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { ProductCatalogService, DeviceMountingService, AccountService, RatingConfigService } from "shared/lib/v2/services";
import { ConfigService, UserService, TranslateService, ToastService, InactivityService, RoutingService } from "shared/lib/common/services";
import { ItemDetail, GroupInfo, SubGroupInfo, Group, SubGroup, RatingConfig } from "shared/lib/v2/apis/programs";
import { ModalController, AlertController } from "@ionic/angular";
import { CalculatorPage, CalculatorPageOutputs, CalculatorPageInputs } from "shared/lib/v2/pages/calculator/calculator.page";
import { SummaryPageInputs, SummaryPage, SummaryPageOutputs } from "../summary/summary.page";
import { IUser } from "shared/lib/common/interfaces";
import {
  IConfirmationPageInputs,
  ConfirmationPage,
  IConfirmationPageOutputs,
} from "shared/lib/common/pages/confirmation/confirmation.page";
import { ICheckPinModalPageInput, CheckPinModalPage, ICheckPinModalPageOutput } from "shared/lib/common/pages";
import { Merge } from "shared/lib/common/interfaces";
import { AccountTransaction } from "../../apis/accounts";

export type Level = "group" | "subGroup" | "item";

export interface Revenue {
  money: number;
  points: number;
  blackListed?: boolean;
  disabled?: boolean;
  group?: GroupInfo;
  id: string;
  groupCode?: string;
  subGroupCode?: string;
  inherited?: boolean;
  itemCode?: string;
  name: string;
  partnerId: string;
  price?: number;
  program: string;
  subGroup?: SubGroupInfo;
  unitType?: string;
}

@Component({
  selector: "shared-v2-revenue",
  templateUrl: "./revenue.page.html",
  styleUrls: ["./revenue.page.scss"],
})
export class RevenuePage {
  public user: IUser;
  public earnGroups: Group[];
  public earnSubGroups: SubGroup[];
  public earnItems: ItemDetail[];
  public burnGroups: Group[];
  public burnSubGroups: SubGroup[];
  public burnItems: ItemDetail[];
  public revenues: Revenue[];
  public amount: number;
  public points: number;
  public routes: typeof SharedRoutes = SharedRoutes;
  public hasPayWithPoints: boolean;
  public mode: "earn" | "burn";
  public level: Level;
  public groupId: string;
  public subGroupId: string;
  public loading: boolean;
  public loadingItems: boolean;
  public timeout: NodeJS.Timeout;

  constructor(
    public routing: RoutingService,
    public t: TranslateService,
    public account: AccountService,
    public deviceMounting: DeviceMountingService,
    private product: ProductCatalogService,
    private config: ConfigService,
    private modal: ModalController,
    private userService: UserService,
    private toast: ToastService,
    private inactivity: InactivityService,
    private alert: AlertController,
    private ratingConfig: RatingConfigService,
  ) {}

  public ionViewWillEnter(): void {
    this.loading = true;
    this.level = "group";
    this.earnGroups = [];
    this.earnSubGroups = [];
    this.earnItems = [];
    this.burnGroups = [
      {
        id: "1",
        program: this.config.getOrganization(),
        partnerId: this.config.getMountingConfig().partner.id,
        name: this.t._("REVENUE_STEP1_BURN_1"),
        groupCode: "groupCode",
      },
    ];
    this.burnSubGroups = [];
    this.burnItems = [];
    this.revenues = [];
    this.amount = 0;
    this.points = 0;
  }

  public async ionViewDidEnter(): Promise<void> {
    this.user = this.userService.getUser();
    await this.fetchItems("group");
    await this.fetchProgramRatingConfig();
    this.loading = false;
  }

  public async ionViewDidLeave(): Promise<void> {
    clearTimeout(this.timeout);
  }

  public async resetMode(): Promise<void> {
    if (this.mode) {
      const alert: HTMLIonAlertElement = await this.alert.create({
        header: this.t._("RESET_REVENUE_CART_TITLE"),
        message: this.t._("RESET_REVENUE_CART_MESSAGE"),
        buttons: [
          {
            role: "cancel",
            text: this.t._("CANCEL"),
          },
          {
            text: this.t._("CONFIRM"),
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
      const { role } = await alert.onWillDismiss();
      if (!role || (role && role.toLowerCase() !== "cancel")) {
        this.mode = null;
        this.revenues = [];
        this.groupId = null;
        this.subGroupId = null;
        this.level = "group";
      }
    }
  }

  public async openCalculator(item: Group | SubGroup | ItemDetail): Promise<void> {
    const componentProps: CalculatorPageInputs = { item };
    const calculatorModal = await this.modal.create({
      component: CalculatorPage,
      componentProps,
      backdropDismiss: false,
    });
    await calculatorModal.present();
    const { data }: CalculatorPageOutputs = await calculatorModal.onWillDismiss();
    if (data) this.setTransactionPreview(item, data);
  }

  public async openSummary(): Promise<void> {
    const componentProps: SummaryPageInputs = { revenues: this.revenues, amount: this.amount, points: this.points, mode: this.mode };
    const summaryModal = await this.modal.create({
      component: SummaryPage,
      componentProps,
      backdropDismiss: false,
    });
    await summaryModal.present();
    const { data }: SummaryPageOutputs = await summaryModal.onWillDismiss();
    this.revenues = data;
    await this.updatePreview();
  }

  public async openConfirmation(): Promise<any> {
    const componentProps: IConfirmationPageInputs = {
      title: this.mode === "earn" ? this.t._("CONFIRM_EARN_TRANSACTION_TITLE") : this.t._("CONFIRM_BURN_TRANSACTION_TITLE"),
      subtitle: this.points.toString(),
      confirmText: this.t._("CONFIRM"),
      dismissText: this.t._("CANCEL"),
    };
    const confirmationModal = await this.modal.create({
      component: ConfirmationPage,
      cssClass: "modal",
      componentProps,
    });
    await confirmationModal.present();
    const { data } = (await confirmationModal.onDidDismiss()) as IConfirmationPageOutputs;
    if (data) {
      if (this.deviceMounting.profile.pinRequired) {
        this.showCheckPinModal(this.deviceMounting.profile.pin ? this.deviceMounting.profile.pin : "");
      } else {
        this.makeTransaction();
      }
    }
  }

  public async selectItem({ item, addToBasket }: { item: Group | SubGroup | ItemDetail; addToBasket: boolean }): Promise<void> {
    if (addToBasket) return this.openCalculator(item);

    switch (this.getItemLevel(item)) {
      case "group":
        this.level = "subGroup";
        this.groupId = item.id;
        this.fetchItems("subGroup", this.groupId);
        break;
      case "subGroup":
        this.level = "item";
        this.subGroupId = item.id;
        this.fetchItems("item", this.groupId, this.subGroupId);
        break;
    }
  }

  public async goBack(): Promise<void> {
    switch (this.level) {
      case "subGroup":
        this.level = "group";
        this.groupId = null;
        this.fetchItems("group");
        break;
      case "item":
        this.level = "subGroup";
        this.subGroupId = null;
        this.fetchItems("subGroup", this.groupId);
        break;
    }
  }

  public getListTitle(): string {
    switch (this.level) {
      case "group":
        return this.t._("GROUPS");
      case "subGroup":
        return this.t._("SUBGROUPS");
      case "item":
        return this.t._("ITEMS");
    }
  }

  public getName(id: string, type: "group" | "subgroup"): string {
    switch (type) {
      case "group":
        let group: Group;
        if (this.mode === "earn") group = this.earnGroups.find(g => g.id === id);
        else group = this.burnGroups.find(g => g.id === id);
        return group ? group.name : "";
      case "subgroup":
        let subgroup: SubGroup;
        if (this.mode === "earn") subgroup = this.earnSubGroups.find(g => g.id === id);
        else subgroup = this.burnSubGroups.find(g => g.id === id);
        return subgroup ? subgroup.name : "";
    }
  }

  public async updatePreview(): Promise<void> {
    this.loading = true;
    const user = this.userService.getUser();
    try {
      const resp: AccountTransaction = await this.account
        .createTransactionPrev(
          user.keyCode,
          this.config.getMountingConfig().workstationId,
          user.idType,
          this.account.getTransactionData(this.revenues, this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION"),
        )
        .toPromise();
      this.revenues = resp.lineItems.map(l => {
        return {
          name: l.description,
          points: l.points,
          money: l.extendedAmount,
          id: l.itemID,
          partnerId: this.config.getMountingConfig().partner.id,
          program: this.config.getOrganization(),
          groupCode: l.merchandiseGroupCode,
          subGroupCode: l.merchandiseSubGroupCode,
        };
      });
      this.amount = resp.amount;
      this.points = resp.points;
    } catch (error) {
      const e = error.error && error.error.message ? error.error.message : error.message || error;
      this.toast.show(this.t._(e), "error");
    } finally {
      this.loading = false;
    }
  }

  protected async makeTransaction(): Promise<void> {
    this.loading = true;
    const transactions =
      this.mode === "earn"
        ? this.account.getTransactionData(this.revenues, "EARNTRANSACTION")
        : this.account.getTransactionData(this.revenues, "PAYWITHPOINTSTRANSACTION");
    const user = this.userService.getUser();
    try {
      await this.account
        .createTransaction(user.keyCode, this.config.getMountingConfig().workstationId, undefined, user.idType, transactions)
        .toPromise();
      this.revenues = [];
      this.timeout = setTimeout(() => {
        this.toast.show(this.t._("ARTS_ERROR"), "error");
        this.inactivity.resetTimer();
        clearTimeout(this.timeout);
        this.loading = false;
      }, 30000);
    } catch (error) {
      const e = error.error && error.error.message ? error.error.message : error.message || error;
      this.toast.show(this.t._(e), "error");
      this.loading = false;
    }
  }

  private async showCheckPinModal(pin: string): Promise<void> {
    const componentProps: ICheckPinModalPageInput = { pin };
    const checkPinModal = await this.modal.create({
      component: CheckPinModalPage,
      cssClass: "modal modal--full-screen",
      componentProps,
    });
    await checkPinModal.present();
    const { data } = (await checkPinModal.onDidDismiss()) as ICheckPinModalPageOutput;
    if (data) this.makeTransaction();
  }

  private async setTransactionPreview(item: Group | SubGroup | ItemDetail, data: number): Promise<void> {
    this.loading = true;
    const revenue: Merge<Group | SubGroup | ItemDetail, { money: number; points: number }> = {
      ...item,
      blacklisted: item.blacklisted,
      money: data,
      points: data,
    };
    const user = this.userService.getUser();
    try {
      const resp: AccountTransaction = await this.account
        .createTransactionPrev(
          user.keyCode,
          this.config.getMountingConfig().workstationId,
          user.idType,
          this.account.getTransactionData(
            [...this.revenues, revenue],
            this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION",
          ),
        )
        .toPromise();
      if (this.user.totalPoints >= resp.points || this.mode === "earn") {
        this.revenues = resp.lineItems.map(l => {
          return {
            name: l.description,
            points: l.points,
            money: l.extendedAmount,
            id: l.itemID,
            partnerId: this.config.getMountingConfig().partner.id,
            program: this.config.getOrganization(),
            groupCode: l.merchandiseGroupCode,
            subGroupCode: l.merchandiseSubGroupCode,
          };
        });
        this.amount = resp.amount;
        this.points = resp.points;
      } else this.toast.show(this.t._("NOT_ENOUGH_POINT"), "error");
    } catch (error) {
      const e = error.error && error.error.message ? error.error.message : error.message || error;
      this.toast.show(this.t._(e), "error");
    } finally {
      this.loading = false;
    }
  }

  private async fetchItems(level: Level, groupId?: string, subGroupId?: string): Promise<void> {
    try {
      switch (level) {
        case "group":
          this.loadingItems = true;
          this.earnGroups = await this.product
            .getGroupsOfPartner(this.config.getOrganization(), this.config.getMountingConfig().partner.id)
            .toPromise();
          break;
        case "subGroup":
          this.loadingItems = true;
          this.earnSubGroups = await this.product
            .getSubGroupsOfPartnerAndGroup(this.config.getOrganization(), this.config.getMountingConfig().partner.id, groupId)
            .toPromise();
          break;
        case "item":
          this.loadingItems = true;
          const patnerItems: ItemDetail[] = await this.product
            .getItemsOfPartner(this.config.getOrganization(), this.config.getMountingConfig().partner.id)
            .toPromise();
          this.earnItems = patnerItems.filter(i => i.group.groupId === groupId && i.subGroup.subGroupId === subGroupId);
          break;
      }
      this.loadingItems = false;
    } catch (error) {
      this.toast.show(this.t._(error), "error");
    }
  }

  private getItemLevel(item: Group | SubGroup | ItemDetail): Level {
    if (item["groupCode"]) return "group";
    if (item["subGroupCode"]) return "subGroup";
    return "item";
  }

  private async fetchProgramRatingConfig(): Promise<void> {
    const resp: RatingConfig = await this.ratingConfig.getRatingConfigForProgram(this.config.getOrganization()).toPromise();
    this.hasPayWithPoints = this.allowedPayWithPoints(resp.pwpAllowed);
    if (!this.hasPayWithPoints) this.mode = "earn";
  }

  private allowedPayWithPoints(pwpAllowed: RatingConfig.PwpAllowedEnum): boolean {
    switch (pwpAllowed) {
      case "NO":
        return false;
      case "REGISTEREDUSERS":
        return this.user.isRegistered;
      case "ALLUSERS":
        return true;
    }
  }
}
