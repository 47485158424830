export interface IDeviceConfig {
  name: string;
  zoom: number;
  exposureCompensation: number;
}

export const CONFIGURATIONS_BY_DEVICE: Array<IDeviceConfig> = [
  { name: "SM-T82", zoom: 1, exposureCompensation: -1 },
  { name: "SM-A75", zoom: 4, exposureCompensation: -1 },
];

export const CONFIGURATIONS_BY_WORKSTATION_ID: Array<IDeviceConfig> = [];

export const CONFIGURATIONS_BY_ORGANIZATION: Array<IDeviceConfig> = [{ name: "hvo", zoom: 1, exposureCompensation: -1 }];
