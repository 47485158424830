var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NgZone } from "@angular/core";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ModalController, Platform } from "@ionic/angular";
import { InformationModalPage } from "shared/lib/common/pages";
import { QrScannerPage as SharedQRScannerPage } from "shared/lib/common/pages";
import { ToastService, TranslateService, UserService, InactivityService, RoutingService, AlertService } from "shared/lib/common/services";
import { AdminService, PublicAccountService } from "shared/lib/v2/services";
import { SharedRoutes } from "shared/lib/common/enums";
import { ConfigService } from "shared/lib/common/services";
export class QrScannerPage extends SharedQRScannerPage {
    constructor(_zone, _translate, _userService, _toast, _config, _modal, _admin, _inactivity, _platform, _qrScanner, _routing, _publicAccount, _alert) {
        super(_zone, _translate, _userService, _toast, _config, _modal, _admin, _inactivity, _platform, _qrScanner, _routing, _alert);
        this._zone = _zone;
        this._translate = _translate;
        this._userService = _userService;
        this._toast = _toast;
        this._config = _config;
        this._modal = _modal;
        this._admin = _admin;
        this._inactivity = _inactivity;
        this._platform = _platform;
        this._qrScanner = _qrScanner;
        this._routing = _routing;
        this._publicAccount = _publicAccount;
        this._alert = _alert;
    }
    user(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (keyCode) {
                const [checkInResponse, details] = yield Promise.all([
                    this._userService.checkIn(keyCode),
                    this._userService.getDetails(keyCode, idType),
                ]);
                if (checkInResponse.ok === false)
                    this._toast.show(this.translate._(checkInResponse.error.message), "error");
                if (details.ok === true) {
                    const queryParams = { idType, keyCode };
                    const saveUserResponse = yield this.saveUser(details.response, idType);
                    if (saveUserResponse.ok === true) {
                        if (this._config.getOrganization() === "dal") {
                            if (details.response.isRegistered) {
                                this._routing.goForward([SharedRoutes.burn], { queryParams });
                            }
                            else {
                                yield this.showInfoModal();
                                return this._routing.goForward([SharedRoutes.screenSaver]);
                            }
                        }
                        else {
                            this._routing.goForward([SharedRoutes.burn], { queryParams });
                        }
                    }
                    else
                        this._toast.show(this.translate._(saveUserResponse.error.message), "error");
                    return saveUserResponse.ok;
                }
                else
                    this._toast.show(this.translate._(details.error.message), "error");
                return details.ok;
            }
            return false;
        });
    }
    showInfoModal() {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                title: this.translate._("PLEASE_REGISTER"),
                description: "",
                buttonText: this.translate._("UNDERSTOOD"),
            };
            const informationModal = yield this._modal.create({
                component: InformationModalPage,
                cssClass: "modal",
                componentProps,
                backdropDismiss: false,
            });
            yield informationModal.present();
            yield informationModal.onDidDismiss();
        });
    }
}
