<shared-header></shared-header>
<shared-close (tap)="dismiss('CANCELLED')"></shared-close>
<div class="register__header">
  <h1 class="register__header__title">{{ t._("REGISTER") }}</h1>
</div>
<span class="register__separator"></span>
<ion-content class="page-wrapper">
  <form class="register__content" autocomplete="off" [formGroup]="form">
    <div class="register__content__name">
      <div class="register__content__name__email">
        <span [class.--hidden]="!this.form?.value?.emailAddress">{{ buildName("EMAIL", true) }}</span>
        <input autocomplete="off" type="email" formControlName="emailAddress" [placeholder]="buildName('EMAIL', true)" />
      </div>
      <div class="register__content__name__given-name" *ngIf="isEnable()">
        <span [class.--hidden]="!this.form?.value?.givenName">{{ buildName("GIVEN_NAME") }}</span>
        <input autocomplete="off" type="text" formControlName="givenName" [placeholder]="buildName('GIVEN_NAME')" />
      </div>
      <div class="register__content__name__family-name" *ngIf="isEnable()">
        <span [class.--hidden]="!this.form?.value?.familyName">{{ buildName("FAMILY_NAME") }}</span>
        <input autocomplete="off" type="text" formControlName="familyName" [placeholder]="buildName('FAMILY_NAME')" />
      </div>
    </div>
    <span *ngIf="isEnable()" class="register__content__separator"></span>
    <div *ngIf="isEnable()" class="register__content__house">
      <div class="register__content__house__street">
        <span [class.--hidden]="!this.form?.value?.streetHouseNo">{{ buildName("STREET") }}</span>
        <input autocomplete="off" type="text" formControlName="streetHouseNo" [placeholder]="buildName('STREET')" />
      </div>
      <div class="register__content__house__postal">
        <span [class.--hidden]="!this.form?.value?.zipCode">{{ buildName("POSTAL") }}</span>
        <input autocomplete="off" type="text" formControlName="zipCode" [placeholder]="buildName('POSTAL')" />
      </div>
      <div class="register__content__house__city">
        <span [class.--hidden]="!this.form?.value?.city">{{ buildName("CITY") }}</span>
        <input autocomplete="off" type="text" formControlName="city" [placeholder]="buildName('CITY')" />
      </div>
      <div class="register__content__house__country">
        <span [class.--hidden]="!this.form?.value?.countryCode">{{ buildName("COUNTRY") }}</span>
        <ion-select name="countryCode" formControlName="countryCode" placeholder="-- {{ buildName('COUNTRY') }} --">
          <ion-select-option *ngFor="let country of countries" [value]="country">{{ t._(country) }} </ion-select-option>
        </ion-select>
      </div>
    </div>
    <span *ngIf="isEnable()" class="register__content__separator"></span>
    <div *ngIf="isEnable()" class="register__content__phone">
      <div class="register__content__phone__phone">
        <span [class.--hidden]="!this.form?.value?.phoneNumber">{{ t._("PHONE") }}</span>
        <input autocomplete="off" type="tel" formControlName="phoneNumber" placeholder="+44 656 445 443" />
      </div>
      <div class="register__content__phone__birthdate">
        <span>{{ buildName("BIRTHDATE") }}</span>
        <input class="register__content__phone__birthdate__input" autocomplete="off" type="date" formControlName="birthdate" />
      </div>
      <div class="register__content__phone__gender">
        <span>{{ buildName("GENDER") }}</span>
        <ion-select name="genderCode" formControlName="genderCode" placeholder="--">
          <ion-select-option value="M">{{ t._("MALE") }}</ion-select-option>
          <ion-select-option value="F">{{ t._("FEMALE") }}</ion-select-option>
          <ion-select-option value="NA">{{ t._("NO_ANSWER") }}</ion-select-option>
        </ion-select>
      </div>
    </div>
    <div class="register__content__text-item">
      <input
        type="checkbox"
        name="declarationOfConsent"
        formControlName="declarationOfConsent"
        class="register__content__text-item__checkbox"
      />
      <p class="register__content__text-item__text" [innerHTML]="t._('MEMBER_REGISTER_DECLARATION_OF_CONSENT', client)"></p>
    </div>
    <div class="register__content__drop-down" [class.--show-more-text]="showMoreText">
      <input type="checkbox" name="acceptConsent" formControlName="acceptConsent" class="register__content__text-item__checkbox" />
      <p [innerHTML]="t._('FORM_OPTIN_ADVERTISING', client, client, client)"></p>
      <a class="register__content__drop-down__read-more" (tap)="showMoreText = !showMoreText">{{ t._("READ_MORE") }}</a>
    </div>
    <p class="register__content__policies">
      <span [innerHTML]="t._('MEMBER_REGISTER_TERMS_1')" (tap)="open('https://point4more.com/terms/')"></span>
      <span [innerHTML]="t._('MEMBER_REGISTER_TERMS_2')" (tap)="open('https://point4more.com/privacy/')"></span>
    </p>
    <div class="register__content__button">
      <shared-button
        class="register__content__button__register"
        [color]="'primary'"
        [fill]="'solid'"
        [disabled]="!form.valid"
        (tap)="register()"
        [loading]="isLoading"
      >
        {{ t._("REGISTER") }}
      </shared-button>
      <shared-button class="register__content__button__dismiss" [color]="'secondary'" [fill]="'solid'" (tap)="dismiss('CANCELLED')">
        {{ t._("DISMISS") }}
      </shared-button>
    </div>
  </form>
</ion-content>
