var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { VoucherType, } from "shared/lib/common/interfaces";
import { AdminService } from "shared/lib/common/services/admin/admin.service";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import * as i0 from "@angular/core";
import * as i1 from "../sentry/sentry.service";
import * as i2 from "../p4m/p4m.service";
import * as i3 from "../admin/admin.service";
import * as i4 from "../config/config.service";
import * as i5 from "../translate/translate.service";
export class UserService {
    constructor(sentry, p4m, admin, config, translate) {
        this.sentry = sentry;
        this.p4m = p4m;
        this.admin = admin;
        this.config = config;
        this.translate = translate;
    }
    checkIn(keyCode) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.p4m.checkIn({
                apiKey: this.admin.getApiKey(),
                deviceKey: this.config.getMountingConfig().deviceKey,
                language: this.translate.getSessionLanguage(),
                keyCode,
                organization: this.config.getOrganization(),
            });
        });
    }
    setUser(user) {
        this.user = user;
    }
    getUser() {
        return this.user;
    }
    setHistory(history) {
        this.history = history;
    }
    getHistory() {
        return this.history || { transactions: [] };
    }
    setAccountCouponsRedemptions(accountCouponsRedemptions) {
        this.accountCouponsRedemptions = accountCouponsRedemptions;
    }
    getAccountCouponsRedemptions() {
        return this.accountCouponsRedemptions || [];
    }
    getCoupons() {
        return this.coupons || [];
    }
    updateUserCoupon(coupon) {
        const i = this.user.coupons.findIndex(c => c.couponId === coupon.couponId);
        if (i >= 0)
            this.user.coupons[i] = this.p4m.parseCoupons([coupon], this.user.totalPoints)[0];
    }
    getExternalCode(voucher) {
        let response;
        if (this.user) {
            if (voucher.voucherType === VoucherType.coupon) {
                (this.user.coupons || []).forEach(c => {
                    if (c.couponId === voucher.voucherId) {
                        response = {
                            type: c.externalCodeBarcodeTypeCode,
                            code: c.externalCode,
                        };
                    }
                });
            }
            else {
                (this.user.rewards || []).forEach(r => {
                    if (r.rewardId === voucher.voucherId) {
                        response = {
                            type: r.externalCodeBarcodeTypeCode,
                            code: r.externalCode,
                        };
                    }
                });
            }
        }
        return response ? { ok: true, response } : { ok: false, error: { message: "COUPON_NOT_FOUND" } };
    }
    getDetails(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userDetailsResponse = yield this.p4m.getUserDetails({
                    keyCode,
                    apiKey: this.admin.getApiKey(),
                    clientId: this.config.getMountingConfig().clientId,
                    idType,
                });
                if (userDetailsResponse.ok === true) {
                    const [userResponse, couponsResponse, rewardsResponse] = yield Promise.all([
                        this.p4m.readUser({ idType: "ID", userId: userDetailsResponse.response.userId }),
                        this.p4m.getCoupons({ apiKey: this.admin.getApiKey(), clientId: this.config.getMountingConfig().clientId }),
                        this.p4m.getRewards({ apiKey: this.admin.getApiKey(), clientId: this.config.getMountingConfig().clientId }),
                    ]);
                    if (userResponse.ok === true && couponsResponse.ok === true && rewardsResponse.ok === true) {
                        this.setCoupons(couponsResponse.response.coupons);
                        const coupons = this.parseCoupons(couponsResponse.response.coupons);
                        const rewards = this.parseRewards(rewardsResponse.response.rewards);
                        return this.parseUser(keyCode, userDetailsResponse.response, coupons, rewards, userResponse.response, idType);
                    }
                    else {
                        let message;
                        [userResponse, couponsResponse, rewardsResponse].forEach(response => {
                            if (response.ok === false)
                                message = response.error.message;
                        });
                        return { ok: false, error: { message } };
                    }
                }
                else {
                    return userDetailsResponse;
                }
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "user.getDetails" });
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
    getCouponKeys() {
        return __awaiter(this, void 0, void 0, function* () {
            const responses = yield Promise.all((this.coupons || []).map(({ voucherId }) => this.p4m.getCouponKeys({
                couponId: voucherId.toString(),
                organization: this.config.getOrganization(),
            })));
            this.coupons = this.coupons.map(coupon => {
                let couponKeys = [];
                const response = responses.find(c => {
                    if (c.ok === true)
                        return c.response.coupon.couponId === coupon.voucherId;
                });
                if (response && response.ok === true)
                    couponKeys = response.response.couponKeys;
                return Object.assign({}, coupon, { couponKeys });
            });
        });
    }
    getUserHistory(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.p4m.getUserHistory({
                    keyCode,
                    apiKey: this.admin.getApiKey(),
                    clientId: this.config.getMountingConfig().clientId,
                    idType,
                });
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "user.getUserHistory" });
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
    readAccountCouponsRedemptions(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.p4m.readAccountCouponsRedemptions(keyCode, idType);
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "user.readAccountCouponsRedemptions" });
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
    removeUser() {
        this.user = undefined;
        this.history = undefined;
        this.coupons = undefined;
    }
    // API1:
    // title: description
    // description: extended description (or empty)
    // API2:
    // title: title || subtitle
    // description: description
    getVouchers() {
        const resp = [];
        if (this.user) {
            if (this.user.coupons) {
                this.user.coupons.forEach(c => {
                    if (!c.storeId || (c.storeId && c.storeId === this.config.getMountingConfig().storeId)) {
                        resp.push({
                            title: c.title || c.subtitle || c.description,
                            couponTypeCode: c.couponTypeCode,
                            voucherType: VoucherType.coupon,
                            points: c.points,
                            description: c.extendedDescription || (c.title || c.subtitle ? c.description : ""),
                            unlocked: c.unlocked,
                            voucherId: c.couponId,
                            accountVoucherId: c.accountCouponId,
                            extendedDescription: c.extendedDescription,
                            contentPictureFilePath: this.getPicture(c.couponFiles, "content", c.pictureFilePath),
                            teaserPictureFilePath: this.getPicture(c.couponFiles, "teaser", c.pictureFilePath),
                            userCouponKeyCodes: c.userCouponKeyCodes,
                            redeemedUserCouponKeyCodes: c.redeemedUserCouponKeyCodes,
                            validToDate: c.validToDate,
                            validFromDate: c.validFromDate,
                            redeemDependencyCode: c.redeemDependencyCode,
                            customProperties: c.customProperties,
                            activation: c.activation,
                            redeemActions: c.redeemActions,
                        });
                    }
                });
            }
            if (this.user.rewards) {
                this.user.rewards.forEach(r => {
                    resp.push({
                        title: r.title || r.subtitle || r.description,
                        voucherType: VoucherType.reward,
                        decreasePoints: r.decreasePoints,
                        description: r.extendedDescription || (r.title || r.subtitle ? r.description : ""),
                        voucherId: r.rewardId,
                        accountVoucherId: r.accountCouponId,
                        unlocked: r.unlocked,
                        points: r.points,
                        contentPictureFilePath: this.getPicture(r.rewardFiles, "content", r.pictureFilePath),
                        teaserPictureFilePath: this.getPicture(r.rewardFiles, "teaser", r.pictureFilePath),
                        redeemDependencyCode: r.redeemDependencyCode,
                        customProperties: r.customProperties,
                        validFromDate: r.validFromDate,
                        validToDate: r.validToDate,
                        extendedDescription: r.extendedDescription,
                        activation: r.activation,
                        redeemActions: r.redeemActions,
                    });
                });
            }
        }
        return resp;
    }
    redeemReward(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.p4m.redeemReward({
                    keyCode: this.user.keyCode,
                    rewardId: voucher.voucherId.toString(),
                    accountVoucherId: voucher.accountVoucherId,
                    apiKey: this.admin.getApiKey(),
                    clientId: this.config.getMountingConfig().clientId,
                });
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "user.redeemReward" });
                return {
                    ok: false,
                    error: { message: error },
                };
            }
        });
    }
    redeemCoupon(voucher, couponCode) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.p4m.redeemCoupon({
                    keyCode: this.user ? this.user.keyCode : "",
                    apiKey: this.admin.getApiKey(),
                    clientId: this.config.getMountingConfig().clientId,
                    couponId: voucher.voucherId,
                    accountVoucherId: voucher.accountVoucherId,
                    couponCode,
                });
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "user.redeemCoupon" });
                return {
                    ok: false,
                    error: { message: error },
                };
            }
        });
    }
    convertToPoints(euros) {
        if (this.user && this.user.convertFactor) {
            return euros * this.user.convertFactor;
        }
        else {
            return euros;
        }
    }
    revenuePoints(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.p4m.revenuePoints({
                organization: this.config.getOrganization(),
                keyCode: params.keyCode,
                deviceKey: params.deviceKey,
                idType: params.idType,
                revenueRmb: params.revenueRmb,
                revenueRmc1: params.revenueRmc1,
                revenueRmc2: params.revenueRmc2,
                revenueRmc3: params.revenueRmc3,
                revenueRmt: params.revenueRmt,
                totalPoints: params.totalPoints,
            });
        });
    }
    bookPoints(keyCode, customPoints, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.p4m.bookPoints({
                keyCode,
                points: customPoints,
                idType,
                apiKey: this.admin.getApiKey(),
                clientId: this.config.getMountingConfig().clientId,
            });
        });
    }
    getEarnCoupons() {
        if (this.user && this.user.coupons) {
            return this.user.coupons.filter(({ couponTypeCode }) => couponTypeCode === "EARN");
        }
        else {
            return [];
        }
    }
    getEarnListFilters() {
        const filters = [];
        const coupons = this.getEarnCoupons();
        if (coupons) {
            coupons.forEach(c => {
                if (c.subtitle && !filters.includes(c.subtitle)) {
                    filters.push(c.subtitle);
                }
            });
        }
        return filters;
    }
    parseCoupons(coupons) {
        const resp = {};
        (coupons || []).forEach(coupon => {
            let storeId = null;
            if (coupon.sponsorStore && coupon.sponsorStore.storeId !== null) {
                storeId = coupon.sponsorStore.storeId;
            }
            if (coupon.store && coupon.store.storeId !== null) {
                storeId = coupon.store.storeId;
            }
            resp[coupon.couponId] = Object.assign({}, coupon, { storeId });
        });
        return resp;
    }
    parseRewards(rewards) {
        const resp = {};
        if (rewards) {
            rewards.forEach(reward => {
                resp[reward.rewardId] = reward;
            });
        }
        return resp;
    }
    parseUser(keyCode, user, infoCoupons, infoRewards, userData, idType) {
        if (user) {
            if (user.errorInfo) {
                return { ok: false, error: { message: user.errorInfo.errorCode } };
            }
            if (typeof user.totalPoints === undefined) {
                return {
                    ok: false,
                    error: { message: "EMPTY_USER_TOT" },
                };
            }
            if (typeof user.userId === undefined) {
                return {
                    ok: false,
                    error: { message: "EMPTY_USER_ID" },
                };
            }
        }
        else {
            return {
                ok: false,
                error: { message: "EMPTY_USER" },
            };
        }
        const coupons = [];
        const rewards = [];
        if (user.coupons) {
            user.coupons.forEach(coupon => {
                coupons.push(Object.assign({}, coupon, { couponId: coupon.couponId, couponTypeCode: coupon.couponTypeCode, description: coupon.description, unlocked: coupon.unlocked, subtitle: coupon.subtitle, points: coupon.points, extendedDescription: coupon.extendedDescription, pictureFilePath: coupon.pictureFilePath, couponFiles: coupon.couponFiles, userCouponKeyCodes: coupon.userCouponKeyCodes, redeemedUserCouponKeyCodes: coupon.redeemedUserCouponKeyCodes, storeId: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].storeId : null, externalCode: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].externalCode : coupon.externalCode, externalCodeBarcodeTypeCode: infoCoupons[coupon.couponId]
                        ? infoCoupons[coupon.couponId].externalCodeBarcodeTypeCode
                        : coupon.externalCodeBarcodeTypeCode, validFromDate: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].validFromDate : coupon.validFromDate, validToDate: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].validToDate : coupon.validToDate, redeemActions: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].redeemActions : coupon.redeemActions }));
            });
        }
        if (user.rewards) {
            user.rewards.forEach(reward => {
                rewards.push(Object.assign({}, reward, { accountCouponId: reward.accountCouponId, rewardId: reward.rewardId, points: reward.points, title: reward.title, subtitle: reward.subtitle, description: reward.description, extendedDescription: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].extendedDescription : reward.extendedDescription, decreasePoints: reward.decreasePoints, unlocked: reward.unlocked, pictureFilePath: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].pictureFilePath : null, externalCode: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].externalCode : null, externalCodeBarcodeTypeCode: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].externalCodeBarcodeTypeCode : null, validFromDate: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].validFromDate : reward.validFromDate, validToDate: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].validToDate : reward.validToDate, redeemActions: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].redeemActions : reward.redeemActions }));
            });
        }
        return {
            ok: true,
            response: {
                userStatusCode: user.userStatusCode,
                userId: user.userId,
                userReferenceCode: user.userReferenceCode,
                totalPoints: user.totalPoints,
                coupons,
                rewards,
                keyCode,
                convertFactor: user.convertFactor || 1,
                errorInfo: user.errorInfo,
                emailAddress: userData.emailAddress,
                firstAction: user.firstAction,
                statusDefinitions: user.statusDefinitions,
                isRegistered: user.userStatusCode === "RGU",
                idType,
                revenueAmountPayableWithPoints: user.revenueAmountPayableWithPoints,
            },
        };
    }
    validateRedeemParams(qrJson) {
        if (qrJson.keyCode) {
            if (qrJson.couponCode) {
                return {
                    ok: true,
                    response: {
                        keyCode: qrJson.keyCode,
                        code: qrJson.couponCode,
                        isCoupon: true,
                    },
                };
            }
            else if (qrJson.rewardId) {
                return {
                    ok: true,
                    response: {
                        keyCode: qrJson.keyCode,
                        code: qrJson.rewardId.toString(),
                        isCoupon: false,
                    },
                };
            }
            else {
                return {
                    ok: false,
                    error: {
                        message: "VOUCHER_CODE_NOT_FOUND",
                    },
                };
            }
        }
        else {
            return {
                ok: false,
                error: { message: "KEYCODE_NOT_FOUND" },
            };
        }
    }
    getPicture(files, type, defaultImage) {
        let pictureFile;
        if (files) {
            files.forEach(file => {
                if (file.tag === `${type}Image` || file.tag === `${type}Picture`) {
                    pictureFile = { file: file.pictureFilePath, isCorrectPicture: true };
                }
            });
            if (!pictureFile && files.length > 0)
                pictureFile = { file: files[0].pictureFilePath, isCorrectPicture: false };
        }
        return pictureFile || { file: defaultImage, isCorrectPicture: false };
    }
    setCoupons(coupons) {
        this.coupons = [];
        (coupons || []).forEach(c => {
            if (!c.store || !c.store.storeId || (c.store && c.store.storeId) === this.config.getMountingConfig().storeId) {
                this.coupons.push({
                    title: c.title || c.subtitle || c.description,
                    couponTypeCode: c.couponTypeCode,
                    voucherType: VoucherType.coupon,
                    points: c.points,
                    description: c.extendedDescription || (c.title || c.subtitle ? c.description : ""),
                    unlocked: c.unlocked,
                    voucherId: c.couponId,
                    accountVoucherId: c.accountCouponId,
                    extendedDescription: c.extendedDescription,
                    contentPictureFilePath: this.getPicture(c.couponFiles, "content", c.pictureFilePath),
                    teaserPictureFilePath: this.getPicture(c.couponFiles, "teaser", c.pictureFilePath),
                    userCouponKeyCodes: c.userCouponKeyCodes,
                    redeemedUserCouponKeyCodes: c.redeemedUserCouponKeyCodes,
                    validToDate: c.validToDate,
                    validFromDate: c.validFromDate,
                    redeemDependencyCode: c.redeemDependencyCode,
                    customProperties: c.customProperties,
                    activation: c.activation,
                    redeemActions: c.redeemActions,
                });
            }
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.SentryService), i0.ɵɵinject(i2.P4mService), i0.ɵɵinject(i3.AdminService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.TranslateService)); }, token: UserService, providedIn: "root" });
