import { Injectable, Inject, Optional } from "@angular/core";
import {
  AccountCouponService as ConvercusAccountCouponService,
  AccountCouponV2Service,
  Configuration,
  BASE_PATH,
  ActivationPatchData,
} from "shared/lib/v2/apis/coupons";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { INTERACTION_ID } from "shared/lib/common/constants";
import { EnvironmentService } from "shared/lib/common/services";

@Injectable({ providedIn: "root" })
export class AccountCouponService extends ConvercusAccountCouponService {
  private accountCouponV2: AccountCouponV2Service;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
    this.accountCouponV2 = new AccountCouponV2Service(httpClient, basePath, configuration);
  }

  public changeActivationStatus(
    accountcouponid: string,
    body: ActivationPatchData,
    interactionId: string = INTERACTION_ID,
  ): Observable<any> {
    return this.accountCouponV2.changeActivationStatus(accountcouponid, interactionId, body);
  }

  public getAccountCoupon(accountCouponId: string, interactionId: string = INTERACTION_ID): Observable<any> {
    return this.accountCouponV2.getAccountCouponV2(accountCouponId, interactionId);
  }
}
