<shared-header></shared-header>
<shared-close (tap)="dismiss('CANCELLED')"></shared-close>
<div class="register__header">
  <h1 class="register__header__title">{{ t._("REGISTER") }}</h1>
</div>
<span class="register__separator"></span>
<ion-content class="page-wrapper">
  <form class="register__content" autocomplete="off" [formGroup]="form">
    <ng-container *ngFor="let userProperty of userProperties">
      <div
        class="register__content__field"
        *ngIf="userProperty.type === 'text' || userProperty.type === 'email' || userProperty.type === 'password'"
      >
        <span class="register__content__field__label">{{ buildName(userProperty.name, userProperty.mandatory) }}</span>
        <input autocomplete="off" [type]="userProperty.type" [formControlName]="userProperty.name" />
      </div>
      <div class="register__content__field" *ngIf="userProperty.type === 'select' && userProperty.name === 'countryCode'">
        <span class="register__content__field__label">{{ buildName(userProperty.name, userProperty.mandatory) }}</span>
        <ion-select [name]="userProperty.name" [formControlName]="userProperty.name">
          <ion-select-option *ngFor="let country of countries" [value]="country">{{ t._(country) }} </ion-select-option>
        </ion-select>
      </div>
      <div class="register__content__field" *ngIf="userProperty.type === 'date'">
        <span class="register__content__field__label">{{ buildName(userProperty.name, userProperty.mandatory) }}</span>
        <input autocomplete="off" type="date" [formControlName]="userProperty.name" />
      </div>
      <div class="register__content__field" *ngIf="userProperty.type === 'select' && userProperty.name === 'genderCode'">
        <span class="register__content__field__label">{{ buildName(userProperty.name, userProperty.mandatory) }}</span>
        <ion-select [name]="userProperty.name" [formControlName]="userProperty.name">
          <ion-select-option value="MALE">{{ t._("MALE") }}</ion-select-option>
          <ion-select-option value="FEMALE">{{ t._("FEMALE") }}</ion-select-option>
          <ion-select-option value="DIVERSE">{{ t._("NO_ANSWER") }}</ion-select-option>
        </ion-select>
      </div>
    </ng-container>
    <div class="register__content__text-item" *ngFor="let optin of membershipOptins">
      <input type="checkbox" [name]="optin" [formControlName]="optin" class="register__content__text-item__checkbox" />
      <p class="register__content__text-item__text" [innerHTML]="t._(optin)"></p>
    </div>
    <div class="register__content__button">
      <shared-button
        class="register__content__button__register"
        [color]="'primary'"
        [fill]="'solid'"
        [disabled]="!form.valid && !isLoading"
        (tap)="register()"
        [loading]="isLoading"
      >
        {{ t._("REGISTER") }}
      </shared-button>
      <shared-button class="register__content__button__dismiss" [color]="'secondary'" [fill]="'solid'" (tap)="dismiss('CANCELLED')">
        {{ t._("DISMISS") }}
      </shared-button>
    </div>
  </form>
</ion-content>
