/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Store } from './store';
import { Partner } from './partner';


export interface InteractionPointDetails { 
    businessUnitId?: string;
    businessUnitType?: InteractionPointDetails.BusinessUnitTypeEnum;
    id?: string;
    name?: string;
    partner?: Partner;
    store?: Store;
    type?: InteractionPointDetails.TypeEnum;
}
export namespace InteractionPointDetails {
    export type BusinessUnitTypeEnum = 'PARTNER' | 'PROGRAM' | 'STORE';
    export const BusinessUnitTypeEnum = {
        PARTNER: 'PARTNER' as BusinessUnitTypeEnum,
        PROGRAM: 'PROGRAM' as BusinessUnitTypeEnum,
        STORE: 'STORE' as BusinessUnitTypeEnum
    };
    export type TypeEnum = 'APP' | 'CASHIER' | 'ONLINE';
    export const TypeEnum = {
        APP: 'APP' as TypeEnum,
        CASHIER: 'CASHIER' as TypeEnum,
        ONLINE: 'ONLINE' as TypeEnum
    };
}


