var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NgZone } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { RegisterPage } from "shared/lib/common/pages/register/register.page";
import { SuccessPage } from "shared/lib/common/pages/success/success.page";
import { AdminService, AlertService, InactivityService, RoutingService, ToastService, TranslateService, UserService, } from "shared/lib/common/services";
import { InformationModalPage } from "shared/lib/common/pages/information-modal/information-modal.page";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ConfigService } from "shared/lib/common/services";
export const positionMap = {
    BC: "top",
    TR: "left",
    RC: "right",
};
export var IAudioFile;
(function (IAudioFile) {
    IAudioFile[IAudioFile["scanned"] = 0] = "scanned";
    IAudioFile[IAudioFile["socket"] = 1] = "socket";
})(IAudioFile || (IAudioFile = {}));
const COUPON_MASK = "AEHJPQSXBV";
const REWARD_MASK = "CDGKLPSVXZ";
export class QrScannerPage {
    constructor(zone, translate, userService, toast, config, modal, admin, inactivity, platform, qrScanner, routing, alert) {
        this.zone = zone;
        this.translate = translate;
        this.userService = userService;
        this.toast = toast;
        this.config = config;
        this.modal = modal;
        this.admin = admin;
        this.inactivity = inactivity;
        this.platform = platform;
        this.qrScanner = qrScanner;
        this.routing = routing;
        this.alert = alert;
    }
    ionViewWillEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isOnCapacitor = this.platform.is("capacitor");
            this.params = this.routing.getUrlParams();
            if (!this.validParams()) {
                this.toast.show(this.translate._("INVALID_PARAMS"), "error");
                this.back();
                return;
            }
            this.loadAudioFiles();
            this.deviceConfig = this.config.getDeviceConfig();
            this.cameraPosition = positionMap[this.config.getMountingConfig().cameraPosition];
            if (this.params.type === "earn" && this.params.totalRevenue) {
                this.playSound(IAudioFile.socket);
            }
            if ((this.config && !this.config.manuallyEnableCamera) ||
                this.params.type === "burn" ||
                (this.params.type === "earn" && this.params.manualPoints)) {
                this.enableAppQRScanner();
                this.showCamera = true;
            }
            this.loading = false;
            if (this.config && this.config.inactivityTime) {
                this.timeout = setTimeout(() => {
                    this.back();
                }, this.config.inactivityTime * 1000);
            }
        });
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.admin.getRegistrationType();
            if (resp.ok === true)
                this.registrationType = resp.response;
            else
                this.toast.show(this.translate._(resp.error.message), "error");
            if (this.config && this.config.manuallyEnableCamera && this.params.type === "earn" && !this.params.manualPoints) {
                if (yield this.manuallyEnableCamera()) {
                    this.enableAppQRScanner();
                    this.showCamera = true;
                }
                else {
                    this.back();
                }
            }
        });
    }
    ionViewDidLeave() {
        clearTimeout(this.timeout);
        clearTimeout(this.alertTimeout);
        this.showCamera = false;
        this.disableAppQRScanner();
        this.alert.dismissAlert();
        if (this.isOnCapacitor)
            this.qrScanner.destroy();
    }
    logs(name, event) { }
    back() {
        this.routing.goForward([SharedRoutes.screenSaver]);
    }
    cameraFounds(devices) {
        const cameras = (devices || []).filter(({ kind }) => kind === "videoinput");
        cameras.forEach(camera => {
            if (camera.label.includes("front")) {
                this.cameraSelected = camera;
            }
        });
        if (!this.cameraSelected && cameras.length > 1 && this.getMobileOperatingSystem() === "iOS") {
            this.cameraSelected = cameras[1];
        }
        else if (!this.cameraSelected && cameras.length > 0) {
            this.cameraSelected = cameras[0];
        }
    }
    scannerCode(qrResult, error) {
        return __awaiter(this, void 0, void 0, function* () {
            this.playSound(IAudioFile.scanned);
            this.loading = true;
            yield this.disableAppQRScanner();
            let actionSuccess = false;
            this.inactivity.resetTimer();
            if (!error) {
                const resp = this.validateQr(qrResult);
                if (resp.ok === true) {
                    switch (resp.response.type) {
                        case "user":
                            actionSuccess = yield this.user(resp.response.keyCode, resp.response.idType);
                            break;
                        case "earn":
                            actionSuccess = yield this.earn(resp.response.keyCode, resp.response.idType);
                            break;
                        case "reward":
                            actionSuccess = yield this.burnReward(resp.response.qrObject);
                            break;
                        case "coupon":
                            actionSuccess = yield this.burnCoupon(resp.response.qrObject);
                            break;
                        case "ck":
                            actionSuccess = this.openCoupon(resp.response.qrObject);
                            break;
                    }
                }
                else {
                    yield this.toast.show(this.translate._(resp.error.message), "error");
                }
            }
            else {
                yield this.toast.show(this.translate._(qrResult), "error");
            }
            this.loading = false;
            if (!actionSuccess)
                yield this.enableAppQRScanner();
        });
    }
    openSuccessModal(keyCode, points, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                idType,
                keyCode,
                points,
                title: points > 0 ? this.translate._("POINTS_ACUMULATED") : this.translate._("POINTS_SPENT"),
            };
            const successModal = yield this.modal.create({
                component: SuccessPage,
                componentProps,
            });
            yield successModal.present();
            yield successModal.onDidDismiss();
        });
    }
    saveUser(user, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            const [userHistoryResponse, accountCouponRedemptionsResponse] = yield Promise.all([
                this.userService.getUserHistory(user.keyCode, idType),
                this.userService.readAccountCouponsRedemptions(user.keyCode, idType),
            ]);
            if (userHistoryResponse.ok === true && accountCouponRedemptionsResponse.ok === true) {
                this.userService.setUser(user);
                this.userService.setHistory(userHistoryResponse.response);
                this.userService.setAccountCouponsRedemptions(accountCouponRedemptionsResponse.response);
            }
            if (!userHistoryResponse.ok)
                return userHistoryResponse;
            if (!accountCouponRedemptionsResponse.ok)
                return accountCouponRedemptionsResponse;
            return userHistoryResponse;
        });
    }
    extendedRevenuePointsManually(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.params.type === "earn") {
                const response = yield this.admin.extendedRevenuePointsManually(keyCode, this.params.totalRevenue, this.params.extendedRevenueFactsId, idType, this.params.externalId);
                if (response.ok === true) {
                    this.openSuccessModal(keyCode, this.params.totalPoints, idType);
                }
                else {
                    this.toast.show(this.translate._(response.error.message), "error");
                }
                return response.ok;
            }
        });
    }
    user(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (keyCode) {
                const [checkInResponse, details] = yield Promise.all([
                    this.userService.checkIn(keyCode),
                    this.userService.getDetails(keyCode, idType),
                ]);
                if (checkInResponse.ok === false)
                    this.toast.show(this.translate._(checkInResponse.error.message), "error");
                if (details.ok === true) {
                    const queryParams = { idType, keyCode };
                    const saveUserResponse = yield this.saveUser(details.response, idType);
                    if (saveUserResponse.ok === true) {
                        if (!details.response.isRegistered && this.registrationType !== "NO") {
                            yield this.showRegisterModal(keyCode, details.response);
                        }
                        this.routing.goForward([SharedRoutes.burn], { queryParams });
                    }
                    else
                        this.toast.show(this.translate._(saveUserResponse.error.message), "error");
                    return saveUserResponse.ok;
                }
                else
                    this.toast.show(this.translate._(details.error.message), "error");
                return details.ok;
            }
            return false;
        });
    }
    earn(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (keyCode && this.params.type === "earn") {
                if (this.params.manualPoints) {
                    return yield this.revenuePoints(keyCode, idType);
                }
                else {
                    return yield this.extendedRevenuePointsManually(keyCode, idType);
                }
            }
            return false;
        });
    }
    showRegisterModal(keyCode, user) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                registrationType: this.registrationType,
                keyCode,
                userReferenceCode: user.userReferenceCode,
            };
            const registerModal = yield this.modal.create({
                component: RegisterPage,
                cssClass: "modal modal--full-screen",
                componentProps,
            });
            yield registerModal.present();
            const { data } = (yield registerModal.onDidDismiss());
            if (data === "SUCCESS")
                yield this.showAttentionModal();
            return data === "SUCCESS";
        });
    }
    playSound(file) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.audios && this.audios[file])
                    yield this.audios[file].play();
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    enableAppQRScanner() {
        return __awaiter(this, void 0, void 0, function* () {
            this.scannerEnabled = true;
            try {
                if (this.isOnCapacitor) {
                    const { authorized } = yield this.qrScanner.prepare();
                    if (authorized) {
                        yield this.qrScanner.useFrontCamera();
                        yield this.qrScanner.show();
                        this.qrScanner$ = this.qrScanner.scan().subscribe((text) => __awaiter(this, void 0, void 0, function* () {
                            this.qrScanner$.unsubscribe();
                            this.scannerCode(text, false);
                            return yield this.qrScanner.hide();
                        }));
                    }
                    else {
                        const componentProps = {
                            title: this.translate._("CAMERA_ACCESS_DENIED"),
                            description: this.translate._("GRANT_CAMERA_INSTRUCTIONS"),
                            buttonText: this.translate._("OK"),
                        };
                        const informationModal = yield this.modal.create({
                            component: InformationModalPage,
                            cssClass: "modal",
                            componentProps,
                            backdropDismiss: false,
                        });
                        yield informationModal.present();
                        yield informationModal.onDidDismiss();
                    }
                }
            }
            catch (error) { }
        });
    }
    disableAppQRScanner() {
        return __awaiter(this, void 0, void 0, function* () {
            this.scannerEnabled = false;
            if (this.isOnCapacitor)
                yield this.qrScanner.hide();
        });
    }
    validateQr(qrResult) {
        const resp = this.getQrType(qrResult);
        if (resp.ok === true) {
            switch (resp.response.type) {
                case "uk":
                case "cc":
                case "pk":
                    return this.params.type === "burn"
                        ? { ok: true, response: { type: "user", keyCode: resp.response.keyCode, idType: resp.response.idType } }
                        : { ok: true, response: { type: "earn", keyCode: resp.response.keyCode, idType: resp.response.idType } };
                case "ruk":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "reward",
                                qrObject: { keyCode: resp.response.keyCode, rewardId: this.unmask(resp.response.hashedReward, REWARD_MASK) },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
                case "ck":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "ck",
                                qrObject: { keyCode: this.params.keycode, couponKey: resp.response.couponId },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
                case "cuk":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "coupon",
                                qrObject: { keyCode: resp.response.keyCode, couponId: this.unmask(resp.response.hashedCoupon, COUPON_MASK) },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
                case "ckuk":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "coupon",
                                qrObject: { keyCode: resp.response.keyCode, couponId: resp.response.couponId, idType: resp.response.idType },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
            }
        }
        else {
            return resp;
        }
    }
    burnReward({ keyCode, rewardId }) {
        return __awaiter(this, void 0, void 0, function* () {
            const details = yield this.userService.getDetails(keyCode);
            if (details.ok === true) {
                const saveUserResponse = yield this.saveUser(details.response);
                if (saveUserResponse.ok === true) {
                    const queryParams = { keyCode, rewardId };
                    this.routing.goForward([SharedRoutes.burn], { queryParams });
                }
                else {
                    this.toast.show(this.translate._(saveUserResponse.error.message), "error");
                }
                return saveUserResponse.ok;
            }
            else {
                this.toast.show(this.translate._(details.error.message), "error");
                return details.ok;
            }
        });
    }
    burnCoupon({ idType, keyCode, couponId, couponKey }) {
        return __awaiter(this, void 0, void 0, function* () {
            const details = yield this.userService.getDetails(keyCode, idType);
            if (details.ok === true) {
                const saveUserResponse = yield this.saveUser(details.response, idType);
                if (saveUserResponse.ok === true) {
                    const queryParams = { idType, keyCode, couponId, couponKey };
                    this.routing.goForward([SharedRoutes.burn], { queryParams });
                }
                else {
                    this.toast.show(this.translate._(saveUserResponse.error.message), "error");
                }
                return saveUserResponse.ok;
            }
            else {
                this.toast.show(this.translate._(details.error.message), "error");
                return details.ok;
            }
        });
    }
    openCoupon({ idType, keyCode, couponId, couponKey }) {
        const queryParams = { idType, keyCode, couponId, couponKey };
        this.routing.goForward([SharedRoutes.burn], { queryParams });
        return true;
    }
    showAttentionModal() {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                title: this.translate._("CONFIRM"),
                description: this.translate._("REGISTRATION_NOT_COMPLETE"),
                buttonText: this.translate._("UNDERSTOOD"),
            };
            const informationModal = yield this.modal.create({
                component: InformationModalPage,
                cssClass: "modal",
                componentProps,
                backdropDismiss: false,
            });
            yield informationModal.present();
            yield informationModal.onDidDismiss();
        });
    }
    getQrType(qrResult) {
        try {
            const qrObject = JSON.parse(qrResult);
            if (qrObject.accountId) {
                if (qrObject.accountCouponId || qrObject.couponId) {
                    return {
                        ok: true,
                        response: {
                            type: "ckuk",
                            keyCode: qrObject.accountId,
                            couponId: qrObject.accountCouponId || qrObject.couponId,
                            idType: qrObject.idType ? qrObject.idType : "ID",
                        },
                    };
                }
                else {
                    return { ok: true, response: { type: "uk", keyCode: qrObject.accountId, idType: qrObject.idType ? qrObject.idType : "ID" } };
                }
            }
            else {
                return { ok: true, response: { type: "uk", keyCode: qrResult } };
            }
        }
        catch (error) {
            const array = qrResult.split("/");
            if (array.length > 4) {
                const type = array[array.length - 2];
                const value = array[array.length - 1];
                switch (type) {
                    case "uk":
                    case "cc":
                    case "pk":
                        return { ok: true, response: { type, keyCode: value } };
                    case "ruk":
                        const hashedReward = value.split("-");
                        return { ok: true, response: { type, keyCode: hashedReward[0], hashedReward: hashedReward[1] } };
                    case "ck":
                        return { ok: true, response: { type, couponId: value } };
                    case "cuk":
                        const hashedCoupon = value.split("-");
                        return { ok: true, response: { type, keyCode: hashedCoupon[0], hashedCoupon: hashedCoupon[1] } };
                    case "ckuk":
                        const coupon = value.split("-");
                        return { ok: true, response: { type, keyCode: coupon[0], couponId: coupon[1] } };
                    default:
                        return { ok: false, error: { message: this.translate._("INVALID_QR") } };
                }
            }
            else if (array.length === 4 || array.length === 1) {
                return { ok: true, response: { type: "uk", keyCode: array[array.length - 1], idType: "ID" } };
            }
            else {
                return { ok: false, error: { message: this.translate._("INVALID_QR") } };
            }
        }
    }
    unmask(ticketId, mask) {
        let ticket = "";
        for (let i = 0; i < ticketId.length; i++) {
            const c = ticketId.substring(i, i + 1);
            const x = mask.indexOf(c);
            if (x < 0) {
                return null;
            }
            ticket += x;
        }
        return ticket;
    }
    revenuePoints(keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.params.type === "earn") {
                const response = yield this.userService.revenuePoints({
                    deviceKey: this.config.getMountingConfig().deviceKey,
                    keyCode,
                    idType,
                    revenueRmb: this.params.revenueRmb,
                    revenueRmc1: this.params.revenueRmc1,
                    revenueRmc2: this.params.revenueRmc2,
                    revenueRmc3: this.params.revenueRmc3,
                    revenueRmt: this.params.revenueRmt,
                    totalPoints: this.params.manualPoints,
                });
                if (response.ok === true) {
                    this.openSuccessModal(keyCode, this.params.manualPoints, idType);
                }
                else {
                    this.toast.show(this.translate._(response.error.message), "error");
                }
                return response.ok;
            }
            return false;
        });
    }
    validParams() {
        switch (this.params.type) {
            case "burn":
                return true;
            case "earn":
                return (!!this.params.manualPoints ||
                    (!!this.params.totalRevenue && !!this.params.date) ||
                    (!!this.params.totalRevenue && !!this.params.totalPoints && (!!this.params.extendedRevenueFactsId || !!this.params.externalId)));
            default:
                return false;
        }
    }
    getMobileOperatingSystem() {
        if (/windows phone/i.test(navigator.userAgent || navigator.vendor)) {
            return "Windows Phone";
        }
        if (/android/i.test(navigator.userAgent || navigator.vendor)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor)) {
            return "iOS";
        }
        return "unknown";
    }
    loadAudioFiles() {
        try {
            this.audios = {
                [IAudioFile.scanned]: new Audio("assets/scanned.mp3"),
                [IAudioFile.socket]: new Audio("assets/socket.mp3"),
            };
            this.audios[IAudioFile.scanned].load();
            this.audios[IAudioFile.socket].load();
        }
        catch (error) {
            console.error(error);
        }
    }
    manuallyEnableCamera() {
        return new Promise((resolve, reject) => {
            this.alertTimeout = setTimeout(() => {
                this.alert
                    .dismissAlert()
                    .then(() => {
                    resolve(false);
                })
                    .catch(error => {
                    reject(error);
                });
            }, this.config && this.config.inactivityTime * 1000);
            this.alert.showAlert({
                cssClass: "alert-enable-camera",
                message: this.translate._("enableCamera"),
                buttons: [
                    {
                        text: this.translate._("CONFIRM"),
                        handler: () => {
                            resolve(true);
                        },
                    },
                ],
            });
        });
    }
}
