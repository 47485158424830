var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { RegisterPage as SharedRegisterPage } from "shared/lib/common/pages";
import { AdminService, ProgramService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
import { PasswordMatch } from "shared/lib/common/validators";
import { ACCEPTED_COUNTRIES } from "shared/lib/common/constants";
export class RegisterPage extends SharedRegisterPage {
    constructor(_t, _modal, _fb, _admin, _toast, _config, _user, _program) {
        super(_t, _modal, _fb, _admin, _toast, _config, _user);
        this._t = _t;
        this._modal = _modal;
        this._fb = _fb;
        this._admin = _admin;
        this._toast = _toast;
        this._config = _config;
        this._user = _user;
        this._program = _program;
    }
    ngOnInit() {
        this.form = new FormGroup({});
        this.countries = ACCEPTED_COUNTRIES;
        this.client = this._config.getMountingConfig().legalName;
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            const config = yield this._program.getProgramConfig(this._config.getOrganization()).toPromise();
            this.updateForm(config);
            this.isLoading = false;
        });
    }
    register() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            if (this.form.valid) {
                const params = this.userProperties.reduce((acum, userProperty) => {
                    if (userProperty.name === "birthDate") {
                        const birthdate = this.form.get(userProperty.name).value;
                        const date = !!birthdate ? new Date(birthdate) : null;
                        acum[userProperty.name] = date && date.toISOString();
                    }
                    else {
                        acum[userProperty.name] = this.form.get(userProperty.name).value || undefined;
                    }
                    return acum;
                }, {});
                const resp = yield this._admin.register(this.addRegistrationData(params));
                if (resp.ok === true) {
                    this.form.reset();
                    this.dismiss("SUCCESS");
                }
                else {
                    this._toast.show(resp.error.message, "error");
                }
            }
            this.isLoading = false;
        });
    }
    addRegistrationData(userData) {
        const registrationData = [{ key: "email", value: this.form.get("emailAddress").value }];
        if (this.partnerId)
            registrationData.push({ key: "partnerId", value: this.partnerId });
        if (this.keyCode)
            registrationData.push({ key: "accountId", value: this.keyCode });
        const optins = (this.membershipOptins || []).map(optin => {
            return { type: optin, flag: this.form.get(optin).value || false };
        });
        registrationData.push({
            key: "optins",
            value: JSON.stringify(optins),
        });
        return Object.assign({}, userData, { registrationData });
    }
    updateForm(programConfig) {
        if (programConfig) {
            this.membershipOptins = programConfig.membershipOptins;
            this.userProperties = programConfig.userProperties;
            (this.membershipOptins || []).forEach(optin => {
                this.form.addControl(optin, new FormControl(false));
            });
            (this.userProperties || []).forEach(userProperty => {
                switch (userProperty.name) {
                    case "emailAddress":
                        this.form.addControl(userProperty.name, new FormControl("", userProperty.mandatory ? [Validators.required, Validators.email] : [Validators.email]));
                        break;
                    case "password":
                    case "passwordRepeat":
                        this.form.addControl(userProperty.name, new FormControl("", userProperty.mandatory ? [Validators.required, Validators.minLength(8)] : [Validators.minLength(8)]));
                        break;
                    default:
                        this.form.addControl(userProperty.name, new FormControl("", userProperty.mandatory ? [Validators.required] : []));
                        break;
                }
            });
            if (this.form.get("password") && this.form.get("passwordRepeat")) {
                this.form.setValidators(PasswordMatch("password", "passwordRepeat"));
            }
            this.form.updateValueAndValidity();
        }
    }
}
