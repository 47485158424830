var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Router } from "@angular/router";
import { DeviceMountingService, ProgramService } from "shared/lib/v2/services";
import { ThirdPartyScriptsService, ConfigService } from "shared/lib/common/services";
import { BLACK_LIST } from "shared/lib/common/constants";
import { Injector } from "@angular/core";
function getSubdomainConfig(program) {
    return __awaiter(this, void 0, void 0, function* () {
        const subdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0];
        const isBlackListDomain = BLACK_LIST.some(host => subdomain.includes(host));
        if (!isBlackListDomain) {
            const subdomainProgramConfig = yield program.getProgramConfig(subdomain).toPromise();
            return { subdomainOrg: subdomain, subdomainProgramConfig };
        }
        return { subdomainOrg: null, subdomainProgramConfig: null };
    });
}
function getPathConfig(program) {
    return __awaiter(this, void 0, void 0, function* () {
        const pathOrg = window.location.pathname.split("/")[1];
        const pathProgramConfig = yield program.getProgramConfig(pathOrg).toPromise();
        return { pathOrg, pathProgramConfig };
    });
}
function setConfig(org, programConfig, config) {
    return __awaiter(this, void 0, void 0, function* () {
        config.setOrganization(org);
        yield config.setConfig(programConfig);
    });
}
export function initializeApp(injector) {
    return () => __awaiter(this, void 0, void 0, function* () {
        const thirdParty = injector.get(ThirdPartyScriptsService);
        const program = injector.get(ProgramService);
        const deviceMounting = injector.get(DeviceMountingService);
        const config = injector.get(ConfigService);
        const router = injector.get(Router);
        yield thirdParty.addThirdPartyScripts();
        const { subdomainOrg, subdomainProgramConfig } = yield getSubdomainConfig(program);
        const { pathOrg, pathProgramConfig } = yield getPathConfig(program);
        if (subdomainProgramConfig && pathProgramConfig && subdomainOrg === pathOrg) {
            yield setConfig(pathOrg, pathProgramConfig, config);
        }
        else if (subdomainProgramConfig && pathProgramConfig) {
            router.navigateByUrl("");
        }
        else if (subdomainProgramConfig) {
            yield setConfig(subdomainOrg, subdomainProgramConfig, config);
            window.location.href = `${subdomainOrg}`;
        }
        else if (pathProgramConfig) {
            yield setConfig(pathOrg, pathProgramConfig, config);
        }
        else {
            router.navigateByUrl("");
        }
        const res = yield deviceMounting.getMounting();
        if (res.ok === true)
            config.setMountingConfig(yield deviceMounting.parseMountingConfigV1ToV2(res.response));
    });
}
const pages = [];
const modals = [];
export class AppModule {
}
