var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from "@angular/common/http";
import { P4M, LOCAL_STORAGE } from "shared/lib/common/enums";
import { IAccount, IAccountBooking, IAccountDetails, IBalance, IBookPointsParams, IError, IGetCouponsResponse, IGetCouponsParams, IGetRewardsResponse, IGetRewardsParams, IGetUserDetailsResponse, IGetUserDetailsParams, IGetUserHistoryParams, IGetUserHistoryResponse, IIdentifier, IIdentifierType, ILoginParams, IMembership, IPartner, IRedeemCouponParams, IRedeemRewardParams, IResponseP4M, IRevenuePointsParams, IRevenuePointsResponse, IStoreAPI2, ITransaction, IFile, ILevel, IStatusDefinitions, IReward, ICoupon, IStatus, IUserStatus, IReadBalanceParams, ICheckInParams, ICheckInResponse, IUserV2, IReadUserParams, IAccountCouponRedemption, } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { EnvironmentService } from "../../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../sentry/sentry.service";
import * as i3 from "../../config/config.service";
import * as i4 from "../../utils/utils.service";
import * as i5 from "../../translate/translate.service";
import * as i6 from "../../environment/environment.service";
export class P4mV2Service {
    constructor(http, sentry, config, utils, translate, environment) {
        this.http = http;
        this.sentry = sentry;
        this.config = config;
        this.utils = utils;
        this.translate = translate;
        this.environment = environment;
    }
    getUserDetails({ keyCode, idType }) {
        return __awaiter(this, void 0, void 0, function* () {
            const [accountResponse, accountMembershipsResponse, accountBalanceResponse, accountCouponsResponse, accountIdentifiersResponse, accountLevelResponse,] = yield Promise.all([
                this.readAccount(keyCode, idType),
                this.readAccountMemberships(keyCode, idType),
                this.readAccountBalance({ keyCode, idType }),
                this.readAccountCoupons(keyCode, idType),
                this.readAccountIdentifiers(keyCode, idType),
                this.readAccountLevel(keyCode, idType),
            ]);
            if (accountResponse.ok === true &&
                accountMembershipsResponse.ok === true &&
                accountBalanceResponse.ok === true &&
                accountCouponsResponse.ok === true &&
                accountIdentifiersResponse.ok === true &&
                accountLevelResponse.ok === true) {
                const membership = this.getOwner(accountMembershipsResponse.response);
                return {
                    ok: true,
                    response: {
                        userId: membership ? membership.userId : null,
                        userStatusCode: this.getUserStatus(accountMembershipsResponse.response, accountResponse.response.status),
                        totalPoints: accountBalanceResponse.response.points,
                        coupons: this.parseCoupons(accountCouponsResponse.response.filter(c => c.usageType !== "REWARD"), accountBalanceResponse.response.points),
                        rewards: this.parseRewards(accountCouponsResponse.response.filter(c => c.usageType === "REWARD"), accountBalanceResponse.response.points),
                        isAppUser: this.isAppUser(accountIdentifiersResponse.response),
                        statusDefinitions: accountLevelResponse.response ? this.parseLevel(accountLevelResponse.response) : null,
                    },
                };
            }
            else {
                let message;
                [
                    accountResponse,
                    accountMembershipsResponse,
                    accountBalanceResponse,
                    accountCouponsResponse,
                    accountIdentifiersResponse,
                    accountLevelResponse,
                ].forEach(response => {
                    if (response.ok === false)
                        message = response.error.message;
                });
                return { ok: false, error: { message } };
            }
        });
    }
    getUserHistory(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.readAccountBookings(params.keyCode, params.idType);
            if (response.ok === true) {
                return { ok: true, response: { transactions: this.parseTransactions(response.response) } };
            }
            else {
                return response;
            }
        });
    }
    bookPoints(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.createAccountBookings(params.keyCode, "EARNBOOKING", params.points, this.translate._("BOOK_POINTS_REASON"), params.idType);
        });
    }
    revenuePoints(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.createAccountBookings(params.keyCode, "EARNBOOKING", params.totalPoints.toString(), this.translate._("REVENUE_POINTS_REASON"), params.idType);
        });
    }
    redeemCoupon(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.redeemAccountCoupon(params.accountVoucherId);
        });
    }
    redeemReward(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.redeemAccountCoupon(params.accountVoucherId);
        });
    }
    getCoupons(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return { ok: true, response: { coupons: [] } };
        });
    }
    getRewards(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return { ok: true, response: { rewards: [] } };
        });
    }
    login(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .post(`${this.environment.getVariable("API_V2")}/${P4M.LOGIN_V2}`, {
                    userName: params.email,
                    org: params.organization,
                    password: params.password,
                }, {
                    responseType: "text",
                    headers: { "Content-Type": "application/json; charset=utf-8", "interaction-id": params.organization !== "oek" ? "posapp" : "" },
                })
                    .subscribe((response) => {
                    this.setToken(response);
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readProgramPartners(workstationId) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.PROGRAMS}/${this.config.getOrganization()}/${P4M.PARTNERS}`, {
                    headers: { authorization: this.getToken(), "interaction-id": workstationId || "pos-app" },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readProgramPartners",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readPartnerStores(partnerId, workstationId) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.PROGRAMS}/${this.config.getOrganization()}/${P4M.PARTNERS}/${partnerId}/${P4M.STORES}`, {
                    headers: { authorization: this.getToken(), "interaction-id": workstationId || "pos-app" },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readPartnerStores",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readAccountBalance(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${params.keyCode}/${P4M.BALANCE}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": params.idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountBalance",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                if (params.userId) {
                    this.http
                        .get(`${this.environment.getVariable("API_V2")}/${P4M.USERS}/${params.userId}`, {
                        headers: {
                            authorization: this.getToken(),
                            "interaction-id": this.config.getMountingConfig().workstationId,
                            "id-type": params.idType || "CARDCODE",
                        },
                    })
                        .subscribe((response) => {
                        resolve(this.parseResponseV2(response));
                    }, error => {
                        const err = this.parseError(error);
                        this.sentry.info({
                            status: err.status,
                            message: err.message,
                            method: "p4m.readUser",
                        });
                        resolve({ ok: false, error: { message: err.message } });
                    });
                }
                else {
                    resolve({ ok: true, response: { emailAddress: this.translate._("ANONYMOUS_USER") } });
                }
            });
        });
    }
    readAccountCouponsRedemptions(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.ACCOUNT_COUPONS}/${P4M.REDEMPTIONS}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountCouponsRedemptions",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    parseResponseV2(response) {
        if (response && response.errorType && response.message) {
            return { ok: false, error: { message: response.message } };
        }
        else {
            return { ok: true, response };
        }
    }
    checkIn(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve({ ok: true, response: null });
        });
    }
    parseCoupons(coupons, totalPoints) {
        return (coupons || []).map(coupon => ({
            couponId: coupon.couponId,
            accountCouponId: coupon.accountCouponId,
            title: this.getCouponi18nField(coupon.i18nFields, "title"),
            subtitle: this.getCouponi18nField(coupon.i18nFields, "subTitle"),
            description: this.getCouponi18nField(coupon.i18nFields, "description"),
            points: coupon.couponValue,
            couponFiles: this.parseFiles(coupon.images),
            redeemDependencyCode: null,
            externalCodeBarcodeTypeCode: null,
            validToDate: { unixTimeStamp: this.utils.parseDate(coupon.validTo) },
            unlocked: this.isUnlocked(coupon, totalPoints),
            externalCode: coupon.externalReference,
            couponTypeCode: coupon.usageType,
            pictureFilePath: "",
            customProperties: coupon.customProperties,
            validFromDate: { unixTimeStamp: this.utils.parseDate(coupon.validFrom) },
            activation: coupon.activation,
            redeemActions: coupon.redeemActions,
        }));
    }
    getCouponi18nField(field, param) {
        if (field) {
            if (this.translate.getSessionLanguage() === "de") {
                return field.de ? field.de[param] : "";
            }
            else {
                return field.en ? field.en[param] : "";
            }
        }
        else {
            return "";
        }
    }
    parseFiles(images) {
        const c = [];
        if (images) {
            images.forEach(image => {
                if (image.tags) {
                    image.tags.forEach(tag => {
                        c.push({
                            pictureFilePath: image.path,
                            tag,
                        });
                    });
                }
            });
        }
        return c;
    }
    isUnlocked(coupon, totalPoints) {
        return ((coupon.validTo
            ? this.utils.isBetweenDates(new Date(coupon.validFrom).getTime(), new Date(coupon.validTo).getTime())
            : this.utils.isBeforeNow(new Date(coupon.validFrom).getTime())) &&
            (coupon.usageType !== "REWARD" || coupon.couponValue <= totalPoints));
    }
    getUserStatus(memberShips, status) {
        if (status === "LOCKED")
            return status;
        if (memberShips && memberShips.length > 0)
            return "RGU";
        return "";
    }
    readAccountLevel(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.LEVEL}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    if (err.status >= 400 && err.status < 500) {
                        resolve({ ok: true, response: null });
                    }
                    else {
                        this.sentry.info({
                            status: err.status,
                            message: err.message,
                            method: "p4m.readAccountLevel",
                        });
                        resolve({ ok: false, error: { message: err.message } });
                    }
                });
            });
        });
    }
    readAccount(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccount",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readAccountMemberships(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.MEMBERSHIPS}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountMemberships",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readAccountCoupons(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/v2/${P4M.ACCOUNTS}/${accountId}/${P4M.ACCOUNT_COUPONS}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountCoupons",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readAccountIdentifiers(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.IDENTIFIERS}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountIdentifiers",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readAccountDetails(accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNT_DETAILS}/${accountId}`, {
                    headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountDetails",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    createAccountBookings(accountId, bookingType, points, reason, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .post(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.BOOKINGS}`, { bookingType, bookingTypeCode: "MANUALREVENUE", points, reason }, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.createAccountBookings",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    readAccountBookings(accountId, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.BOOKINGS}`, {
                    headers: {
                        authorization: this.getToken(),
                        "interaction-id": this.config.getMountingConfig().workstationId,
                        "id-type": idType || "CARDCODE",
                    },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.readAccountBookings",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    redeemAccountCoupon(accountCouponId) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.http
                    .patch(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNT_COUPONS}/${accountCouponId}`, "", {
                    headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": "ID" },
                })
                    .subscribe((response) => {
                    resolve(this.parseResponseV2(response));
                }, error => {
                    const err = this.parseError(error);
                    this.sentry.info({
                        status: err.status,
                        message: err.message,
                        method: "p4m.redeemAccountCoupon",
                    });
                    resolve({ ok: false, error: { message: err.message } });
                });
            });
        });
    }
    parseRewards(coupons, totalPoints) {
        return (coupons || []).map(coupon => ({
            rewardId: coupon.couponId,
            accountCouponId: coupon.accountCouponId,
            title: this.getCouponi18nField(coupon.i18nFields, "title"),
            subtitle: this.getCouponi18nField(coupon.i18nFields, "subTitle"),
            description: this.getCouponi18nField(coupon.i18nFields, "description"),
            points: coupon.couponValue,
            unlocked: this.isUnlocked(coupon, totalPoints),
            rewardFiles: this.parseFiles(coupon.images),
            redeemDependencyCode: null,
            decreasePoints: true,
            createDateTime: null,
            lastRedeemDateTime: null,
            customProperties: coupon.customProperties,
            validFromDate: { unixTimeStamp: this.utils.parseDate(coupon.validFrom) },
            validToDate: { unixTimeStamp: this.utils.parseDate(coupon.validTo) },
            activation: coupon.activation,
            redeemActions: coupon.redeemActions,
        }));
    }
    parseTransactions(bookings) {
        const c = [];
        if (bookings) {
            bookings.forEach(booking => {
                c.push({
                    description: this.getBookingDescription(booking),
                    points: booking.bookingType === "EARNBOOKING" ? booking.points : booking.points * -1,
                    transactionDateTime: {
                        unixTimeStamp: this.utils.parseDate(booking.bookingTime[booking.bookingTime.length - 1] === "Z" ? booking.bookingTime : booking.bookingTime + "Z"),
                    },
                });
            });
        }
        return c;
    }
    getBookingDescription(booking) {
        switch (booking.bookingTypeCode) {
            case "COU":
                if (booking.points === 0) {
                    return `${this.translate._("COUPON_REDEMPTION")}${booking.reason ? `: ${booking.reason}` : ``}`;
                }
                else {
                    return `${this.translate._("POINT_REDEMPTION")}${booking.reason ? `: ${booking.reason}` : ``}`;
                }
                break;
            case "REV":
                switch (booking.bookingType) {
                    case "BURNBOOKING":
                        return `${this.translate._("PAY_WITH_POINTS")}${booking.reason ? `: ${booking.reason}` : ``}`;
                    case "EARNBOOKING":
                        return `${this.translate._("PURCHASE")}${booking.reason ? `: ${booking.reason}` : ``}`;
                }
                break;
            default:
                return booking.reason;
        }
    }
    getOwner(memberships) {
        return memberships.filter(membership => membership.memberRole === "OWNER")[0];
    }
    isAppUser(identifiers) {
        return identifiers.filter(identifier => identifier.type === "APPCODE").length > 0;
    }
    getToken() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
    }
    setToken(token) {
        localStorage.setItem(LOCAL_STORAGE.TOKEN, this.utils.encrypt(token));
    }
    parseError(error) {
        if (error.message && error.status) {
            return error;
        }
        else if (error.error instanceof ErrorEvent) {
            return {
                message: error.message,
                status: error.status,
            };
        }
        else {
            return {
                message: error.error ? error.error.message : "An error has occurred " + error.status,
                status: error.status,
            };
        }
    }
    parseLevel(response) {
        return [
            {
                unlocked: true,
                pictureFilePath: "",
                points: response.currentStatePoints,
                statusDefinitionId: 0,
                statusName: response.name,
                pointsDisplay: String(response.currentStatePoints),
            },
        ];
    }
}
P4mV2Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function P4mV2Service_Factory() { return new P4mV2Service(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SentryService), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.UtilsService), i0.ɵɵinject(i5.TranslateService), i0.ɵɵinject(i6.EnvironmentService)); }, token: P4mV2Service, providedIn: "root" });
