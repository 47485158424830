import { AccountCouponService as ConvercusAccountCouponService, AccountCouponV2Service, Configuration, ActivationPatchData, } from "shared/lib/v2/apis/coupons";
import { HttpClient } from "@angular/common/http";
import { INTERACTION_ID } from "shared/lib/common/constants";
import { EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../apis/coupons/variables";
import * as i3 from "../../apis/coupons/configuration";
import * as i4 from "../../../common/services/environment/environment.service";
export class AccountCouponService extends ConvercusAccountCouponService {
    constructor(httpClient, basePath, configuration, environmentService) {
        super(httpClient, environmentService.getVariable("API_V2"), configuration);
        this.httpClient = httpClient;
        this.environmentService = environmentService;
        this.accountCouponV2 = new AccountCouponV2Service(httpClient, basePath, configuration);
    }
    changeActivationStatus(accountcouponid, body, interactionId = INTERACTION_ID) {
        return this.accountCouponV2.changeActivationStatus(accountcouponid, interactionId, body);
    }
    getAccountCoupon(accountCouponId, interactionId = INTERACTION_ID) {
        return this.accountCouponV2.getAccountCouponV2(accountCouponId, interactionId);
    }
}
AccountCouponService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountCouponService_Factory() { return new AccountCouponService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8), i0.ɵɵinject(i4.EnvironmentService)); }, token: AccountCouponService, providedIn: "root" });
