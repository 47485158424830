import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { PosConnectionService, RoutingService, ToastService, TranslateService } from "shared/lib/common/services";
import { DeviceMountingService, ProgramService } from "shared/lib/v2/services";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { InteractionPoint } from "shared/lib/v2/apis/programs";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-mounting-settings",
  templateUrl: "./mounting-settings.page.html",
  styleUrls: ["./mounting-settings.page.scss"],
})
export class MountingSettingsPage implements OnInit {
  public loading: boolean;
  public interactionPoints: InteractionPoint[];
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private program: ProgramService,
    protected routing: RoutingService,
    protected toast: ToastService,
    protected location: Location,
    protected config: ConfigService,
    protected posConnectionService: PosConnectionService,
    protected deviceMountingService: DeviceMountingService,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      appInteractionId: ["", [Validators.required]],
      deviceInformation: [""],
    });
  }

  public async ionViewDidEnter(): Promise<void> {
    try {
      const resp = await this.program.getInteractionPointsOfProgram(this.config.getOrganization(), undefined, true).toPromise();
      this.interactionPoints = resp.filter(i => i.type !== "CASHIER");
    } catch (error) {
      const e = error.error && error.error.message ? error.error.message : error.message || error;
      this.toast.show(this.translate._(e), "error");
    }
  }

  public back(): void {
    this.location.back();
  }

  public async mountDevice(): Promise<void> {
    this.loading = true;
    const res = await this.deviceMountingService.mount(
      this.form.get("appInteractionId").value.id || this.form.get("appInteractionId").value,
      this.form.get("deviceInformation").value,
    );
    if (res.ok === true) {
      this.config.setMountingConfig(await this.deviceMountingService.parseMountingConfigV1ToV2(res.response));
      this.posConnectionService.connectToSocket(res.response.deviceKey);
      this.translate.setLanguage(res.response.profile.language === "de" ? "de" : "en");
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
      } catch (error) {
        this.toast.show("Camera access denied", "error");
      }
      this.routing.goForward([SharedRoutes.screenSaver]);
    } else {
      const e = res.error.error && res.error.error.message ? res.error.error.message : res.error.message || res.error;
      this.toast.show(this.translate._(e), "error");
    }
    this.loading = false;
  }

  public checkErrors(formControlName: string): boolean {
    const control = this.form.get(formControlName);
    return control.invalid && control.touched;
  }

  public onChangeSearch(value: string): void {
    this.form.get("appInteractionId").patchValue(value);
  }
}
