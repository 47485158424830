import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { EnvironmentService, RoutingService, SentryService, ToastService, TranslateService } from "shared/lib/common/services";
import { LoginPage as SharedLoginPage } from "shared/lib/common/pages";
import { Platform } from "@ionic/angular";
import { AdminService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-login",
  templateUrl: "../../../common/pages/login/login.page.html",
  styleUrls: ["../../../common/pages/login/login.page.scss"],
})
export class LoginPage extends SharedLoginPage {
  constructor(
    public _translate: TranslateService,
    public _fb: FormBuilder,
    public _admin: AdminService,
    public _config: ConfigService,
    public _toast: ToastService,
    public _sentry: SentryService,
    private _platform: Platform,
    private _routing: RoutingService,
    private _environment: EnvironmentService,
  ) {
    super(_translate, _fb, _admin, _config, _toast, _sentry, _platform, _routing, _environment);
  }
}
